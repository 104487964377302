import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import './Telegram.css'

function Telegram(props) {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [groups, setGroups] = useState({});
  const [savedMessages, setSavedMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [loadPageStatus, setLoadPageStatus] = useState(1); // 0 Okay, 1 Loading, 2 No more pages
  const [showSaveMessageFull, setShowSaveMessageFull] = useState(null);
  const [renderStars, setRenderStars] = useState(0);

  useEffect(() => {
    const loadGroups = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/get_telegram_groups_and_favorites/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data === "No permissions") {
            navigate("/pricing")
          } else {
            setGroups(data[0].reduce((obj, str) => ({ ...obj, [str]: true }), {}))
            setSavedMessages(data[1])
          }
        })
    }
    if (!isLoading && isAuthenticated) {
      loadGroups();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, navigate])

  useEffect(() => {
    const loadMessages = async () => {
      setLoadPageStatus(1)
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/get_telegram_messages/" + page + "/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data === "No permissions") {
            navigate("/pricing")
          } else {
            if (data.length === 0) {
              setLoadPageStatus(2)
            } else {
              setMessages((prev) => prev.concat(data))
              setLoadPageStatus(0)
            }
          }
        })
    }
    if (!isLoading && isAuthenticated) {
      loadMessages();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, navigate, page])

  const saveTelMessage = async (e, msg) => {
    e.target.style.cursor = "wait";
    e.target.disabled = true;
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/telegram_save_message/" + msg['id'] + "/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setSavedMessages((savedMsgs) => [...savedMsgs, msg])
        e.target.style.cursor = "not-allowed";
      })
  }

  const removeSavedTelMessage = async (e, msg_id) => {
    e.target.style.cursor = "wait";
    e.target.disabled = true;
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/telegram_remove_saved_message/" + msg_id + "/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        e.target.style.cursor = "pointer";
        e.target.disabled = false;
        setSavedMessages((savedMsgs) => savedMsgs.filter((msg) => msg.id !== msg_id))
      })
  }

  if (!isLoading && !isAuthenticated) {
    return (
      <div style={{ textAlign: "center", marginBottom: "80vh" }}>
        <Helmet>
          <title>Nepeto - Telegram Deals for Amazon</title>
          <meta
            name="description"
            content="With our cutting-edge technology, we continuously source deals across multiple Telegram groups! Just click on the provided link, and it'll whisk you straight to the relevant Telegram group, where the lead awaits."
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, telegram, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
        <br />
        <br />
        <p
          style={{
            width: "80vw",
            margin: "auto",
            fontSize: "200%",
            boxShadow: "0px 0px 5px 1px #8c3e7c",
            padding: "2%",
          }}
        >
          To use Nepeto, sign up and subscribe to one of our plans. You can
          start with a free sign-up and enjoy a free trial!
          <br />
          <br />
          <button
            className="button is-rounded is-size-6-mobile is-size-6-tablet"
            style={{
              backgroundColor: "#32CD32",
              margin: "auto",
              color: "#ffffff",
            }}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                },
                appState: { returnTo: "/pricing?s=1" },
              })
            }
          >
            Sign up for Free!
          </button>
        </p>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Nepeto - Telegram Deals for Amazon</title>
        <meta
          name="description"
          content="With our cutting-edge technology, we continuously source deals across multiple Telegram groups! Just click on the provided link, and it'll whisk you straight to the relevant Telegram group, where the lead awaits."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, telegram, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "2vh",
        }}
      >
        Products from Telegram distributors
      </h1>
      <p style={{ textAlign: "center" }}>Do your own research and due diligence before making any purchase!</p>

      <div className="columns" style={{ marginBottom: "3.5vh", height: props.isMobile ? "130vh" : "80vh" }}>
        {!props.isMobile && <div className="column is-3" style={{ padding: "3vh 3vw" }}>
          <div className="box" style={{ borderTop: "2px solid #8c3e7c", height: "100%", maxHeight: "100%", overflowY: "scroll", backgroundColor: "#8c3e7c" }}>
            <h1 style={{ fontSize: "150%", color: "white" }}>Click to Filter Distributors</h1>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.entries(groups).sort((a, b) => {
                const aStar = localStorage.getItem(a[0] + "-star") && (!!+localStorage.getItem(a[0] + "-star"));
                const bStar = localStorage.getItem(b[0] + "-star") && (!!+localStorage.getItem(b[0] + "-star"));
                return (aStar === bStar) ? 0 : aStar ? -1 : 1;
              }).map((group) => {
                return <div className="box"
                  onClick={() => {
                    loadPageStatus !== 1 && setGroups(prevState => ({
                      ...prevState,
                      [group[0]]: !prevState[group[0]]
                    }));
                  }}
                  style={{ height: "10%", backgroundColor: group[1] ? "rgba(150,255,150,1)" : "lightgray", verticalAlign: "middle", cursor: "pointer", textAlign: "center", display: "table-cell", color: "#8c3e7c", fontWeight: "600" }}>
                  {group[1] ? <i class="fa fa-check-circle-o" aria-hidden="true" style={{ fontSize: "150%", float: "left" }}></i> : <i class="fa fa-circle-thin" aria-hidden="true" style={{ fontSize: "150%", float: "left" }}></i>}
                  {group[0]}
                  <i render={renderStars} class={"fa fa-star" + (localStorage.getItem(group[0] + "-star") ? (!!+localStorage.getItem(group[0] + "-star") ? "" : "-o") : "-o")} aria-hidden="true" style={{ fontSize: "150%", float: "right", cursor: "pointer" }} onClick={(e) => { localStorage.setItem(group[0] + "-star", localStorage.getItem(group[0] + "-star") ? (+localStorage.getItem(group[0] + "-star") + 1) % 2 : 1); e.stopPropagation(); setRenderStars(ren => ren + 1) }}></i>
                </div>
              })}
              {Object.entries(groups).length > 0 &&
                <div style={{ margin: "auto" }}>
                  <button className="button is-danger" style={{ margin: "auto" }} onClick={() => navigate("/contact")}>Report</button>&emsp;
                  <button className="button is-success" style={{ margin: "auto" }} onClick={() => navigate("/contact")}>Add New</button>
                </div>}
            </div>
          </div>
        </div>}
        <div className="column is-6" style={{ padding: "3vh 3vw", height: props.isMobile && "65vh", overflow: props.isMobile && "scroll" }}>
          {props.isMobile && <h1 style={{ fontSize: "150%", color: "black" }}>Messages:</h1>}
          <div className="box" style={{ borderTop: "2px solid #8c3e7c", height: "100%", maxHeight: "100%", overflowY: "scroll", backgroundColor: "#8c3e7c", display: "flex", flexDirection: "column-reverse" }}>
            {messages.length > 0 && <button className="button is-success" style={{ width: "20%", margin: "auto" }} onClick={() => window.location.reload()}>Refresh</button>}
            {messages.map((msg, index) => {
              if (groups[msg['chat_name']]) {
                return <>
                  <article class="message is-light chat-message">
                    <div class="message-header" style={{ borderBottom: "1px solid lightgray" }}>
                      <p>{new Date((msg['sent_date'] * 1000)).toLocaleString()}</p>
                      <p>{msg['chat_name']}&emsp;</p>
                      <button onClick={(e) => saveTelMessage(e, msg)} style={{ backgroundColor: "#E3D0DF", color: "#8B3C7E", fontWeight: "700", borderRadius: "30px", padding: "0.5% 2%", border: "none", cursor: "pointer" }}>Save Message</button>
                    </div>
                    <div class="message-body chat-body" style={{ whiteSpace: 'break-spaces' }}>
                      {msg['message'].split("\n").map((msgRow) => {
                        return <>{msgRow.split(" ").map((msgWord) => {
                          if (msgWord.includes("https")) {
                            return <><a target="_blank" rel="noopener noreferrer" href={msgWord} style={{ color: "SlateBlue" }}>{msgWord}</a>{" "}</>
                          } else {
                            return <><span style={{ fontWeight: msgWord.includes("$") && "700" }}>{msgWord}</span>{" "}</>
                          }
                        })}<br /></>
                      })}
                      <button className="button is-success" onClick={() => window.open("https://t.me/s/" + msg['user_name'])} style={{ marginLeft: "auto", float: "right", marginBottom: "1%", marginRight: "-2%" }}>Go to chat</button>
                    </div>
                  </article>
                  {(index === messages.length - 1) && <br />}
                </>
              } else { return <></> }
            })}
            {(messages.length > 0 && loadPageStatus !== 2) ? (loadPageStatus === 1 ?
              <button className="button is-success" style={{ width: "20%", margin: "auto" }}>Loading...</button> :
              <button className="button is-success" onClick={(e) => { setPage((page) => page + 1); }} style={{ width: "20%", margin: "auto" }}>Load More</button>) : ""}
          </div>
        </div>
        <div className="column is-3" style={{ padding: "3vh 3vw", height: props.isMobile && "60vh", overflow: props.isMobile && "scroll" }}>
          <div className="box" style={{ borderTop: "2px solid #8c3e7c", height: "100%", maxHeight: "100%", overflowY: "scroll", backgroundColor: "#8c3e7c" }}>
            <h1 style={{ fontSize: "150%", color: "white" }}>Saved Messages</h1>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {savedMessages.map((msg, index) => {
                return <div className="box" style={{ height: "10%", backgroundColor: "#e6cede", verticalAlign: "middle", textAlign: "center", display: "table-cell" }}>
                  <strong>{new Date((msg['sent_date'] * 1000)).toLocaleString()}<br />{msg['chat_name']}</strong>
                  <br />
                  <span style={{ wordWrap: "break-word" }}>{msg['message'].slice(0, 30)}...</span>
                  <br />
                  <button className="button is-success" onClick={() => setShowSaveMessageFull(msg)} style={{ margin: "5% 0% 0% 0%", padding: "4%" }}>Show</button>&emsp;
                  <button className="button is-danger" onClick={(e) => removeSavedTelMessage(e, msg['id'])} style={{ margin: "5% 0% 0% 0%", padding: "4%" }}>Remove</button>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>

      {showSaveMessageFull !== null && <div
        id="modal-js-example"
        className={"modal" + (showSaveMessageFull !== null ? " is-active" : "")}
      >
        <div className="modal-background" onClick={() => { setShowSaveMessageFull(null) }}></div>

        <div
          className="modal-content"
          style={{ width: props.isMobile ? "90vw" : "auto", minWidth: "25vw" }}
        >
          <div className="box" style={{ textAlign: "center" }}>
            <article class="message is-light" style={{ textAlign: "left", border: "1px solid black" }}>
              <div class="message-header" style={{ borderBottom: "1px solid lightgray" }}>
                <p>{new Date((showSaveMessageFull['sent_date'] * 1000)).toLocaleString()}</p>
                <p>{showSaveMessageFull['chat_name']}&emsp;</p>
              </div>
              <div class="message-body" style={{ whiteSpace: 'break-spaces' }}>
                {showSaveMessageFull['message'].split("\n").map((msgRow) => {
                  return <>{msgRow.split(" ").map((msgWord) => {
                    if (msgWord.includes("https")) {
                      return <><a target="_blank" rel="noopener noreferrer" href={msgWord} style={{ color: "SlateBlue" }}>{msgWord}</a>{" "}</>
                    } else {
                      return <><span style={{ fontWeight: msgWord.includes("$") && "700" }}>{msgWord}</span>{" "}</>
                    }
                  })}<br /></>
                })}
              </div>
              <button className="button is-success" onClick={() => window.open("https://t.me/s/" + showSaveMessageFull['user_name'])} style={{ margin: "auto", display: "flex" }}>Go to chat</button>
              <br />
            </article>
            <br />
            <br />
            <div className="columns is-mobile is-centered">
              <button
                className="button is-rounded is-danger"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => {
                  setShowSaveMessageFull(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default Telegram;
