import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const StartingAmazonFBA = () => {
  return (
    <div>
      <Helmet>
        <title>Nepeto - Starting Amazon FBA Business</title>
        <meta
          name="description"
          content="Ready to start your Amazon FBA business? Explore the essential steps to launch your own e-commerce venture with Amazon's Fulfillment by Amazon program. Simplify your journey and turn your dreams into a thriving online store. Get started today with expert guidance!"
        />
        <meta
          name="keywords"
          content="amazon fba, amazon fba business, fba business"
        />
      </Helmet>
      <header>
        <h1 className="blogTitle">Starting Amazon FBA Business</h1>
      </header>
      <br />
      <main>
        <article className="blogContent">
          <h2>Setting Up Your Amazon Seller Account</h2>
          <p>
            Setting up your Amazon Seller account is akin to setting up a social
            media profile - you need to fill in the blanks.
          </p>
          <p>
            You'll need to provide your business information, agree to Amazon's
            seller agreement, and provide your tax information. It might seem
            boring, but hey, every grand adventure starts with a single step!
          </p>
          <img
            src="/blogs/blog2/1.png"
            alt="Setting Up Your Amazon Seller Account"
          />

          <h2>Navigating the Amazon Seller Dashboard: Key Features</h2>
          <p>
            Okay, imagine you've just entered the cockpit of a jumbo jet. There
            are all these buttons and screens.
          </p>
          <p>
            Welcome to the Amazon Seller dashboard, our jumbo jet cockpit. The
            goosebumps-inducing features include inventory management, sales
            performance metrics, advertising and promotional tools, and so much
            more.
          </p>
          <img
            src="/blogs/blog2/2.png"
            alt="Navigating the Amazon Seller Dashboard"
          />

          <h2>Selecting a Profitable Product Category</h2>
          <p>
            Picture yourself in a candy shop, with hundreds of delicious
            options. Choosing the right product category feels exactly the same,
            except these candies are cash cows! And just like a candy shop, look
            for the popular but unique items that sell rapidly and have less
            competition.
          </p>
          <img
            src="/blogs/blog2/3.png"
            alt="Selecting a Profitable Product Category"
          />
          
          <iframe
            src="https://www.youtube-nocookie.com/embed/hqbZK19Lri4?si=fr57y2bxEDwG55gA"
            title="YouTube video player"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </article>
      </main>
      <footer className="blogFooter">
        <p>Published on: 10.26.2023 by Jon Hlyo</p>
        <Link to="/blog">Back to Blog</Link>
      </footer>
    </div>
  );
};

export default StartingAmazonFBA;
