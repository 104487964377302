import { useState } from "react";
import ModalForSettings from "../../Products/Components/ModalForSettings";
import ModalForSupplierFilter from "../../Products/Components/ModalForSupplierFilter";
import ModalForHiddenProducts from "../../Products/Components/ModalForHiddenProducts";
import ModalForProFeatures from "../../Products/Components/ModalForProFeatures";

function OOSFilterBar(props) {
  const [modalSetting, setModalSetting] = useState("");
  const [searchText, setSearchText] = useState(props.filters.hasOwnProperty("search") ? props.filters.search : "");
  const [filterSupplierModalOpen, setFilterSupplierModalOpen] = useState(false);
  const [showHiddenProducts, setShowHiddenProducts] = useState(false);
  const [proModalOpen, setProModalOpen] = useState(false);
  const [bsrType, setBsrType] = useState(false);
  const [categories, setCategories] = useState({
    "Kitchen & Dining": false,
    "Beauty & Personal Care": false,
    "Toys & Games": false,
    "Office Products": false,
    "Home & Kitchen": false,
    "Baby": false,
    "Unique Finds": false,
    "Sports & Outdoors": false,
    "Patio, Lawn & Garden": false,
    "Arts, Crafts & Sewing": false,
    "Tools & Home Improvement": false,
    "Automotive": false,
    "Industrial & Scientific": false,
    "Video Games": false,
    "Health & Household": false,
    "Our Brands": false,
    "Cell Phones & Accessories": false,
    "Pet Supplies": false,
    "Books": false,
    "Musical Instruments": false,
    "Clothing, Shoes & Jewelry": false,
    "Grocery & Gourmet Food": false,
    "Climate Pledge Friendly": false,
    "Electronics": false,
    "Amazon Renewed": false,
    "Amazon Devices & Accessories": false,
    "Computers & Accessories": false,
    "Appliances": false,
    "CDs & Vinyl": false,
    "Software": false,
    "Handmade Products": false,
    "Collectible Coins": false,
    "Movies & TV": false,
    "Camera & Photo Products": false,
  });
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);

  return (
    <div
      className="columns is-centered is-multiline is-mobile"
      id="filterbarTt"
      style={{
        textAlign: "center",
        marginTop: props.isMobile ? "1vh" : "5vh",
        marginLeft: !props.isMobile && "-9vw",
        marginRight: props.isMobile && ".5vw",
        verticalAlign: "middle",
      }}
    >
      <div className="column is-2-desktop is-6-mobile">
        <div className="field has-addons">
          {!props.isMobile && <><button
            className="button is-rounded darkbutton"
            onClick={() => setProModalOpen(true)}
            style={{
              marginTop: "-5.5vh",
              fontSize: props.isMobile ? "100%" : "0.85vw",
              position: "absolute"
            }}
            id="proFeatTt"
          >
            Pro Features
          </button>
            <label class="checkbox" style={{ fontSize: "100%", position: "absolute", marginTop: "-2.15%", marginLeft: "9%" }}>
              <input
                type="checkbox"
                style={{ transform: "scale(1.5)" }}
                onChange={(e) =>
                  props.setFilters((filters) => ({
                    ...filters,
                    favoritesOnly: +e.target.checked,
                  }))
                }
                checked={props.filters.favoritesOnly || false}
              />
              &nbsp;&thinsp;Show only Favorites
            </label></>}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width={props.isMobile ? "17vw" : "3.5vw"}
            height={"4.4vh"}
            viewBox="0 0 120.000000 120.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{ cursor: "pointer" }}
            onClick={() => window.location.reload()}
          >
            <g
              transform="translate(0.000000,111.000000) scale(0.0900000,-0.086000)"
              fill="#000000"
              stroke="none"
            >
              <path d="M813 1266 c-77 -25 -153 -94 -183 -167 l-12 -29 -247 0 c-266 0 -291 -5 -291 -53 0 -27 -6 -17 184 -277 l151 -205 5 -253 c5 -236 6 -255 24 -268 32 -23 50 -17 142 51 63 46 92 74 101 97 7 20 12 97 12 199 l1 166 58 82 57 82 51 -6 c30 -3 74 1 108 10 270 71 306 430 56 555 -56 28 -157 36 -217 16z m182 -82 c85 -40 145 -146 129 -231 -33 -185 -248 -257 -378 -127 -107 107 -78 286 56 353 52 26 145 28 193 5z m-384 -251 c8 -64 45 -130 99 -176 l40 -34 -18 -24 c-11 -13 -40 -55 -66 -92 l-46 -68 0 -178 0 -179 -55 -41 c-30 -22 -57 -41 -60 -41 -3 0 -5 96 -5 213 0 185 -2 217 -18 247 -10 19 -82 122 -160 228 -78 106 -142 195 -142 198 0 2 96 3 213 2 l212 -3 6 -52z" />
              <path d="M800 1070 c-8 -15 -5 -25 20 -53 l29 -34 -24 -23 c-28 -26 -32 -61 -11 -79 17 -14 41 -5 70 28 l19 21 34 -30 c28 -25 38 -28 53 -20 27 15 25 39 -7 72 l-27 28 27 28 c29 31 34 51 15 70 -18 18 -33 15 -68 -14 l-31 -27 -28 27 c-32 31 -56 33 -71 6z" />
            </g>
          </svg>

          <div className="control">
            <input
              className="input searchproduct"
              type="text"
              style={{
                backgroundColor: "rgb(179,127,170)",
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              placeholder={"Search Product"}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div className="control">
            <button
              className="button is-success"
              style={{
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              onClick={() => {
                props.setFilters((filters) => ({
                  ...filters,
                  search: searchText,
                }));
              }}
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <details className="selectlight selectdropdown" style={{ fontSize: props.isMobile ? "1.9vh" : ".85vw" }}>
          <summary onClick={() => {
            if (categoryDropdownOpen) {
              props.setFilters((filters) => ({
                ...filters,
                category: Object.keys(categories).sort().filter(key => categories[key] === true),
              }))
              if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
                let tempFilters = { ...props.filters }
                delete tempFilters['category']
                props.setFilters(tempFilters)
              }
            }
            setCategoryDropdownOpen(before => !before);
          }} style={{ backgroundColor: categoryDropdownOpen && "green", color: categoryDropdownOpen && "white" }}>{categoryDropdownOpen ? "Save" : "Category"}</summary>
          <ul>
            <li key={"SelectAll"}>
              <label><input type="checkbox" name={"SelectAll"} value={"SelectAll"}
                onChange={(e) => {
                  setCategories((prevCat) => {
                    const updatedCategories = { ...prevCat };

                    Object.keys(updatedCategories).forEach((key) => {
                      updatedCategories[key] = e.target.checked;
                    });

                    return updatedCategories;
                  })
                }} /> {Object.values(categories).every(value => value === true) ? "Unselect All" : "Select All"}</label>
            </li>
            {Object.keys(categories).sort().map((category, index) => {
              return <li key={index}>
                <label><input type="checkbox" name={category} value={category} checked={categories[category]}
                  onChange={(e) => { setCategories({ ...categories, [category]: e.target.checked }) }} /> {category}</label>
              </li>;
            })}
          </ul>
        </details>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("qty")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("qty") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          In&nbsp;Stock
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("moq")}
        >
          MOQ
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("price")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("price") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          Price
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setBsrType(true)}
          style={{
            border:
              props.filters &&
              (props.filters.hasOwnProperty("bsr") ||
                props.filters.hasOwnProperty("top_bsr")) &&
              "3px solid rgb(72,199,142)",
          }}
        >
          BSR
        </button>
        <div
          id="modal-js-example"
          className={
            "modal" + (bsrType ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setBsrType(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                BSR Type
              </p>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => { setModalSetting("bsr"); setBsrType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("bsr") &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  Sales Rank #
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => { setModalSetting("top_bsr"); setBsrType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("top_bsr") &&
                      "3px solid rgb(72,199,142)",
                  }}
                >
                  Top BSR %
                </button>
              </div>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setBsrType(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="column is-2-desktop is-4-mobile"
        style={{
          marginLeft: !props.isMobile && "7vw",
          marginRight: "-8vw",
          width: props.isMobile && "100%"
        }}
      >
        <button
          className="button is-rounded darkbutton"
          onClick={() => setShowHiddenProducts(true)}
          style={{
            float: !props.isMobile && "right",
            marginTop: !props.isMobile && "-6vh",
            fontSize: props.isMobile ? "100%" : "0.85vw",
          }}
        >
          My Hidden Products
        </button>
        <button
          className="button is-rounded darkbutton"
          onClick={() => setFilterSupplierModalOpen(true)}
          id="filtersupTt"
          style={{
            float: !props.isMobile && "right",
            border:
              props.filters &&
              (props.filters.hasOwnProperty("sourceName") ||
                props.filters.hasOwnProperty("supplierTopMinPurchase") ||
                props.filters.hasOwnProperty("supplierAimAmazon")) &&
              "3px solid rgb(72,199,142)",
          }}
        >
          Filter Suppliers
        </button>
        {props.isMobile && <button
          className="button is-rounded darkbutton"
          onClick={() => setProModalOpen(true)}
          style={{
            fontSize: props.isMobile ? "100%" : "0.85vw",
          }}
        >
          Pro Features
        </button>}
      </div>

      <ModalForSettings
        filters={props.filters}
        setFilters={props.setFilters}
        modalSetting={modalSetting}
        setModalSetting={setModalSetting}
        isMobile={props.isMobile}
        supplierType={props.supplierType}
      />
      <ModalForSupplierFilter
        filters={props.filters}
        setFilters={props.setFilters}
        filterSupplierModalOpen={filterSupplierModalOpen}
        setFilterSupplierModalOpen={setFilterSupplierModalOpen}
        isMobile={props.isMobile}
        suppliersList={props.suppliersList}
      />
      {showHiddenProducts && <ModalForHiddenProducts
        isMobile={props.isMobile}
        setShowHiddenProducts={setShowHiddenProducts}
        showHiddenProducts={showHiddenProducts}
      />}
      <ModalForProFeatures
        isMobile={props.isMobile}
        setProModalOpen={setProModalOpen}
        proModalOpen={proModalOpen}
        setFilters={props.setFilters}
        filters={props.filters}
        data={props.data}
        oos={true} />
    </div>
  );
}

export default OOSFilterBar;
