import { useAuth0 } from "@auth0/auth0-react";

function SignUpFreeTrialButton(props) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  return !isLoading && !isAuthenticated ? (
    <button
      className="button is-rounded"
      style={{
        backgroundColor: "#32CD32",
        color: "#ffffff",
        fontSize: props.fontSize
      }}
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
          },
          appState: { returnTo: "/pricing?s=1" },
        })
      }
    >
      Sign up for Free!
    </button>
  ) : (
    <button
      className="button is-rounded"
      style={{
        backgroundColor: "#32CD32",
        color: "#ffffff",
        fontSize: props.fontSize
      }}
      onClick={() =>  window.location.href = "/pricing"}
    >
      Start a Free Trial!
    </button>
  );
}

export default SignUpFreeTrialButton;
