import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import ModalForBuyHCredits from "../../Dashboard/Components/ModalForBuyHCredits";
import Checkout from "../../../components/Checkout";

function ModalForHide(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [weeks, setWeeks] = useState(1);
  const [useCredits, setUseCredits] = useState(true);
  const [buyHideCreditsModal, setBuyHideCreditsModal] = useState(false);
  const [readyToPay, setReadyToPay] = useState(false);

  const hideProduct = async (e) => {
    if (e !== false) {
      e.target.style.cursor = "wait";
      e.target.disabled = true;
    }
    const accessToken = await getAccessTokenSilently();

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json; charset=utf8",
        "Content-Type": "application/json; charset=utf8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        product_url: props.hideModalProduct.url === undefined ? props.hideModalProduct.asin : props.hideModalProduct.url.replace(
          "X".repeat(props.hideModalProduct.source_name.length),
          props.hideModalProduct.source_name
        ),
        weeks: weeks,
        img: props.hideModalProduct?.az_img || props.hideModalProduct.img,
        asin: props.hideModalProduct.asin.split("/")[props.hideModalProduct.asin.split("/").length - 1],
        useCredits: useCredits
      }),
    };

    fetch("https://server.nepeto.com/hide_product/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data;
      })
      .catch((error) => {
        alert("Error. Please try again.")
      });
  };

  return (
    <div
      id="modal-js-example"
      className={"modal" + (props.hideModalProduct !== "" ? " is-active" : "")}
    >
      <div className="modal-background" onClick={() => {
        props.setHideModalProduct("");
      }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "24vw" }}
      >
        <div className="box" style={{ textAlign: "center" }}>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            <strong>Want to hide this product from other Nepeto users and keep it to
              yourself?</strong>
            <br /><br />
            {useCredits && <span>You have <strong>{props.hideCredits ?? "N/A"}</strong> hide credits.<br /><button className="gotobuttonsmall button" onClick={() => setBuyHideCreditsModal(true)}>Buy More</button><br />Costs only 1 credit/week</span>}
            {!useCredits && <>Costs only $2/week.<br />{props.hideCredits && "or"}<br />
              {props.hideCredits && <button onClick={() => { setUseCredits(true); setWeeks(0) }} className="button lightbutton" style={{ width: '50%', borderRadius: "35px" }}>Use Hide Credits</button>}</>}
          </p>
          <br />
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            Hide for:
            <br />
            <span
              style={{
                fontSize: props.isMobile ? "4vh" : "2.1vw",
                color: "rgb(100,20,90)",
              }}
            >
              <button
                onClick={() => setWeeks(weeks => weeks > 1 ? weeks - 1 : weeks)}
                className="button is-rounded selectlight"
                style={{ width: props.isMobile ? "7vw" : "3vw", height: props.isMobile ? "7vw" : "2vw", fontSize: props.isMobile ? "4vw" : "1.5vw", marginTop: "1.5vh" }}
              >
                -
              </button>
              &nbsp;&nbsp;{weeks}&nbsp;&nbsp;
              <button
                onClick={() => setWeeks(weeks => useCredits ? (((weeks + 1) > (props.hideCredits ?? 0)) ? weeks : (weeks + 1)) : weeks + 1)}
                className="button is-rounded selectlight"
                style={{ width: props.isMobile ? "7vw" : "3vw", height: props.isMobile ? "7vw" : "2vw", fontSize: props.isMobile ? "4vw" : "1.5vw", marginTop: "1.5vh" }}
              >
                +
              </button>
            </span>
            <br />
            Week(s)
          </p>
          <br />
          {props.hideCredits <= 0 && <p style={{color: "red", display: "inline-grid"}}>You do not have enough credits. <button className="gotobuttonsmall button" onClick={() => setBuyHideCreditsModal(true)}>Buy More</button></p>}
          <br /><br /><br />
          <div className="columns is-mobile is-centered">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setHideModalProduct("");
              }}
            >
              Cancel
            </button>
            &emsp;
            {useCredits ? <button
              className="button is-rounded is-success"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={(e) => hideProduct(e)}
              disabled={props.hideCredits <= 0}
            >
              Hide
            </button> : !readyToPay ?
              <button
                className="button is-rounded is-success"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => setReadyToPay(true)}
              >
                Pay
              </button> : <div>
                <Checkout width="100%" margin="" price={(2 * weeks).toString()} completeAction={() => hideProduct(false)} /></div>}
          </div>
        </div>
      </div>

      {buyHideCreditsModal && (
        <ModalForBuyHCredits setBuyHideCreditsModal={setBuyHideCreditsModal} />
      )}
    </div>
  );
}

export default ModalForHide;
