import { useAuth0 } from "@auth0/auth0-react";
import HistoryGraph from "../../Products/Components/HistoryGraph";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function A2WAdvancedDataModal(props) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAdvancedData = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch(
        "https://server.nepeto.com/get_walmart_data/" +
          props.advancedDataProduct.walmart_url.split("?")[0].split("/")[
            props.advancedDataProduct.walmart_url.split("/").length - 1
          ] +
          "/",
        {
          mode: "cors",
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });
    };

    if (!isLoading && isAuthenticated) {
      getAdvancedData();
    }
  }, [
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    props.advancedDataProduct.walmart_url
  ]);

  return (
    <div
      id="modal-js-example"
      className={
        "modal" + (props.advancedDataProduct !== null ? " is-active" : "")
      }
    >
      <div className="modal-background" onClick={() => {
                  props.setAdvancedDataProduct(null);
                }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "47vw" }}
      >
        {data === "No permissions" ? (
          <div className="box" style={{ textAlign: "center" }}>
            <strong style={{ fontSize: "150%" }}>Advanced Product Data</strong>
            <br />
            <br />
            <span>
              The advanced product data is available for the Advanced Plan and
              above.
              <br />
              Please upgrade your plan to access this data.
            </span>
            <br />
            <br />
            <button
              className="button is-rounded"
              style={{
                backgroundColor: "#32CD32",
                color: "#ffffff",
                fontSize: props.fontSize
              }}
              onClick={() => navigate("/pricing")}
            >
              Upgrade
            </button>
          </div>
        ) : (
          <div className="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
              <strong>Advanced Product Data</strong>
              <br />
              <br />
              <div class="columns">
                <div
                  class="column is-5"
                  style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}
                >
                  <table
                    class="table is-bordered"
                    style={{
                      width: "100%",
                      textAlign: "left",
                      height: "100%",
                      fontSize: "65%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>Rating</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.rating === null ? "N/A" : (data.rating + "★")
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Amount of Reviews</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.reviews
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Is Sponsored?</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.is_sponsored === 0 ? "No" : "Yes"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Has Variations</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.has_variations === 0 ? "No" : "Yes"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Seller Name</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.seller_name
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Estimated Sales</strong></td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <strong>{data.sales_estimate === null ? "N/A" : data.sales_estimate}</strong>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Revenue</strong></td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <strong>${data.revenue === "0.00" ? "N/A" : data.revenue}</strong>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="column is-7"
                  style={{ zIndex: 1, backgroundColor: "white" }}
                >
                  {props.isMobile && <br />}
                  {data === null ? (
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  ) : (
                    <HistoryGraph
                      valueName="Price History"
                      history={data.pricehist}
                      color="darkblue"
                    />
                  )}
                  <hr style={{ marginTop: "0" }} />
                  {data === null ? (
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  ) : (
                    <HistoryGraph
                      valueName="Estimated Sales"
                      history={data.saleshist}
                      color="darkgreen"
                    />
                  )}
                </div>
              </div>
            </p>
            <br />
            <br />
            <br />
            <div className="columns is-mobile is-centered">
              <button
                className="button is-rounded is-danger"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => {
                  props.setAdvancedDataProduct(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default A2WAdvancedDataModal;
