import "./CarCard.css";

function CarCard(props) {
  const spanStyle = {
    backgroundColor: "#8c3e7c",
    color: "#e6cede",
    width: "5vw",
    maxWidth: "5vw",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whitSpace: "nowrap",
    padding: "0% 1%",
    fontSize: ".75vw",
    cursor: "pointer",
    textDecoration: "underline",
  };

  return (
    <div
      style={{
        height: "21vw",
        width: "15vw",
        backgroundColor: "#e6cede",
        borderRadius: "20px",
        margin: "3vh 0px 1vh 0px",
      }}
      className="defFont"
    >
      {props.loading ? (
        <i
          className="fa fa-spinner fa-spin"
          aria-hidden="true"
          style={{ marginTop: "60%" }}
        ></i>
      ) : (
        <>
          <p
            style={{
              margin: "auto",
              whiteSpace: "nowrap",
              width: "80%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize: "1vw",
            }}
          >
            {props.product.title.slice(0, 30)}...
            <br />
            <a
              href={props.product.asin}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "underline",
                fontWeight: "700",
                color: "black",
              }}
            >
              {
                props.product.asin.split("/")[
                  props.product.asin.split("/").length - 1
                ]
              }
            </a>
          </p>
          <div>
            <img
              src={props.product.img}
              alt="product"
              style={{
                width: "5vw",
                height: "5vw",
                objectFit: "scale-down",
                backgroundColor: "white",
                cursor: "pointer"
              }}
              onClick={() => window.open(
                props.product.url.replace(
                  "X".repeat(props.product.source_name.length),
                  props.product.source_name
                )
              )}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/assets/noimage.png";
              }}
            />
            <img
              src={(props.product.az_img !== null && props.product.az_img !== "") ? 
                    props.product.az_img : 
                    `https://images.amazon.com/images/P/${props.product.asin.split("/")[props.product.asin.split("/").length - 1]}.jpg`}
              alt="product"
              style={{
                width: "5vw",
                height: "5vw",
                marginLeft: "2vw",
                objectFit: "scale-down",
                backgroundColor: "white",
                cursor: "pointer"
              }}
              onClick={() => window.open(props.product.asin)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/assets/noimage.png";
              }}
            />
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "1.5vw" }}>
              <span
                className="tag is-rounded has-text-weight-normal"
                style={spanStyle}
                onClick={() => {
                    window.open(
                      props.product.url.replace(
                        "X".repeat(props.product.source_name.length),
                        props.product.source_name
                      )
                    );
                }}
              >
                &ensp;{props.product.source_name}&ensp;
              </span>
              <p style={{ fontSize: "1.25vw", fontWeight: "700" }}>
                ${props.product.price.toFixed(2)}
              </p>
            </div>
            <div style={{ marginLeft: "2vw" }}>
              <span
                className="tag is-rounded has-text-weight-normal"
                style={spanStyle}
                onClick={() => {
                  window.open(props.product.asin);
                }}
              >
                &ensp;Amazon&ensp;
              </span>
              <p style={{ fontSize: "1.25vw", fontWeight: "700" }}>
                ${props.product.az_price.toFixed(2)}
              </p>
            </div>
          </div>

          <div
            style={{
              width: "70%",
              backgroundColor: "rgba(200,0,0,0.1)",
              border: "1px solid purple",
              borderRadius: "20px",
              marginTop: "3%",
              margin: "auto",
              fontSize: ".7vw",
              fontWeight: "700",
            }}
          >
            #{props.product.sales_rank} |{" "}
            {JSON.parse(props.product.az_offers).length}
            {JSON.parse(props.product.az_offers).length === 20 && "+"}{" "}
            <i className="fa fa-user" aria-hidden="true"></i> | x
            {props.product.qty}
            <br />
            MOQ: {props.product.moq}
          </div>

          <p style={{ color: "#009201", fontSize: "1.7vw", fontWeight: "700" }}>
            {Math.ceil(props.product.roi * 100)}% ROI
          </p>
          <p style={{ fontSize: ".7vw", fontWeight: "700" }}>
            After Amazon and FBA Fees
          </p>
          <strong
            className="button is-rounded is-small"
            onClick={() => {
                window.open(
                  props.product.url.replace(
                    "X".repeat(props.product.source_name.length),
                    props.product.source_name
                  )
                );
            }}
            style={{
              backgroundColor: "#8c3e7c",
              color: "white",
              fontSize: ".6vw",
              marginTop: ".7vw",
              padding: "0px 2vw",
            }}
          >
            LINK
          </strong>
        </>
      )}
    </div>
  );
}

export default CarCard;
