import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { retailLogos } from "./RetailSuppliersLogos";

function ModalForSupplierFilter(props) {
  const {
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();
  const [sourceName, setSourceName] = useState("");
  const [suppliers, setSuppliers] = useState(
    props.suppliersList.reduce((acc, item) => {
      acc[item] = false;
      return acc;
    }, {})
  );
  const [supplierTopMinPurchase, setSupplierTopMinPurchase] = useState("");
  const [supplierAimAmazon, setSupplierAimAmazon] = useState(false);
  const [suggestSupplier, setSuggestSupplier] = useState("");
  const [searchSupplier, setSearchSupplier] = useState("");

  useEffect(() => {
    setSuppliers(
      props.suppliersList.reduce((acc, item) => {
        acc[item] = false;
        if (props.filters["sourceName"]) {
          acc[item] = props.filters["sourceName"].includes(item)
        }
        return acc;
      }, {})
    );
  }, [props.suppliersList]);

  useEffect(() => {
    if (props.filterSupplierModalOpen) {
      if (props.filters["sourceName"]) {
        setSourceName(props.filters["sourceName"]);
      }
      if (props.filters["supplierTopMinPurchase"]) {
        setSupplierTopMinPurchase(props.filters["supplierTopMinPurchase"]);
      }
      if (props.filters["supplierAimAmazon"]) {
        setSupplierAimAmazon(props.filters["supplierAimAmazon"]);
      }
    }
  }, [props.filters, props.filterSupplierModalOpen]);

  return (
    <div
      id="modal-js-example"
      className={"modal" + (props.filterSupplierModalOpen ? " is-active" : "")}
    >
      <div
        className="modal-background"
        onClick={() => {
          setSourceName("");
          setSupplierAimAmazon(false);
          setSupplierTopMinPurchase("");
          props.setFilterSupplierModalOpen(false);
        }}
      ></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "50vw", minHeight: "97vh" }}
      >
        <div className="box" style={{minHeight: "97vh"}}>
        <div className="floatingAccept">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : "1vw", boxShadow: "0px 0px 5px 0px black" }}
              onClick={() => {
                setSourceName("");
                setSupplierAimAmazon(false);
                setSupplierTopMinPurchase("");
                props.setFilterSupplierModalOpen(false);
              }}
            >
              Cancel
            </button>
            &emsp;
            <button
              className="button is-rounded is-success"
              style={{ fontSize: props.isMobile ? "1.9vh" : "1vw", boxShadow: "0px 0px 5px 0px black" }}
              onClick={() => {
                let newSourceName = sourceName;
                setSourceName(
                  Object.keys(suppliers)
                    .sort()
                    .filter((key) => suppliers[key] === true)
                );
                newSourceName = Object.keys(suppliers)
                  .sort()
                  .filter((key) => suppliers[key] === true);
                if (
                  Object.keys(suppliers)
                    .sort()
                    .filter((key) => suppliers[key] === true).length === 0
                ) {
                  setSourceName("");
                  newSourceName = "";
                }
                props.setFilters((filters) => ({
                  ...filters,
                  sourceName: newSourceName,
                  supplierTopMinPurchase: supplierTopMinPurchase,
                  supplierAimAmazon: supplierAimAmazon,
                }));
                props.setFilterSupplierModalOpen(false);
              }}
            >
              Save
            </button>
        </div>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            Filter Suppliers
          </p>
          <br />
          <div className="is-centered is-mobile">
            <span style={{ fontSize: "115%", fontWeight: "400" }}>
               Choose suppliers:
            </span>
            <br/>
            <label style={{cursor: "pointer"}}>
              <input
                type="checkbox"
                name={"SelectAll"}
                value={"SelectAll"}
                onChange={(e) => {
                  setSuppliers((prevSup) => {
                    const updatedSups = { ...prevSup };

                    Object.keys(updatedSups).forEach((key) => {
                      updatedSups[key] = e.target.checked;
                    });

                    return updatedSups;
                  });
                }}
              />{" "}
              {Object.values(suppliers).every((value) => value === true)
                ? "Unselect All"
                : "Select All"}
            </label>
            <br/>
            <br/>
            <input
              className="input searchproduct"
              placeholder="Search by Supplier Name"
              style={{
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
                backgroundColor: "rgb(179,127,170)",
                width: props.isMobile ? "80%" : "30%"
              }}
              onChange={(e) =>
                setSearchSupplier(e.target.value.toLowerCase().replaceAll(" ", ""))
              }
            />
            <br/><br/>
            {Array.from({ length: Math.ceil(props.suppliersList.filter((supp) => supp.includes(searchSupplier)).sort().length / 4) }, (_, index) => props.suppliersList.filter((supp) => supp.includes(searchSupplier)).sort().slice(index * 4, index * 4 + 4)).map((subList, index) => {
              return (<div key={index} class="columns is-desktop">
                  {subList.map((supplier, subIndex) => {
                    return (
                      <div class="column is-3" key={subIndex}>
                        <label style={{display: "flex", position: "relative", boxShadow: suppliers[supplier] ? "inset 0px 0px 10px gray" : "0px 0px 5px 0px gray", borderRadius: "10px", padding: "5px", backgroundColor: suppliers[supplier] && "rgba(0,255,0,0.1)", cursor: "pointer", flexDirection: "column", alignItems: "center", height: props.isMobile ? "40vw" : "10vw", margin: "auto", justifyContent: "center"}}>
                          <input
                            type="checkbox"
                            name={supplier}
                            value={supplier}
                            checked={suppliers[supplier]}
                            style={{opacity: "0"}}
                            onChange={(e) => {
                              setSuppliers({
                                ...suppliers,
                                [supplier]: e.target.checked,
                              });
                            }}
                          />{" "}
                          {retailLogos.hasOwnProperty(supplier) && <img
                            alt="logo"
                            class="transparent-white"
                            src={`/assets/logos/${retailLogos[supplier]}.png`}
                            loading="lazy"
                            style={{maxWidth: !props.isMobile && "70%", marginTop: "-10%", maxHeight: props.isMobile ? "85%" : '65%'}}
                          />}
                          <span style={{fontWeight: "500", position: "absolute", bottom: retailLogos.hasOwnProperty(supplier) && "0", fontSize: !props.isMobile && "1vw"}}>{supplier.slice(0, 19)}<br/>{supplier.slice(19)}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>)
              })}
            <br />
              <form
                action="https://server.nepeto.com/email"
                method="post"
                accept-charset="UTF-8"
                className="box p-5"
                style={{
                  border: "1px solid rgba(0,0,0,0.12)",
                  marginBottom: "0",
                  width: props.isMobile ? "100%" : "60%",
                  margin: "auto"
                }}
              >
                <label className="is-block mb-4">
                  <span className="is-block mb-2" style={{ fontSize: "100%" }}>
                    Can't find your favorite supplier? Let us know their name,
                    and we will add them!
                  </span>
                  <div style={{}}>
                    <input
                      name="name"
                      type="text"
                      className="input"
                      placeholder="Suggest a new supplier..."
                      style={{ width: "75%" }}
                      onChange={(e) => setSuggestSupplier(e.target.value.replace(" ", "").replace("'", "").toLowerCase())}
                    />
                    <button
                      type="submit"
                      className="button px-4"
                      style={{
                        backgroundColor: "#8c3e7c",
                        color: "white",
                        width: "25%",
                      }}
                      onClick={(e) => {if (props.suppliersList.includes(suggestSupplier) || suggestSupplier === "") {alert(`We already have ${suggestSupplier} in Nepeto! Select it from the 'Supplier Name' dropdown above!`); e.preventDefault();}}}
                    >
                      Send
                    </button>
                  </div>
                </label>
                <div className="mb-4">
                  <div
                    style={{
                      textIndent: "-99999px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      position: "absolute",
                    }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="_gotcha"
                      tabindex="-1"
                      autocomplete="off"
                    />
                    <input
                      type="text"
                      name="what_is_it"
                      value={"new_supplier"}
                      tabindex="-1"
                      autocomplete="off"
                    />
                    <input
                      type="text"
                      name="email"
                      value={(!isLoading && isAuthenticated) ? user.email : "N/A"}
                      tabindex="-1"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </form>
            <br />
            {props.supplierType !== "retail" && (
              <>
                <div className="field" style={{ marginTop: "1vh" }}>
                  <p
                    className="control has-icons-left"
                    style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw", width: props.isMobile ? "80%" : "40%", margin: "auto" }}
                  >
                    <input
                      className="input searchproduct"
                      type="number"
                      placeholder="Top Min. Purchase"
                      style={{
                        color: "white",
                        fontSize: props.isMobile ? "1.9vh" : ".95vw",
                        backgroundColor: "rgb(179,127,170)",
                      }}
                      value={supplierTopMinPurchase}
                      onChange={(e) =>
                        setSupplierTopMinPurchase(e.target.value)
                      }
                    />
                    <span className="icon is-small is-left">
                      <i className="fa fa-usd" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
                <label
                  className="checkbox"
                  style={{
                    fontSize: props.isMobile ? "2vh" : "1vw",
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => setSupplierAimAmazon(e.target.checked)}
                    checked={supplierAimAmazon}
                  />
                  &nbsp;Supplier Aims for Amazon Products
                </label>
                <br/><br/>
              </>
            )}
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  );
}

export default ModalForSupplierFilter;
