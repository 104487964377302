import guideIcon from './guideIcon.svg';

function GuideCircle(props) {
    return <div
        style={{
            position: "absolute", 
            lineHeight: "65px", 
            top: "1vh", 
            left: "95vw", 
            zIndex: 40, 
            borderRadius: "500px", 
            width: "3.5vw", 
            height: "4vw", 
            color: "white", 
            textAlign: "center",
            cursor: "pointer"
        }}
        className="guideCircle tooltip guideAriaanaIcon"
        onClick={() => props.onClick ? props.onClick() : ""}>
        <img src={guideIcon} alt="wwwLogo" className="block-icon block-icon-img" />
        <span className="tooltiptext ariannattt">Arianna's Guides</span>
    </div>
}

export default GuideCircle;
