import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";
import Card from "../Components/Card";
import "./CardSwipper.css";
import { useEffect, useState } from "react";
import ModalForHide from "../Components/ModalForHide";
import NotDirectLinkPopup, {
  warningSuppliers,
} from "../Components/NotDirectLinkPopup";
import SignUpFreeTrialButton from "../../Home/components/SignUpFreeTrialButton";
import AdvancedDataModal from "../Components/AdvancedDataModal";
import MessageBeforeLinkPopup from "../Components/MessageBeforeLinkPopup";

function CardSwipper(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [innerPage, setInnerPage] = useState(1);
  const [isInnerLoading, setInnerLoading] = useState(false);
  const [hideModalProduct, setHideModalProduct] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [advancedDataProduct, setAdvancedDataProduct] = useState(null);
  const [showMessageObj, setShowMessageObj] = useState(null);

  const openSuppLink = (product) => {
    if (product.source_name === "b2bsportswear") {
      product.url = product.url.replace("+", "").replace("--", "-")
    }
    if (!warningSuppliers.includes(product.source_name)) {
      window.open(
        product.url.replace(
          "X".repeat(product.source_name.length),
          product.source_name
        )
      );
    } else {
      setLinkUrl(
        product.url.replace(
          "X".repeat(product.source_name.length),
          product.source_name
        )
      );
    }
  };

  useEffect(() => {
    const unLoad = () => {
      setInnerLoading(false);
    };
    setTimeout(unLoad, 5);
  }, [innerPage]);

  if (isInnerLoading) {
    return <progress className="progress" style={{ height: "100vh" }} />;
  }

  return (
    <>
      <Swiper
        spaceBetween={250}
        pagination={{
          dynamicBullets: true,
        }}
        effect={"cards"}
        modules={[Pagination, EffectCards]}
        onSlideChange={(e) => {
          setActiveIndex(e.activeIndex);
          if (e.activeIndex === 5) {
            setInnerPage((currPage) => currPage + 1);
            setInnerLoading(true);
          }
        }}
        style={{ width: "75vw" }}
      >
        {props.data
          .slice((innerPage - 1) * 5, innerPage * 5)
          .map((product, index) => {
            return (
              <SwiperSlide key={index}>
                <Card
                  product={product}
                  isFirstCard={index === 0}
                  active={index === activeIndex}
                  setLinkUrl={setLinkUrl}
                  linkUrl={linkUrl}
                  warningSuppliers={warningSuppliers}
                  openSuppLink={openSuppLink}
                  setShowMessageObj={setShowMessageObj}
                  showMessageObj={showMessageObj}
                />
              </SwiperSlide>
            );
          })}
        <SwiperSlide style={{ width: "70vw" }}>
          <div
            style={{
              display: "flex",
              height: "55vh",
              backgroundColor: "#e6cede",
              textAlign: "center",
              justifyContent: "center",
              verticalAlign: "center",
            }}
          >
            {!props.hasOwnProperty("homePage") ? (
              <button
                className="button is-rounded"
                style={{
                  fontSize: "5vw",
                  margin: "auto",
                  padding: "0px 5vw",
                  backgroundColor: "#8B3C7E",
                  color: "white",
                  boxShadow: "0px 0px 5px 0px black",
                }}
                onClick={() => {
                  setInnerPage(1);
                  props.setPage((page) => page + 1);
                }}
              >
                Load More
              </button>
            ) : (
              <div style={{ margin: "auto", fontSize: "3vh" }}>
                {" "}
                For more, sign up to Nepeto and try our free trial!
                <br />
                <br />
                <br />
                <SignUpFreeTrialButton />
              </div>
            )}
          </div>
        </SwiperSlide>
        {!props.hasOwnProperty("homePage") && (
          <>
            <br />
            <button
              className="button is-danger"
              onClick={() => {
                let prod = props.data[(innerPage - 1) * 5 + activeIndex];
                setHideModalProduct(prod);
              }}
            >
              Hide
            </button>
            <img
              className="graphicon"
              alt="graph"
              onClick={() =>
                setAdvancedDataProduct(
                  props.data[(innerPage - 1) * 5 + activeIndex]
                )
              }
              src={"/assets/graphtransparent.png"}
              style={{
                width: "66px",
                height: "40px",
                borderRadius: "10px",
                border: "2px solid gray",
                padding: "5px 18px",
                float: "right",
                backgroundColor: advancedDataProduct !== null && "lightgreen",
              }}
            />
          </>
        )}
      </Swiper>
      {hideModalProduct !== "" && (
        <ModalForHide
          isMobile={true}
          setHideModalProduct={setHideModalProduct}
          hideModalProduct={hideModalProduct}
          hideCredits={props.hideCredits}
        />
      )}
      {linkUrl !== "" && (
        <NotDirectLinkPopup
          isMobile={true}
          setLinkUrl={setLinkUrl}
          linkUrl={linkUrl}
        />
      )}
      {advancedDataProduct !== null && (
        <AdvancedDataModal
          advancedDataProduct={advancedDataProduct}
          setAdvancedDataProduct={setAdvancedDataProduct}
          isMobile={true}
        />
      )}
      {showMessageObj !== null && (
        <MessageBeforeLinkPopup
          showMessageObj={showMessageObj}
          setShowMessageObj={setShowMessageObj}
          isMobile={true}
        />
      )}
    </>
  );
}

export default CardSwipper;
