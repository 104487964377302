import React, { useEffect } from "react";
import "../Pricing.css";
import PriceCard from "../components/PriceCard";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function GA(props) {
  const { isAuthenticated, isLoading } = useAuth0();
  const prices = [
    {
      name: "GA",
      price_monthly: 88,
      price_annually: 69,
      img: "/assets/enterpriseflower.png",
      checkList: [
        "Profitable products from Retail Suppliers (Walmart, Target, Kohl's, Zoro, Home Depot, etc.)",
        "Profitable products from more than 60 Unique Distributors",
        "Profitable products from Wholesale Suppliers",
        "Profitable products that are currently Out-of-Stock on Amazon",
        "Advanced Product Data",
        "1 HOUR FREE COACHING CALL WITH Gokhan ABRA (Amazon Seller & Business Management Expert) (Product analysis and the realities of the Online Arbitrage & WHOLESALE business model)",
        "20 Min Tutorial with Ty (Nepeto's Coach)",
        "100 Free Products Excel Report",
        "Limited time offer available only to exclusive Amazon sellers"
      ],
      planLevel: 1,
      plan_id_monthly: "P-3A735536H8792954LM2HOQWY",
      plan_id_monthly_no_trial: "P-2M88605087687645YM2HORDI",
      plan_id_annually: "P-9DP9121085868753TM2HOTFI",
      plan_id_annually_no_trial: "P-1L97866913221874YM2HOTRY",
    },
  ];
  const [isMonthly, setIsMonthly] = useState(true);
  const [activePlanLevel, setActivePlanLevel] = useState(0);

  useEffect(() => {
    const getActivePlan = async () => {
      if (props.activePlanLevel === "Starter Plan") {
        setActivePlanLevel(1)
      } else if (props.activePlanLevel === "Advanced Plan") {
        setActivePlanLevel(2)
      } else if (props.activePlanLevel === "Pro Plan") {
        setActivePlanLevel(3)
      } else {
        setActivePlanLevel(parseInt(props.activePlanLevel))
      }
    };
    if (!isLoading && isAuthenticated) {
      getActivePlan();
    }
  }, [props.activePlanLevel, isLoading, isAuthenticated])

  return (
    <div className="container">
      <Helmet>
        <title>Nepeto - Pricing</title>
        <meta
          name="description"
          content="Explore our pricing options and choose the plan that best suits your needs. Discover transparent and competitive pricing for our products and services. Find the perfect pricing plan for your requirements."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, pricing, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div class="columns center">
        <span
          style={{
            position: "absolute",
            textAlign: "center",
            top: "1.5vh",
            fontSize: "200%",
            color: "rgb(140, 62, 124)",
          }}
        >
          <i class="fa fa-star-o" aria-hidden="true"></i>{" "}
          <strong style={{color: "rgb(140, 62, 124)",}}>GA</strong> Custom Plan{" "}
          <i class="fa fa-star-o" aria-hidden="true"></i>
        </span>
        <div class="column center" style={{ marginTop: props.isMobile ? "7.5vh" : "5.5vh" }}>
          <span
            style={{
              fontSize: "1rem",
              color: "rgb(140, 62, 124)",
              fontWeight: "bold",
              padding: "1rem",
            }}
          >
            Monthly
          </span>{" "}
          <label class="switch">
            <input type="checkbox" onClick={() => setIsMonthly(!isMonthly)} />
            <span class="slider round"></span>
          </label>
          <span
            style={{
              fontSize: "1rem",
              color: "rgb(140, 62, 124)",
              fontWeight: "bold",
              padding: "1rem",
            }}
          >
            Annually
          </span>{" "}
        </div>
      </div>

      <div className="columns" style={{width: props.isMobile ? "100vw" : "30vw", margin: "auto"}}>
        {prices.map((item) => {
          return <PriceCard plan={item} isMonthly={isMonthly} activePlanLevel={activePlanLevel} isMobile={props.isMobile} noDiscount={true} higherCard={true}></PriceCard>;
        })}
      </div>

      <div class="columns">
        <div class="column">
          <h2
            style={{
              fontSize: "2vh",
              color: "#000000",
              textAlign: "center",
              marginBottom: "4vh",
            }}
          >
            <br />
            If you have any questions, please don't hesitate to{" "}
            <Link to="/contact">contact us</Link> or check our{" "}
            <Link to="/faq">FAQ</Link>.
            <br />
            By starting a subscription, you acknowledge and accept our{" "}
            <a href="/termsandpolicies.pdf">Terms and Conditions</a>.
          </h2>
        </div>
      </div>
    </div>
  );
}
export default GA;
