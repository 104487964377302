import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import {utils, writeFile} from 'xlsx';

function ModalForProFeatures(props) {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [manualRender, setManualRender] = useState(15);

  useEffect(() => {
    const isPro = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/is_pro", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setLoading(false)
          } else {
            alert("Only Pro & Master Plan subscribers can access the Pro Features!")
          }
        }).catch((error) => {
          alert("Error! Please try again.");
          setLoading(false)
        });
    };

    if (!isLoading && isAuthenticated && props.proModalOpen) {
      isPro()
    }
  }, [props.proModalOpen, isAuthenticated, isLoading, getAccessTokenSilently]);

  const exportToExcel = () => {
    let formedData = []
    props.data.forEach(product => {
      formedData.push({
        "1. ASIN Link": product.asin,
        "2. Supplier Link": product.url.replace("X".repeat(product.source_name.length), product.source_name),
        "3. Name": product.title,
        "4. Category": product.category,
        "5. BSR": product.sales_rank,
        "6. AZ Offers": props.oos ? "0" : JSON.parse(product.az_offers).length,
        "7. Supplier Price": product.price,
        "8. AZ Price": product?.az_price || "N/A",
        "9. FBA Cost": product?.fba_cost || "N/A",
        "10. AZ Referral Fee": product?.selling_on_az_fees || "N/A",
        "11. ROI": product?.roi * 100 || "N/A",
        "12. MOQ": product?.moq || "N/A",
        "13. Stock": product?.qty || "N/A"
      })
    });
    const ws = utils.json_to_sheet(formedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, 'Nepeto.csv');
  };

  return (!loading &&
    <div
      id="modal-js-example"
      className={"modal" + (props.proModalOpen ? " is-active" : "")}
    >
      <div className="modal-background" onClick={() => {
              props.setProModalOpen(false);
            }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "24vw" }}
      >
        <div className="box">
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            Pro Features
          </p>
          <br />
          <button
            className={"button is-rounded" + (!localStorage.getItem("clickedActions") ? " hidebtnglow" : "")}
            style={{
              backgroundColor: "#8B3C7E",
              color: "white",
              fontWeight: "700",
              border:"#8B3C7E",
              fontSize: props.isMobile ? "2.2vh" : "1vw",
            }}
            onClick={() => {
              exportToExcel()
            }}
          >
            Export Page To CSV&nbsp;<i class="fa fa-download" style={{paddingTop: "5px"}} aria-hidden="true"></i>
          </button>
          <br/><br/>
          <label class="checkbox" style={{ fontSize: "150%" }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.5)" }}
              onChange={(e) =>
                props.setFilters((filters) => ({
                  ...filters,
                  favoritesOnly: +e.target.checked,
                }))
              }
              checked={props.filters.favoritesOnly || false}
            />
            &nbsp;&thinsp;Show only Favorites
          </label>
          <br />
          <br />
          {!props.oos && <button
            className="button is-rounded lightbutton"
            onClick={() => {props.setModalSetting("profit"); props.setProModalOpen(false);}}
            style={{
              width: "57%",
              border:
                props.filters &&
                props.filters.hasOwnProperty("profit") &&
                "3px solid rgb(72,199,142)",
            }}
            >
            Filter By Profit
          </button>}
          <br/><br/>
          {!props.oos && <button
            className={"button is-rounded" + (!localStorage.getItem("clickedActions") ? " hidebtnglow" : "")}
            style={{
              backgroundColor: "#8B3C7E",
              color: "white",
              fontWeight: "700",
              border:
                props.filters &&
                JSON.stringify(props.filters).includes("found_time") &&
                "3px solid rgb(72,199,142)",
              fontSize: props.isMobile ? "2.2vh" : "1vw",
            }}
            onClick={() => {
              props.setFilters((filters) => ({
                ...filters,
                sortBy: ["found_time", true],
              }));
              props.setProModalOpen(false);
            }}
          >
            Sort by 'Found time' - Newest First
          </button>}
          {!props.oos && <br />}
          {!props.oos && <br />}
          {!props.oos && 
          <div style={{border: "1px solid gray", paddingTop: "15px", paddingBottom: "15px"}}>
            <div style={{display: "flex", margin: 'auto', justifyContent: "center"}}>
              <button
                className="button is-rounded lightbutton is-success"
                onClick={() => {localStorage.setItem('saveFilters', JSON.stringify(props.filters)); setManualRender(mr => mr+1)}}
                style={{
                  width: "30%",
                  marginRight: "10px"
                }}
                >
                Save Filters
              </button>
              <button
                className="button is-rounded lightbutton"
                onClick={() => {localStorage.getItem('saveFilters') !== null && props.setFilters(JSON.parse(localStorage.getItem('saveFilters'))); props.setProModalOpen(false);}}
                style={{
                  width: "30%",
                }}
                >
                Load Filters
              </button>
          </div>
          <p style={{width:"60%", overflowY: "scroll", backgroundColor: "rgba(0,0,0,0.2)", minHeight: manualRender + "px", maxHeight: "10vh", textAlign: "center", margin: "auto", marginTop: "5px"}}>
            {(localStorage.getItem('saveFilters') !== null ? 
            localStorage.getItem('saveFilters') : "").replace("{", "").replace("}", "").replaceAll('"', '').replaceAll(',', ', ').replace("search:,", "").replace("moq:[-1, -1],", "").toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).replace(":" , ": ")).join(' ').replaceAll("-1", "N/A")}</p>
          </div>}
          {!props.oos && <br />}
          {!props.oos && <br />}
          {!props.oos && <br />}
          <button
            className="button is-rounded is-danger"
            style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
            onClick={() => {
              props.setProModalOpen(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalForProFeatures;
