import "../../Products/Components/Card.css";

function A2ACard(props) {
  const cardStyle = {
    backgroundColor: "#e6cede",
    borderRadius: "20px",
    color: "#000",
    border: "1px solid rgba(0,0,0,0.2)",
  };

  const spanStyle = {
    backgroundColor: "#8c3e7c",
    color: "#e6cede",
    width: "100%",
    maxWidth: "25vw",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whitSpace: "nowrap",
    padding: "0% 1%",
    fontSize: "4vw",
  };

  return (
    <div
      className="card-content has-text-centered has-text-weight-bold"
      style={cardStyle}
    >
      <div className="columns" style={{ height: "120px" }}>
        <div className="column is-flex is-flex-direction-column">
          <p
            className="is-align-items-flex-start content is-size-8"
            style={{ whiteSpace: "nowrap" }}
          >
            {props.product.title.slice(0, 30)}...
            <br />
            <a
              href={"https://www.amazon.com/dp/" + props.product.asin + "?th=1&psc=1"}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "underline",
                fontWeight: "900",
                color: "black",
              }}
            >
              {
                props.product.asin.split("/")[
                  props.product.asin.split("/").length - 1
                ]
              }
            </a>
          </p>
        </div>

        <div className="columns is-mobile mt-1">
          <img
            src={props.product.walmart_img}
            alt="product"
            style={{ width: "27.5vw", height: "27.5vw", marginLeft: "5vw" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/assets/noimage.png";
            }}
          />
          <img
            src={(props.product.az_img !== null && props.product.az_img !== "") ? 
                  props.product.az_img : 
                  `https://images.amazon.com/images/P/${props.product.asin.split("/")[props.product.asin.split("/").length - 1]}.jpg`}
            alt="product"
            style={{ width: "27.5vw", height: "27.5vw", marginLeft: "10vw" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/assets/noimage.png";
            }}
          />
        </div>
        </div>
      <div
        className="columns is-mobile is-variable is-6"
        style={{ height: "10vh", marginTop: "15vw", marginBottom: "0" }}
      >
        <div className="column">
          <span
            className="tag is-rounded has-text-weight-normal"
            style={spanStyle}
            onClick={() => {window.open(props.product.walmart_url)}}
          >
            &ensp;{"Walmart"}&ensp;
          </span>
          <p className="content is-large">${props.product.walmart_price.toFixed(2)}</p>
        </div>
        <div className="column">
          <span
            className="tag is-rounded has-text-weight-normal"
            style={spanStyle}
            onClick={() => {
              window.open("https://www.amazon.com/dp/" + props.product.asin + "?th=1&psc=1");
            }}
          >
            &ensp;Amazon&ensp;
          </span>
          <p className="content is-large">${props.product.az_price.toFixed(2)}</p>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(200,0,0,0.1)",
          border: "1px solid purple",
          borderRadius: "20px",
          marginTop: "-2vw",
          marginBottom: "5vw",
        }}
      >
        +${(props.product.walmart_price-props.product.az_price).toFixed(2)} | #{props.product.sales_rank}
      </div>
      <img
        alt="graph"
        style={{
          height: "17vh",
          marginTop: "1vw",
          marginBottom: "1.5vh",
        }}
        onClick={() =>
          window.open(
            `https://graph.keepa.com/?asin=${props.product.asin}&domain=com&salesrank=1&amazon=1&new=1&bb=1&fba=1&range=60`,
            "_blank"
          )
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/assets/noimage.png";
        }}
        src={`https://graph.keepa.com/?asin=${props.product.asin}&domain=com&salesrank=1&amazon=1&new=1&bb=1&fba=1&range=60`}
      />

      <strong
        className="button is-rounded is-small"
        onClick={() => {window.open(props.product.walmart_url);}}
        style={{
          backgroundColor: "#8c3e7c",
          color: "white",
          fontSize: "4vw",
          padding: "0px 12vw",
        }}
      >
        LINK
      </strong>
    </div>
  );
}

export default A2ACard;
