import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis,
  Brush,
} from "recharts";

export default function HistoryGraph(props) {
  return (
    <ResponsiveContainer width="90%" height={props.height ? props.height : "24vh"} aspect={2} style={{margin: "auto"}}>
      <LineChart
        margin={0}
        data={props.history}
        style={{backgroundColor: props.addBG && "white"}}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" fontSize={props.fontSize ? props.fontSize : "45%"}/>
        {props.domain && <YAxis type="number" domain={props.domain}/>}
        <Tooltip />
        <Legend />
        <Brush dataKey="date" height={26} stroke={props.color} travellerWidth={8} startIndex={props.history ? parseInt(props.history.length > 2 ? props.history.length / 2 : 0) : 0}/>
        <Line
          type="monotone"
          dataKey="value"
          name={props.valueName}
          stroke={props.color}
          activeDot={{ r: 8 }}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}