import { useAuth0 } from "@auth0/auth0-react";
import HistoryGraph from "./HistoryGraph";
import { useEffect, useState } from "react";
import DynamicKeepaGraphPopup from "../../../components/DynamicKeepaGraphPopup";
import PieGraph from "./PieGraph";
import ModalForVariationsTable from "./ModalForVariationsTable";
import ModalForReviewsGraph from "./ModalForReviewsGraph";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from 'xlsx';

function AdvancedData(props) {
  const navigate = useNavigate();
  const [scroolToElement, setScrollToElement] = useState(null)
  const [data, setData] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [modalForVariations, setModalForVariations] = useState(false);
  const [modalForReviewsGraph, setModalForReviewsGraph] = useState(false);
  const [modalForKeepaSales, setModalForKeepaSales] = useState(false);
  const [DKGasin, setDKGAsin] = useState(null);
  const [loadPro, setLoadPro] = useState(0);
  const [bbWinnerDays, setBbWinnerDays] = useState("90");
  const [triggerUnits, setTriggerUnits] = useState(0);
  const [inboundShipping, setInboundShipping] = useState(localStorage.getItem("inboundShipping") === null ? 0 : parseFloat(localStorage.getItem("inboundShipping")));
  const [chosenOfferGraph, setChosenOfferGraph] = useState("");
  const [bsr_state, setBsr] = useState(
    localStorage.getItem("DKGbsr") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbsr"))
  );
  const [az_state, setAz] = useState(
    localStorage.getItem("DKGaz") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGaz"))
  );
  const [new_state, setNew] = useState(
    localStorage.getItem("DKGnew") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGnew"))
  );
  const [bb_state, setBb] = useState(
    localStorage.getItem("DKGbb") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbb"))
  );
  const [fba_state, setFba] = useState(
    localStorage.getItem("DKGfba") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGfba"))
  );
  const [range_state, setRange] = useState(
    localStorage.getItem("DKGrange") === null
      ? 90
      : localStorage.getItem("DKGrange")
  );

  function getOfferLiveStock(offer_graphs, seller) {
    try {
      return ("Stock: " + offer_graphs[seller][offer_graphs[seller].length - 1]["value"])
    } catch {
      return ""
    }
  }

  function findDaysWithHugeDrop(data, thresholdPercentage) {
    try {
      const daysWithHugeDrop = [];

      for (let i = 1; i < data.length; i++) {
        const currentDate = data[i].date;
        const currentValue = data[i].value;
        const previousValue = data[i - 1].value;

        // Check if the previous value is 7 or higher before considering a drop
        if (previousValue >= 10) {
          const percentageChange = ((currentValue - previousValue) / previousValue) * 100;

          if (percentageChange <= -thresholdPercentage) {
            daysWithHugeDrop.push({ date: currentDate, percentageChange });
          }
        }
      }

      return daysWithHugeDrop;
    } catch (error) {
      return []
    }
  }

  const exportOffersToExcel = (offers) => {
    let formedData = []
    offers.forEach(offer => {
      formedData.push({
        "1. Seller Id": offer.seller,
        "2. Seller Link": "https://www.amazon.com/sp?seller=" + offer.seller,
        "3. ASIN": props.advancedDataProduct.asin.split("/").pop(),
        "4. Price": offer.price,
        "5. FBA/FBM": offer.is_fba ? "FBA" : "FBM",
        "6. Rating": offer.seller_positive_feedback_rating + "% (" + offer.seller_feedback_count + " ratings)"
      })
    });
    const ws = utils.json_to_sheet(formedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, 'Nepeto_Offers_' + props.advancedDataProduct.asin.split("/").pop() + '.csv');
  };

  useEffect(() => {
    const getAdvancedData = async () => {
      setData(null)
      const accessToken = await getAccessTokenSilently();
      fetch(
        "https://server.nepeto.com/get_advanced_data/" +
        props.advancedDataProduct.asin.split("/")[
        props.advancedDataProduct.asin.split("/").length - 1
        ] +
        "/" +
        props.advancedDataProduct.category +
        "/" +
        props.advancedDataProduct.sales_rank +
        "/" +
        loadPro +
        "/",
        {
          mode: "cors",
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });
    };

    if (!isLoading && isAuthenticated && props.advancedDataProduct !== null && ((!loadPro && data === null) || (loadPro && data !== null && data['bb_perc_per_id'] === true)) && props.isItTheProduct) {
      getAdvancedData();
      if (scroolToElement !== null) {
        scroolToElement.scrollIntoView({
          behavior: 'instant',
          block: 'start',
          inline: 'start'
        });
      }
    }
  }, [
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    props.advancedDataProduct,
    props.isItTheProduct,
    loadPro,
    scroolToElement,
    data
  ]);

  if (props.advancedDataProduct === null || !props.isItTheProduct) {
    return <div style={{ height: "7.5vh", cursor: "pointer" }}
      onClick={(e) => { props.setProduct(); setScrollToElement(e.currentTarget.parentElement.parentElement.previousSibling); setInboundShipping(localStorage.getItem("inboundShipping") === null ? 0 : parseFloat(localStorage.getItem("inboundShipping"))); }}>
      <div className="adv-data-content-load"></div>

      <div class="adv-data-content-text">
        <h1 style={{ fontWeight: "600" }}>Click to see estimated monthly sales, historical data, graphs, and more.</h1>
      </div>
    </div>
  }

  return (
    <div
      className="adv-data-content"
    >
      {data === "No permissions" ? (
        <div className="box" style={{ textAlign: "center" }}>
          <strong style={{ fontSize: "150%" }}>Advanced Product Data</strong>
          <br />
          <br />
          <span>
            The advanced product data is available for the Advanced Plan and
            above.
            <br />
            Please upgrade your plan to access this data.
          </span>
          <br />
          <br />
          <button
            className="button is-rounded"
            style={{
              backgroundColor: "#32CD32",
              color: "#ffffff",
              fontSize: props.fontSize
            }}
            onClick={() => navigate("/pricing")}
          >
            Upgrade
          </button>
        </div>
      ) : (
        <div className="box" style={{ textAlign: "center" }}>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            <strong>Advanced Product Data</strong>
            <strong style={{ float: "right", cursor: "pointer", border: "1px solid lightgray", padding: "0px 10px" }} onClick={() => props.closeAdvancedData()}>X</strong>
            <strong style={{ float: "left", cursor: "pointer", border: "1px solid lightgray", padding: "0px 10px" }} onClick={() => props.closeAdvancedData()}>X</strong>
            <br />
            <br />
            <div class="columns">
              <div class="column is-2" style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                <table
                  class="table is-bordered"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    height: "100%",
                    fontSize: "65%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AZ Price Avg (30|90d)</td>
                      <td>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          "$" + data.az_price_avg30 + "|$" + data.az_price_avg90
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Amount of Reviews</td>
                      <td>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (<>
                          {data.count_reviews}{" "}&nbsp;
                          {data.count_reviews_graph && data.count_reviews_graph.length > 0 && <button style={{ padding: "0px 1px", cursor: "pointer", boxShadow: "0px 0px 2px 0px gray" }} onClick={() => setModalForReviewsGraph(true)}><i class="fa fa-line-chart" aria-hidden="true"></i></button>}
                          {modalForReviewsGraph && <ModalForReviewsGraph title="Reviews Count" count_reviews_graph={data.count_reviews_graph} currAsin={props.advancedDataProduct.asin.split("/")[props.advancedDataProduct.asin.split("/").length - 1]} modalForReviewsGraph={modalForReviewsGraph} setModalForReviewsGraph={setModalForReviewsGraph} isMobile={props.isMobile} />}
                        </>)}
                      </td>
                    </tr>
                    <tr>
                      <td>Customers Rating</td>
                      <td>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          data.rating + "★"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>IP Issues Detected</td>
                      <td>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          findDaysWithHugeDrop(data.new_offers_graph, 50).length > 0 ? <span>IP <i class="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: "red" }}></i></span> : <span>Safe <i class="fa fa-check-circle" aria-hidden="true" style={{ color: "green" }}></i></span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td trig={triggerUnits}>Dims: LxWxH <button style={{ padding: "0", cursor: "pointer" }}
                        onClick={(e) => {
                          if (localStorage.getItem("dimsUnits") === null || localStorage.getItem("dimsUnits") === "mm") {
                            localStorage.setItem("dimsUnits", 'in');
                            e.target.innerHTML = 'in';
                            setTriggerUnits(old => old + 1)
                          } else {
                            localStorage.setItem("dimsUnits", 'mm');
                            e.target.innerHTML = 'mm';
                            setTriggerUnits(old => old + 1)
                          }
                        }}>{localStorage.getItem("dimsUnits") ? localStorage.getItem("dimsUnits") : "mm"}</button></td>
                      <td trig={triggerUnits}>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          data.dims_string.split('x').map(dim => ((parseFloat(dim) * (localStorage.getItem("dimsUnits") === 'in' ? 0.0393 : 1)).toFixed(localStorage.getItem("dimsUnits") === 'in' ? 2 : 0)).toString()).join('x')
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td trig={triggerUnits}>Weight <button style={{ padding: "0", cursor: "pointer" }}
                        onClick={(e) => {
                          if (localStorage.getItem("weightUnits") === null || localStorage.getItem("weightUnits") === "grams") {
                            localStorage.setItem("weightUnits", 'lbs');
                            e.target.innerHTML = 'lbs';
                            setTriggerUnits(old => old + 1)
                          } else {
                            localStorage.setItem("weightUnits", 'grams');
                            e.target.innerHTML = 'grams';
                            setTriggerUnits(old => old + 1)
                          }
                        }}>{localStorage.getItem("weightUnits") ? localStorage.getItem("weightUnits") : "grams"}</button></td>
                      <td trig={triggerUnits}>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          (data.weight * (localStorage.getItem("weightUnits") === 'lbs' ? 0.0022 : 1)).toFixed(localStorage.getItem("weightUnits") === 'lbs' ? 2 : 0)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td trig={triggerUnits}>Inbound Shipping
                        <br />$<input style={{ padding: "0", width: '35%', }} min={1} step={0.1}
                          onChange={(e) => {
                            localStorage.setItem("inboundShipping", e.target.value ? e.target.value : 0);
                            setInboundShipping(e.target.value);
                          }} value={inboundShipping} type="number" /><button style={{ padding: "0", cursor: "pointer" }}
                            onClick={(e) => {
                              if (localStorage.getItem("weightUnits") === null || localStorage.getItem("weightUnits") === "grams") {
                                localStorage.setItem("weightUnits", 'lbs');
                                e.target.innerHTML = '/1 lbs';
                                setTriggerUnits(old => old + 1)
                              } else {
                                localStorage.setItem("weightUnits", 'grams');
                                e.target.innerHTML = '/1 grams';
                                setTriggerUnits(old => old + 1)
                              }
                            }}>/1 {localStorage.getItem("weightUnits") ? localStorage.getItem("weightUnits") : "grams"}</button></td>
                      <td trig={triggerUnits}>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          "$" + ((data.weight * (localStorage.getItem("weightUnits") === 'lbs' ? 0.0022 : 1)) * (inboundShipping ? inboundShipping : 0)).toFixed(2)
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Sales Rank Drops (30|90d)</td>
                      <td>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          data.sales_rank_drops_30_days + "|" + data.sales_rank_drops_90_days
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Amount of Variations</td>
                      <td>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : data.variations && data.variations !== "null" ? (<>
                          {JSON.parse(data.variations).length}{"  "}&nbsp;
                          <button style={{ padding: "0px 1px", cursor: "pointer", boxShadow: "0px 0px 2px 0px gray" }} onClick={() => setModalForVariations(true)}><i class="fa fa-table" aria-hidden="true"></i></button>
                          {modalForVariations && <ModalForVariationsTable variations={data.variations} currAsin={props.advancedDataProduct.asin.split("/")[props.advancedDataProduct.asin.split("/").length - 1]} modalForVariations={modalForVariations} setModalForVariations={setModalForVariations} isMobile={props.isMobile} />}
                        </>
                        ) : (
                          0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Amazon Sells It?</td>
                      <td style={{ color: "black" }}>
                        {data === null ? (
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : data.az_sells_it ? (
                          "Yes"
                        ) : (
                          "No"
                        )}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: (data !== null && data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) && "lightgreen" }}>
                      <td>
                        <strong>{(data !== null && data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) ? "" : "Estimated"} Revenue
                          {(data !== null && data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) && <i class="fa fa-check-circle tooltip" aria-hidden="true" style={{ color: "darkgreen", margin: "5px -5px", position: "absolute" }}><span className="tooltiptext" style={{ fontFamily: "sans-serif" }}>Verified by Amazon - Variation Estimation</span></i>}
                        </strong>
                      </td>{" "}
                      <td>
                        <strong>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (<>
                            ${((data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) ?
                              (data.keepa_monthly_sold * ((data.az_price_avg30 + data.az_price_avg90) / 2)).toFixed(1) + "+" :
                              (data.estimate_sales_30_days * ((data.az_price_avg30 + data.az_price_avg90) / 2)).toFixed(1))}
                          </>)}
                        </strong>
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: (data !== null && data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) && "lightgreen" }}>
                      <td>
                        <strong>{(data !== null && data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) ? "" : "Estimated"} Monthly Sales
                          {(data !== null && data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) && <i class="fa fa-check-circle tooltip" aria-hidden="true" style={{ color: "darkgreen", margin: "5px -5px", position: "absolute" }}><span className="tooltiptext" style={{ fontFamily: "sans-serif" }}>Verified by Amazon - Variation Estimation</span></i>}
                        </strong>
                      </td>{" "}
                      <td>
                        <strong>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (<>
                            {(data.keepa_monthly_sold && data.keepa_monthly_sold !== -1) ? data.keepa_monthly_sold + "+" : data.estimate_sales_30_days}{" "}&nbsp;
                            {data.keepa_monthly_sold && data.keepa_monthly_sold !== -1 && data.keepa_monthly_sold_history && data.keepa_monthly_sold_history.length > 0 && <button style={{ padding: "0px 1px", cursor: "pointer", boxShadow: "0px 0px 2px 0px gray" }} onClick={() => setModalForKeepaSales(true)}><i class="fa fa-line-chart" aria-hidden="true"></i></button>}
                            {modalForKeepaSales && <ModalForReviewsGraph title="Monthly Sales" count_reviews_graph={data.keepa_monthly_sold_history} currAsin={props.advancedDataProduct.asin.split("/")[props.advancedDataProduct.asin.split("/").length - 1]} modalForReviewsGraph={modalForKeepaSales} setModalForReviewsGraph={setModalForKeepaSales} isMobile={props.isMobile} />}
                          </>)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="column is-4" style={{ zIndex: 1, backgroundColor: "white", borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                {props.isMobile && <br />}
                {data === null ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <>
                    <img
                      loading="lazy"
                      onClick={() =>
                        setDKGAsin(
                          props.advancedDataProduct.asin.split("/")[
                          props.advancedDataProduct.asin.split("/").length -
                          1
                          ]
                        )
                      }
                      src={
                        DKGasin === null
                          ? `https://graph.keepa.com/?asin=${props.advancedDataProduct.asin.split("/")[
                          props.advancedDataProduct.asin.split("/")
                            .length - 1
                          ]
                          }&domain=com&salesrank=${+bsr_state}&amazon=${+az_state}&new=${+new_state}&bb=${+bb_state}&fba=${+fba_state}&range=${range_state}`
                          : "/assets/dkg_loading.png"
                      }
                      alt="keepahist"
                      style={{
                        position: "relative",
                        border: "1px solid gray",
                      }}
                      className="keepagraphoos hoverscaleadv"
                    />
                  </>
                )}
                <hr style={{ marginTop: "0" }} />
                {data === null ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <HistoryGraph
                    valueName="Offers Count"
                    history={data.new_offers_graph}
                    color="darkblue"
                  />
                )}
              </div>
              <div class="column is-3" style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                BB Winner %{data !== null && data['bb_perc_per_id'] === false && <span> for <strong style={{ cursor: "pointer" }} onClick={() => { window.location.href = "/pricing" }}>Pro Subscribers</strong></span>}
                {data !== null && <>
                  &nbsp;<button disabled={bbWinnerDays === "30"} onClick={() => setBbWinnerDays("30")} style={{ cursor: "pointer", fontSize: "60%" }}>30d</button>&nbsp;
                  <button disabled={bbWinnerDays === "90"} onClick={() => setBbWinnerDays("90")} style={{ cursor: "pointer", fontSize: "60%" }}>90d</button>&nbsp;
                  <button disabled={bbWinnerDays === "180"} onClick={() => setBbWinnerDays("180")} style={{ cursor: "pointer", fontSize: "60%" }}>180d</button>&nbsp;
                  <button disabled={bbWinnerDays === "360"} onClick={() => setBbWinnerDays("360")} style={{ cursor: "pointer", fontSize: "60%" }}>360d</button></>}
                {data === null ? (
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                  ></i>
                ) : (data['bb_perc_per_id'] === false || data['bb_perc_per_id'] === true ? <><br /><img alt="hint" style={{ cursor: "pointer" }} onClick={() => { if (data['bb_perc_per_id'] === false) { window.location.href = "/pricing" } else { setLoadPro(1) } }} class={data['bb_perc_per_id'] === false ? "blurImg nohoverscaleadv" : "nohoverscaleadv"} src={"/assets/" + (data['bb_perc_per_id'] === true ? "load_" : "") + "bbwinner_hint.png"} /></> : (props.isMobile ?
                  <table
                    class="table is-bordered"
                    style={{
                      width: "100%",
                      textAlign: "left",
                      height: "100%",
                      fontSize: "65%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>SellerID</th>
                        <th>BB Winner %</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data['bb_perc_per_id'][bbWinnerDays].map((bb_id, index) => {
                        return (<tr key={index}>
                          <td><a target="_blank" rel="noreferrer" href={"https://www.amazon.com/sp?seller=" + bb_id['name']} alt="seller">{bb_id['name'].replace("ATVPDKIKX0DER", "Amazon").replace("A2R2RITDJNW1Q6", "Amazon")}</a></td>
                          <td>{bb_id['value'].toFixed(2)}%</td>
                        </tr>)
                      }
                      )
                      }
                    </tbody>
                  </table> :
                  <PieGraph data={data['bb_perc_per_id'][bbWinnerDays]} />))}
                <hr style={{ marginTop: "0" }} />
                Stock History for &nbsp;{data !== null && data['offer_graphs'] === false && <strong style={{ cursor: "pointer" }} onClick={() => { window.location.href = "/pricing" }}>Pro Subscribers</strong>}
                {data === null ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (data['offer_graphs'] !== false && data['offer_graphs'] !== true && <select name="offerSellerIdShown" onChange={(e) => setChosenOfferGraph(e.target.value)}>
                  {Object.keys(data['offer_graphs']).map((offerSellerId, index) => {
                    return (<option key={index} value={offerSellerId}>{offerSellerId.replace("ATVPDKIKX0DER", "Amazon").replace("A2R2RITDJNW1Q6", "Amazon")}</option>)
                  })}
                </select>)}
                {data === null ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (data['offer_graphs'] === false || data['offer_graphs'] === true ? <><br /><img alt="hint" style={{ cursor: "pointer" }} onClick={() => { if (data['offer_graphs'] === false) { window.location.href = "/pricing" } else { setLoadPro(1) } }} class={data['offer_graphs'] === false ? "blurImg nohoverscaleadv" : "nohoverscaleadv"} src={"/assets/" + (data['offer_graphs'] === true ? "load_" : "") + "stockhist_hint.png"} /></> :
                  <div style={{ height: "10vh" }}>
                    <HistoryGraph
                      valueName="Stock"
                      history={data['offer_graphs'][chosenOfferGraph !== "" ? chosenOfferGraph : Object.keys(data['offer_graphs'])[0]]}
                      color="purple"
                    />
                  </div>
                )}
              </div>
              {props.isMobile && <br />}{props.isMobile && <br />}
              <div class="column is-3" style={{ zIndex: DKGasin === null ? 1 : 0, backgroundColor: "white", maxHeight: "60vh", overflow: "scroll" }}>
                Current Offers
                <button
                  style={{
                    fontSize: "70%",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    exportOffersToExcel(JSON.parse(props.advancedDataProduct['az_offers']))
                  }}
                >
                  <i class="fa fa-download" style={{ paddingTop: "5px" }} aria-hidden="true"></i>
                </button>
                <table
                  class="table is-bordered"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    height: "100%",
                    fontSize: "65%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>SellerID</th>
                      <th>Price</th>
                      <th>FBA/FBM</th>
                      <th>BuyBox</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JSON.parse(props.advancedDataProduct['az_offers']).map((offer, index) => {
                      return (<tr key={index}>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <a target="_blank" rel="noreferrer" href={"https://www.amazon.com/sp?seller=" + offer['seller']} alt="seller">{offer['seller'].replace("ATVPDKIKX0DER", "Amazon").replace("A2R2RITDJNW1Q6", "Amazon")}</a>
                          <br />
                          <span>{offer['seller_feedback_count']} ratings</span>{" "}
                          <i class="fa fa-search graphicon tooltip" style={{
                            width: "1vw",
                            height: "1vw",
                            borderRadius: "50px",
                            padding: "0.1vw 0vw",
                            cursor: "pointer",
                            color: "green",
                            border: "1px solid black"
                          }} aria-hidden="true"
                            onClick={() => { if (offer['seller'] === "ATVPDKIKX0DER" || offer['seller'] === "A2R2RITDJNW1Q6") { alert("You cannot scan Amazon!"); return } window.open("/storefront-scanner?sellerId=" + offer['seller']) }}
                            id="searchTt"
                          ><span
                            class="tooltiptext"
                            style={{
                              marginTop: "-25%",
                              fontSize: ".75vw",
                              zIndex: "2000",
                              padding: "3px 6px",
                              border: "1px solid black",
                              fontFamily: "sans-serif",
                            }}
                          >
                              Storefront Scanner
                            </span></i>
                          <br />
                          {data && ((data['bb_perc_per_id'] === false || data['bb_perc_per_id'] === true) ?
                            <button
                              style={{ backgroundColor: "#8B3C7E", color: "white", border: "0", borderRadius: "7px", boxShadow: "0px 0px 3px 0px gray", cursor: "pointer" }}
                              onClick={() => { if (data['bb_perc_per_id'] === false) { window.location.href = "/pricing" } else { setLoadPro(1) } }}
                            >
                              Stock
                            </button>
                            : getOfferLiveStock(data['offer_graphs'], offer['seller'])
                          )}
                        </td>
                        <td>${offer['price'].toFixed(2)}</td>
                        <td>{offer['is_fba'] ? "FBA" : "FBM"}</td>
                        <td style={{ backgroundColor: offer['is_buy_box_winner'] && !JSON.parse(props.advancedDataProduct['az_offers']).some(otherOffer => otherOffer['is_buy_box_winner'] && otherOffer['price'] < offer['price']) && "lightgreen" }}>{offer['is_buy_box_winner'] ?
                          JSON.parse(props.advancedDataProduct['az_offers']).some(otherOffer => otherOffer['is_buy_box_winner'] && otherOffer['price'] < offer['price']) ? "NO" : "YES"
                          : "NO"}</td>
                      </tr>)
                    }
                    )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </p>
        </div>
      )}
      {DKGasin !== null && (
        <DynamicKeepaGraphPopup
          asin={DKGasin}
          setDKGAsin={setDKGAsin}
          bsr_state={bsr_state}
          setBsr={setBsr}
          bb_state={bb_state}
          setBb={setBb}
          az_state={az_state}
          setAz={setAz}
          fba_state={fba_state}
          setFba={setFba}
          new_state={new_state}
          setNew={setNew}
          range_state={range_state}
          setRange={setRange}
        />
      )}
    </div>
  );
}

export default AdvancedData;
