import { Helmet } from "react-helmet";

function Affiliate(props) {
  return (
    <div className="container">
      <Helmet>
        <title>Nepeto - Affiliate Program</title>
        <meta
          name="description"
          content="Earn 30% monthly from successful referrals. Easy sign-up and recurring income! Contact us today!"
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, affiliate, affiliate program, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>

      <div className="columns is-justify-content-center" style={{marginTop: "10vh"}}>
        <div className="column is-6">
        <h1
            style={{
              fontSize: props.isMobile ? "5vh" : "4vw",
              color: "#8c3e7c",
              marginTop: "3vh",
              textAlign: props.isMobile && "center"
            }}
          >
            Affiliate Program
          </h1>
          <h2
            style={{
              fontSize: props.isMobile ? "2.5vh" : "2vw",
              color: "#8c3e7c",
              textAlign: props.isMobile && "center"
            }}
          >
            Earn 30% monthly from successful referrals. Easy sign-up and recurring income! Contact us today!
          </h2>
        </div>
        <div className="column is-2"></div>
        <div className="column is-4  is-centered" style={{ margin: "auto" }}>
          <h1
            style={{
              fontSize: props.isMobile ? "5vh" : "3vw",
              color: "#8c3e7c",
              marginTop: props.isMobile ? "5vh" : "-5vh",
              marginBottom: props.isMobile ? "2.5vh" : "5vh",
            }}
          >
            Contact Us
          </h1>
          <form
            action="https://server.nepeto.com/email"
            method="post"
            accept-charset="UTF-8"
            className="box p-5"
            style={{ border: "1px solid rgba(0,0,0,0.12)" }}
          >
            <label className="is-block mb-4">
              <span className="is-block mb-2">Your name</span>
              <input
                name="name"
                type="text"
                className="input"
                placeholder="Nepeto Nepeto"
              />
            </label>

            <label className="is-block mb-4">
              <span className="is-block mb-2">Email address</span>
              <input
                required
                name="email"
                type="email"
                className="input"
                placeholder="support@nepeto.com"
              />
            </label>

            <label className="is-block mb-4">
              <span className="is-block mb-2">Message</span>
              <textarea
                name="message"
                className="textarea"
                rows="3"
                placeholder="Tell us what you're thinking about..."
              ></textarea>
            </label>

            <div className="mb-4">
              <button
                type="submit"
                className="button px-4"
                style={{ backgroundColor: "#8c3e7c", color: "white" }}
              >
                Submit
              </button>
              <div
                style={{
                  textIndent: "-99999px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  position: "absolute",
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="_gotcha"
                  tabindex="-1"
                  autocomplete="off"
                />
                <input
                  type="text"
                  name="what_is_it"
                  value={"affiliate"}
                  tabindex="-1"
                  autocomplete="off"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Affiliate;
