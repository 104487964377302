import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function AffiliateDashboard(props) {
  const [tempAffiliateId, setTempAffiliateId] = useState("");
  const [affiliateId, setAffiliateId] = useState("");
  const [affiliates, setAffiliates] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const jimHides = 0
  const fiftyPerc = ["fullychargedent", "sidehustleexperiment", "don", "geri", "jparpheniuk", "parker", "cali", "escola", "matthew0607", "parker", "chad", "crazyflippinmom", "ty", "alldayfba", "eric", "edwin", "jashustles", "adaptechluke", "wetrioline", "kaijay", "ryanolivas", "gu3ra621", "1willfba", "misha", "yamie", "finditflipit", "tonafba", "cynthia", "joekick", "vendingfinders", "anthony"]

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Check if the 'affiliate' parameter exists in the query string
    if (urlParams.has("id")) {
      setAffiliateId(urlParams.get("id"));
    }
  }, []);

  useEffect(() => {
    const loadAffiliates = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/get_affiliates/" + affiliateId + "/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== "No permissions") {
            setAffiliates(data);
          }
        });
    };
    if (!isLoading && isAuthenticated && affiliateId !== "" && affiliateId !== "null") {
      loadAffiliates();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, affiliateId]);

  if (!isLoading && !isAuthenticated) {
    return <div className="container">
      <Helmet>
        <title>Nepeto - Affiliate Dashboard</title>
        <meta
          name="description"
          content="Earn money monthly from successful referrals. Dashboard for affiliates."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, affiliate, affiliate program, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <br /><br /><br /><br />
      <h1 style={{ height: "60vh", textAlign: "center", fontSize: "200%" }}>
        Please log in to your account to access your Affiliate Dashboard :)
      </h1>
    </div>
  }

  if (affiliateId === "" || affiliateId === "null") {
    return <div className="container">
      <Helmet>
        <title>Nepeto - Affiliate Dashboard</title>
        <meta
          name="description"
          content="Earn money monthly from successful referrals. Dashboard for affiliates."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, affiliate, affiliate program, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <br /><br /><br /><br />
      <h1 style={{ height: "60vh", textAlign: "center", fontSize: "200%" }}>
        Please enter your Affiliate-Id:<br /><br />
        <input className="input" placeholder="Affiliate ID" onChange={(e) => setTempAffiliateId(e.target.value)} style={{ width: "20%", borderColor: "gray" }} />
        <button className="button is-success" onClick={(e) => setAffiliateId(tempAffiliateId)}>GO</button>
      </h1>
    </div>
  }

  return (
    <div className="container">
      <Helmet>
        <title>Nepeto - Affiliate Dashboard</title>
        <meta
          name="description"
          content="Earn money monthly from successful referrals. Dashboard for affiliates."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, affiliate, affiliate program, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      {affiliateId !== "" && affiliateId !== "null" && affiliates !== null ? (
        <>
          <h1
            style={{ marginTop: "10vh", textAlign: "center", fontSize: "200%" }}
          >
            Hey {affiliateId}!
          </h1>
          <div
            className="columns is-justify-content-center"
            style={{ marginTop: "5vh", textAlign: "center" }}
          >
            <div class="column">
              <p style={{ fontSize: "400%" }}>
                {affiliates["Starter Plan"][0]}{" "}
                <span style={{ fontSize: "50%" }}>users</span>
              </p>
              <p style={{ fontSize: "150%" }}>
                Starter Plan
              </p>
            </div>
            <div class="column">
              <p style={{ fontSize: "400%" }}>
                {affiliates["Advanced Plan"][0]}{" "}
                <span style={{ fontSize: "50%" }}>users</span>
              </p>
              <p style={{ fontSize: "150%" }}>
                Advanced Plan
              </p>
            </div>
            <div class="column">
              <p style={{ fontSize: "400%" }}>
                {affiliates["Pro Plan"][0]}{" "}
                <span style={{ fontSize: "50%" }}>users</span>
              </p>
              <p style={{ fontSize: "150%" }}>
                Pro Plan
              </p>
            </div>
            <div class="column">
              <p style={{ fontSize: "400%" }}>
                {affiliates["Master Plan"][0]}{" "}
                <span style={{ fontSize: "50%" }}>users</span>
              </p>
              <p style={{ fontSize: "150%" }}>
                Master Plan
              </p>
            </div>
          </div>
          {(affiliateId.includes("ga_") || affiliateId === "ga") && (
            <div style={{ margin: "auto", textAlign: "center" }}>
              <p style={{ fontSize: "400%" }}>
                {affiliates["GA Plan"][0]}{" "}
                <span style={{ fontSize: "50%" }}>users</span>
              </p>
              <p style={{ fontSize: "150%" }}>
                GA Plan
                <br />
                $88/mo each
              </p>
            </div>
          )}
          {(affiliateId === "jim") && (
            <div style={{ margin: "auto", textAlign: "center" }}>
              <p style={{ fontSize: "400%" }}>
                {jimHides}{" "}
                <span style={{ fontSize: "50%" }}>hides</span>
              </p>
              <p style={{ fontSize: "150%" }}>
                $2/week
              </p>
            </div>
          )}
          <h1
            style={{
              marginTop: "15vh",
              marginBottom: "5vh",
              textAlign: "center",
              fontSize: "250%",
            }}
          >
            Total (Paid + Free Trial):<br />{" "}
            <span style={{ fontSize: "150%" }}>
              $
              {((
                (affiliates["Starter Plan"][1].reduce((acc, curr) => acc + curr[1], 0)
                  +
                  affiliates["Advanced Plan"][1].reduce((acc, curr) => acc + curr[1], 0)
                  +
                  affiliates["Pro Plan"][1].reduce((acc, curr) => acc + curr[1], 0)
                  +
                  affiliates["Master Plan"][1].reduce((acc, curr) => acc + curr[1], 0)
                  +
                  affiliates["GA Plan"][1].reduce((acc, curr) => acc + curr[1], 0))
                *
                (affiliateId.includes("_") ? 0.2 : (fiftyPerc.includes(affiliateId) ? 0.5 : 0.3))
                + (affiliateId === "jim" && (jimHides * 2 * 0.3))
              ).toFixed(2) / (affiliateId === "jim" ? 2 : 1.42857)).toFixed(2)}
            </span>
            /mo
            <br />({affiliateId.includes("_") ? "20" : (fiftyPerc.includes(affiliateId) ? "50" : "30")}% Affiliate)
            <br />
            <span style={{ fontSize: "50%" }}>
              We'll send your earnings from paid users on the 1st of each month.
            </span>
            <br />
          </h1>
          <table
            class="table is-striped"
            style={{
              margin: "auto",
              marginBottom: "5vh",
              border: "1px solid gray",
            }}
          >
            <thead>
              <tr>
                <th>Email</th>
                <th>Subscribed</th>
                <th>Plan Price</th>
                <th>Paid <span style={{ fontSize: "70%" }}>(Continued after Free Trial)</span></th>
              </tr>
            </thead>
            <tbody>
              {affiliates["Starter Plan"][1].map((emailPrice) => {
                return (
                  <tr>
                    <td>{emailPrice[0]}</td>
                    <td>Starter Plan {emailPrice[3] && " (Annually)"}</td>
                    <td style={{ textAlign: 'center' }}>${(emailPrice[1] / (affiliateId === "jim" ? 2 : 1.42857)).toFixed(2)}/mo</td>
                    <td style={{ textAlign: 'center', backgroundColor: emailPrice[2] ? 'rgba(255,165,0,0.3)' : 'lightgreen' }}>{emailPrice[2] ? "Free Trial" : "Paid"}</td>
                  </tr>
                );
              })}
              {affiliates["Advanced Plan"][1].map((emailPrice) => {
                return (
                  <tr>
                    <td>{emailPrice[0]}</td>
                    <td>Advanced Plan {emailPrice[3] && " (Annually)"}</td>
                    <td style={{ textAlign: 'center' }}>${(emailPrice[1] / (affiliateId === "jim" ? 2 : 1.42857)).toFixed(2)}/mo</td>
                    <td style={{ textAlign: 'center', backgroundColor: emailPrice[2] ? 'rgba(255,165,0,0.3)' : 'lightgreen' }}>{emailPrice[2] ? "Free Trial" : "Paid"}</td>
                  </tr>
                );
              })}
              {affiliates["Pro Plan"][1].map((emailPrice) => {
                return (
                  <tr>
                    <td>{emailPrice[0]}</td>
                    <td>Pro Plan {emailPrice[3] && " (Annually)"}</td>
                    <td style={{ textAlign: 'center' }}>${(emailPrice[1] / (affiliateId === "jim" ? 2 : 1.42857)).toFixed(2)}/mo</td>
                    <td style={{ textAlign: 'center', backgroundColor: emailPrice[2] ? 'rgba(255,165,0,0.3)' : 'lightgreen' }}>{emailPrice[2] ? "Free Trial" : "Paid"}</td>
                  </tr>
                );
              })}
              {affiliates["Master Plan"][1].map((emailPrice) => {
                return (
                  <tr>
                    <td>{emailPrice[0]}</td>
                    <td>Master Plan {emailPrice[3] && " (Annually)"}</td>
                    <td style={{ textAlign: 'center' }}>${(emailPrice[1] / (affiliateId === "jim" ? 2 : 1.42857)).toFixed(2)}/mo</td>
                    <td style={{ textAlign: 'center', backgroundColor: emailPrice[2] ? 'rgba(255,165,0,0.3)' : 'lightgreen' }}>{emailPrice[2] ? "Free Trial" : "Paid"}</td>
                  </tr>
                );
              })}
              {affiliates["GA Plan"][1].map((emailPrice) => {
                return (
                  <tr>
                    <td>{emailPrice[0]}</td>
                    <td>GA Plan</td>
                    <td style={{ textAlign: 'center' }}>${(emailPrice[1] / (affiliateId === "jim" ? 2 : 1.42857)).toFixed(2)}</td>
                    <td style={{ textAlign: 'center', backgroundColor: emailPrice[2] ? 'rgba(255,165,0,0.3)' : 'lightgreen' }}>{emailPrice[2] ? "Free Trial" : "Paid"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <br />
          {affiliateId === "jim" && <table
            class="table is-striped"
            style={{
              margin: "auto",
              marginBottom: "5vh",
              border: "1px solid gray",
            }}
          >
            <thead>
              <tr>
                <th>Email</th>
                <th>Hide Weeks</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              </tr>
            </tbody>
          </table>}
          <br />
        </>
      ) : (
        <div style={{ height: "88vh", textAlign: "center" }}>
          <i
            className="fa fa-spinner fa-spin"
            aria-hidden="true"
            style={{ marginTop: "20vh", fontSize: "250%" }}
          ></i>
        </div>
      )}
    </div>
  );
}

export default AffiliateDashboard;
