import { Helmet } from "react-helmet";

function AppExtLandingPage(props) {
  window.location.href="#landingAppExt"
  return (
    <div id="landingAppExt" style={{ backgroundColor: "#EAE0E8" }}>
      <Helmet>
        <title>Nepeto - AppExtLandingPage</title>
        <meta
          name="description"
          content="Earn 30% monthly from successful referrals. Easy sign-up and recurring income! Contact us today!"
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, affiliate, affiliate program, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>

      <img src={props.isMobile? "/assets/appextlandmobile.png" : "/assets/appextland.jpg"} style={{ width: "80vw", marginLeft: "10vw" }} onClick={() => window.scrollTo(0, 500)} />
      <i class="fa fa-arrow-down upDown" aria-hidden="true" style={{ position: 'absolute', fontSize: "4vw", top: "90vh", marginLeft: "1vw", color: "#8B3C7E" }}></i>
      <i class="fa fa-arrow-down upDown" aria-hidden="true" style={{ position: 'absolute', fontSize: "4vw", top: "90vh", left: "10vw", color: "#8B3C7E" }}></i>

      <h1 style={{ fontSize: "200%", textAlign: "center", marginTop: "3vh", color: "#8B3C7E", fontWeight: "600" }}>Fast and Easy Download!</h1>
      <div className="columns is-justify-content-center" style={{ marginTop: "1vh" }}>
        <div className="column" style={{ fontSize: "150%", textAlign: "center", marginTop: "3vh", color: "#8B3C7E", fontWeight: "600" }}>
          Download our RA Mobile app today!
          <br /><br />
          <img src="/assets/appStoreS.png" style={{ cursor: "pointer", marginLeft: props.isMobile && "5.5%" }} onClick={() => window.open("https://apps.apple.com/us/app/nepeto/id6511243439")} />&emsp;
          <img src="/assets/googlePlayS.png" style={{ cursor: "pointer" }} onClick={() => window.open("https://nepeto.app")} />
        </div>
        <div className="column" style={{ fontSize: "150%", textAlign: "center", marginTop: "3vh", color: "#8B3C7E", fontWeight: "600" }}>
          Download our OA Chrome extension today!
          <br /><br />
          <img src="/assets/chromeExtS.png" style={{ boxShadow: "0px 0px 5px 0px gray", cursor: "pointer" }} onClick={() => window.open("https://chromewebstore.google.com/detail/nepeto/ehjjfmgnkkkhaeeocejeimgjgepajkeh")} />
        </div>
      </div>
      <br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div>
  );
}

export default AppExtLandingPage;
