import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useEffect } from "react";

function UserActivityDashboard(props) {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [timeSpents, setTimeSpents] = useState({});
  const [loadingTimes, setLoadingTimes] = useState(true);
  const [currIP, setCurrIP] = useState("");

  const removeIP = async (e, ipA) => {
    e.target.style.cursor = "wait";
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/remove_time_spent_per_ip/" + ipA + "/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then((response) => response.json())
      .then((data) => {
        e.target.style.cursor = "pointer";
        const updatedTimeSpents = { ...timeSpents };
        delete updatedTimeSpents[ipA];
        setTimeSpents(updatedTimeSpents);
      }).catch(error => alert("Error! Please refresh and try again!"))
  };

  useEffect(() => {
    const loadTimeSpents = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/get_time_spents/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setTimeSpents(data);
          setLoadingTimes(false);
        });
    };
    if (!isLoading && isAuthenticated) {
      loadTimeSpents();
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setCurrIP(data.ip))
        .catch(error => console.log(error))
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);

  if (!isLoading && !isAuthenticated) {
    return (
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <h1 style={{ height: "60vh", textAlign: "center", fontSize: "200%" }}>
          Please log in to your account to access your User Activity Dashboard
          :)
        </h1>
      </div>
    );
  }

  return !loadingTimes ? (
    <div className="container">
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "2vh",
          marginTop: "3vh"
        }}
      >User Activity Dashboard</h1>
      <table
        class="table is-striped is-bordered"
        style={{
          margin: "auto",
          marginBottom: "55vh",
          border: "1px solid gray",
          minWidth: "50vw",
          marginTop: "5vh",
          textAlign: "center",
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>
              IPv4 Address / Nickname
            </th>
            <th style={{ textAlign: "center" }}>
              Time Spent on Nepeto
            </th>
            <th style={{ textAlign: "center" }}>
              Remove
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(timeSpents).map((key) => {
            return (
              <tr>
                <td style={{ backgroundColor: currIP === key && "lightgreen" }}><input onChange={(e) => localStorage.setItem("UAD" + key, e.target.value)} placeholder={localStorage.getItem("UAD" + key) ?? (currIP === key ? "Current User" : key)}/></td>
                <td style={{ backgroundColor: currIP === key && "lightgreen" }}>
                  <strong>{Math.floor(parseFloat(timeSpents[key]) / 3600) < 10 && "0"}
                    {Math.floor(parseFloat(timeSpents[key]) / 3600)}</strong> Hours &emsp;
                  <strong>{Math.floor((parseFloat(timeSpents[key]) % 3600) / 60) < 10 &&
                    "0"}
                    {Math.floor((parseFloat(timeSpents[key]) % 3600) / 60)}</strong> Minutes &emsp;
                  <strong>{parseFloat(timeSpents[key]) % 60 < 10 && "0"}
                    {(parseFloat(timeSpents[key]) % 60).toFixed(2)}</strong> Seconds
                </td>
                <td style={{ backgroundColor: currIP === key && "lightgreen" }}><i class="fa fa-trash" style={{
                  borderRadius: "20px",
                  border: "2px solid gray",
                  height: "calc(1.2vh + .75vw)",
                  width: "calc(1.2vh + .75vw)",
                  padding: "4px",
                  fontSize: "calc((1.2vh + .75vw) / 1.75)",
                  cursor: "pointer",
                }} aria-hidden="true"
                  onClick={(e) => removeIP(e, key)}></i></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <div style={{ height: "88vh", textAlign: "center" }}>
      <i
        className="fa fa-spinner fa-spin"
        aria-hidden="true"
        style={{ marginTop: "20vh", fontSize: "250%" }}
      ></i>
    </div>
  );
}

export default UserActivityDashboard;
