import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import "bulma/css/bulma.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="login.nepeto.com"
    clientId="EZjkduNOAi8bj5wIDpTaZVrEaQIdHagH"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "auth0.api.nepeto",
      // scope="auth0.api.nepeto" ?
    }}
  >
    <App />
  </Auth0Provider>
);

reportWebVitals(sendToVercelAnalytics);
