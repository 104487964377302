// LogoScroller.js
import React from 'react';
import { retailLogos } from '../../Products/Components/RetailSuppliersLogos';

const LogoScroller = () => {
    // Map logos to their respective image paths
    const logoPaths = Object.values(retailLogos).map(logo => `/assets/logos/${logo}.png`);

    return (
        <div className="logo-scrollp-scroller">
            <div className="logo-scrollp-container">
                {/* Render each logo with an alt attribute for accessibility */}
                {logoPaths.map((logo, index) => (
                    <img 
                        key={logo} 
                        src={logo} 
                        alt={`Logo ${index + 1}`} // Use an index for the alt text
                        className="logo-scrollp" 
                    />
                ))}
                {/* Duplicate logos for infinite scrolling effect */}
                {logoPaths.map((logo, index) => (
                    <img 
                        key={`dup-${logo}`} 
                        src={logo} 
                        alt={`Logo ${index + 1}`} 
                        className="logo-scrollp" 
                    />
                ))}
            </div>
        </div>
    );
};

export default LogoScroller;
