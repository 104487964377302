import { useNavigate } from "react-router-dom";

function BlogCardPreview(props) {
  const navigate = useNavigate();

  return (
    <div class="card blogCard" onClick={() => navigate(props.href)}>
      <div class="card-image">
        <figure class="image is-4by3">
          <img
            src={props.img}
            alt="Blog Preview"
          />
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4" style={{textAlign: "center", minHeight: "10vh"}}>{props.title}</p>
          </div>
        </div>
        <footer class="card-footer">
          <span style={{fontSize: "70%"}}>~ {props.writer}</span>
          <span style={{fontSize: "70%", marginLeft: "auto"}}>{props.minRead} min read</span>
        </footer>
      </div>
    </div>
  );
}

export default BlogCardPreview;
