import Checkout from "../../../components/Checkout";

function ModalForBuyHCredits(props) {
  return (
    <div id="modal-js-example" className="modal is-active">
      <div className="modal-background" onClick={() => props.setBuyHideCreditsModal(false)}></div>

      <div className="modal-content" style={{width: "60vw"}}>
        <div className="box" style={{ textAlign: "center" }}>
          <p style={{ fontSize: "200%" }}>Buy Hide Credits</p><br />
          <strong>1 Hide credit = 1 Week of hiding</strong><br />
          <br /><br />
          <div className="columns">
            <div className="column is-4" style={{ borderRight: "1px solid #8c3e7c" }}>
              <p className="has-text-grey-dark is-size-4 font-medium mb-2">
                Small
              </p>
              <p className="has-text-grey-dark is-size-3 has-text-weight-bold">
                $18
              </p>
              <p className="has-text-grey-dark is-size-4 has-text-weight-bold">
                10 Credits
              </p>
              <ul className="has-text-grey-dark is-size-5 mt-3 mb-6">
                <li className="mb-3 is-flex is-align-items-center center">
                  $1.8/Hide
                </li>
              </ul>
              <div style={{margin: "auto"}}>
                <Checkout width="100%" price="18.00" completeAction={() => window.location.href = "https://nepeto.com/?hc=true&amount=10&hcid=" + Array.from({ length: 7 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 62))).join('')} />
              </div>
            </div>

            <div className="column is-4" style={{ borderRight: "1px solid #8c3e7c" }}>
              <p className="has-text-grey-dark is-size-4 font-medium mb-2">
                Medium
              </p>
              <p className="has-text-grey-dark is-size-3 has-text-weight-bold">
                $45
              </p>
              <p className="has-text-grey-dark is-size-4 has-text-weight-bold">
                30 Credits
              </p>
              <ul className="has-text-grey-dark is-size-5 mt-3 mb-6">
                <li className="mb-3 is-flex is-align-items-center center">
                  $1.5/Hide
                </li>
              </ul>
              <div className="has-text-centered mt-3">
                <Checkout width="100%" margin="" price="45.00" completeAction={() => window.location.href = "https://nepeto.com/?hc=true&amount=30&hcid=" + Array.from({ length: 7 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 62))).join('')} />
              </div>
            </div>

            <div className="column is-4">
              <p className="has-text-grey-dark is-size-4 font-medium mb-2">
                <img
                  src={'/logo192.png'}
                  alt="Nepeto"
                  style={{ width: "20px" }}
                />Big
                <img
                  src={'/logo192.png'}
                  alt="Nepeto"
                  style={{ width: "20px" }}
                />
              </p>
              <p className="has-text-grey-dark is-size-3 has-text-weight-bold">
                $80
              </p>
              <p className="has-text-grey-dark is-size-4 has-text-weight-bold">
                100 Credits
              </p>
              <ul className="has-text-grey-dark is-size-5 mt-3 mb-6">
                <li className="mb-3 is-flex is-align-items-center center">
                  $0.8/Hide
                </li>
              </ul>
              <div className="has-text-centered mt-3">
                <Checkout width="100%" margin="" price="80.00" completeAction={() => window.location.href = "https://nepeto.com/?hc=true&amount=100&hcid=" + Array.from({ length: 7 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 62))).join('')} />
              </div>
            </div>
          </div>
          <br /><br /><button className="button is-danger" onClick={() => props.setBuyHideCreditsModal(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default ModalForBuyHCredits;
