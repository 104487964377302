import { Link } from "react-router-dom";
import logo from "../logo2white.svg";

function Footer(props) {
  return (
    <>
      <section className="footerwaves">
        <div className="custom-shape-divider-bottom">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <div
          className="columns is-variable is-8"
          style={{
            textAlign: "center",
            alignItems: "center",
            marginTop: "7.5vh",
            maxWidth: "100vw"
          }}
        >
          <div className="column">
            <img
              src={logo}
              alt="Nepeto"
              style={{
                scale: props.isMobile ? ".5" : ".8",
                // mixBlendMode: "multiply",
                marginBottom: props.isMobile ? "-2.5vh" : "-1.5vh",
                marginTop: props.isMobile ? "0vh" : "-2vh",
              }}
            />
            <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
              Earbe LLC - Gal Cheft<br/>
              support@nepeto.com<br/>
              ig01.projects@gmail.com<br/>
              8 The Green Dover DE
            </span>
          </div>
          <div className="column is-2">
            <h1 style={{ marginBottom: "1vh", fontWeight: "650", marginTop: props.isMobile && "3vh", fontSize: props.isMobile && "2.3vh" }}>
              Getting Started
            </h1>
            <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
              <Link style={{color: "white"}} to="/how-to-use-nepeto">How to use Nepeto</Link>
              <br />
              <Link style={{color: "white"}} to="/blog">Blog</Link>
              <br />
              <Link style={{color: "white"}} to="/blog/what-is-nepeto">What is Nepeto</Link>
              {/* <Link style={{color: "white"}} to="/courses">How to use Nepeto</Link> */}
            </span>
          </div>
          <div className="column is-2">
            <h1 style={{ marginBottom: "1vh", fontWeight: "650", marginTop: props.isMobile && "3vh", fontSize: props.isMobile && "2.3vh" }}>Navigate</h1>
            <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
              <Link style={{color: "white"}} to="/pricing">Pricing</Link>
              <br />
              <Link style={{color: "white"}} to="/faq">FAQ</Link>
              <br />
              <Link style={{color: "white"}} to="/contact">Contact Us</Link>
            </span>
          </div>
          <div className="column is-2">
            <h1 style={{ marginBottom: props.isMobile ? "3vh" : "1vh", fontWeight: "650", marginTop: props.isMobile && "3vh", fontSize: props.isMobile && "2.3vh" }}>Sourcing</h1>
            <div className="columns is-variable is-0">
              <div className="column">
                <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
                  <Link style={{color: "white"}} to="/retail">Retail</Link>
                  <br />
                  <Link style={{color: "white"}} to="/distributors">Distributors</Link>
                  <br />
                  <Link style={{color: "white"}} to="/wholesale">Wholesale</Link>
                </span>
              </div>
              <div className="column">
                <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
                  <Link style={{color: "white"}} to="/oos">OOS</Link>
                  <br />
                  <Link style={{color: "white"}} to="/amazon-flips">A2A (AZ Flips)</Link>
                  <br />
                  <Link style={{color: "white"}} to="/chrome-extension">Extension</Link>
                </span>
              </div>
            </div>
          </div>
          <div className="column is-2">
            <h1 style={{ marginBottom: props.isMobile ? "3vh" : "1vh", fontWeight: "650", marginTop: props.isMobile && "3vh", fontSize: props.isMobile && "2.3vh" }}>Social</h1>
            <div className="columns is-variable is-0">
              <div className="column">
                <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
                  <a href="https://www.facebook.com/share/ofb5faZtiNzMDmPs/?mibextid=K35XfP" style={{color: "white"}}>Facebook</a>
                  <br />
                  <a href="https://www.instagram.com/_nepeto/" style={{color: "white"}}>Instagram</a>
                  <br />
                  <a href="https://discord.gg/jg2vmgMrz9" style={{color: "white"}}>Discord</a>
                </span>
              </div>
              <div className="column">
                <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
                  <a href="https://twitter.com/_nepeto" style={{color: "white"}}>Twitter</a>
                  <br />
                  <a href="https://www.youtube.com/@_nepeto" style={{color: "white"}}>Youtube</a>
                  <br />
                  <a href="https://www.tiktok.com/@_nepeto" style={{color: "white"}}>TikTok</a>
                </span>
              </div>
            </div>
          </div>
          <div className="column is-2">
            <h1 style={{ marginBottom: "1vh", fontWeight: "650", marginTop: props.isMobile && "3vh", fontSize: props.isMobile && "2.3vh" }}>
              Documents
            </h1>
            <span style={{ fontSize: props.isMobile ? "2vh" : ".8vw" }}>
              <a href="/termsandpolicies.pdf" style={{color: "white"}}>Terms and Conditions</a>
              <br />
              <a href="/privacyterms.pdf" style={{color: "white"}}>Privacy Terms</a>
              <br />
              <Link to="/affiliate" style={{color: "white"}}>Affiliate Program</Link>
            </span>
          </div>
        </div>
      </section>
      <p
        style={{
          backgroundColor: "#8c3e7c",
          color: "white",
          margin: "auto",
          textAlign: "center",
          paddingTop: props.isMobile && "3vh",
          marginTop: "-1px",
          fontSize: props.isMobile ? "2vh" : ".8vw"
        }}
      >
        © 2024 by Nepeto
      </p>
    </>
  );
}

export default Footer;
