import { useAuth0 } from "@auth0/auth0-react";
import HistoryGraph from "../../Products/Components/HistoryGraph";
import { useEffect, useState } from "react";
import ModalForVariationsTable from "../../Products/Components/ModalForVariationsTable";
import { useNavigate } from "react-router-dom";

function OOSAdvancedDataModal(props) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [modalForVariations, setModalForVariations] = useState(false);

  useEffect(() => {
    const getAdvancedData = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch(
        "https://server.nepeto.com/get_oos_advanced_data/" +
          props.advancedDataProduct.asin.split("/")[
            props.advancedDataProduct.asin.split("/").length - 1
          ] +
          "/" +
          props.advancedDataProduct.category +
          "/" +
          props.advancedDataProduct.sales_rank +
          "/" +
          props.advancedDataProduct.price +
          "/",
        {
          mode: "cors",
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });
    };

    if (!isLoading && isAuthenticated) {
      getAdvancedData();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, props.advancedDataProduct.price, props.advancedDataProduct.asin, props.advancedDataProduct.category, props.advancedDataProduct.sales_rank]);

  return (
    <div
      id="modal-js-example"
      className={
        "modal" + (props.advancedDataProduct !== null ? " is-active" : "")
      }
    >
      <div className="modal-background" onClick={() => {
                  props.setAdvancedDataProduct(null);
                }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "40vw" }}
      >
        {data === "No permissions" ? (
          <div className="box" style={{ textAlign: "center" }}>
            <strong style={{ fontSize: "150%" }}>Advanced Product Data</strong>
            <br />
            <br />
            <span>
              The advanced product data is available for the Advanced Plan and above.
              <br />
              Please upgrade your plan to access this data.
            </span>
            <br />
            <br />
            <button
              className="button is-rounded"
              style={{
                backgroundColor: "#32CD32",
                color: "#ffffff",
                fontSize: props.fontSize
              }}
              onClick={() => navigate("/pricing")}
            >
              Upgrade
            </button>
          </div>
        ) : (
          <div className="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
              <strong>Advanced Product Data</strong>
              <br />
              <br />
              <div class="columns">
                <div class="column">
                  <table
                    class="table is-bordered"
                    style={{
                      width: "100%",
                      textAlign: "left",
                      height: "100%",
                      fontSize: "65%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>Last Price</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.last_price === -1 ? "N/A" : "$" + data.last_price
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Last ROI</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.last_roi === -1 ? "N/A" : (data.last_roi * 100).toFixed(2) + "%"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Time In-Stock</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            (new Date(data.last_time_in_stock * 1000)).toLocaleDateString()
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Time AZ Sell It</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            (new Date(data.last_time_az_was_seller * 1000)).toLocaleDateString()
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Going-OOS Count</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.how_much_time_went_oos
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>AMZ OOS % (30 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.oos_percentages_30days_amz + "%"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>AMZ OOS % (90 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.oos_percentages_90days_amz + "%"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>New OOS % (30 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.oos_percentages_30days_new + "%"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>New OOS % (90 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.oos_percentages_90days_new + "%"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Rating</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.rating === -1 ? "N/A" : data.rating + "★"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Count Reviews</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.count_reviews
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column">
                  <table
                    class="table is-bordered"
                    style={{
                      width: "100%",
                      textAlign: "left",
                      height: "100%",
                      fontSize: "65%",
                    }}
                  >
                    <tbody>
                    <tr>
                        <td>Sales Rank Drops (30 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.sales_rank_drops_30 === -1 ? "N/A" : data.sales_rank_drops_30
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Sales Rank Drops (90 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.sales_rank_drops_90 === -1 ? "N/A" : data.sales_rank_drops_90
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Sales Rank Drops (180 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.sales_rank_drops_180 === -1 ? "N/A" : data.sales_rank_drops_180
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Sales Rank Drops (365 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.sales_rank_drops_365 === -1 ? "N/A" : data.sales_rank_drops_365
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>AZ Price Avg (30 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.az_price_avg30 === -0.01 ? "N/A" : "$" + data.az_price_avg30
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>AZ Price Avg (90 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.az_price_avg90 === -0.01 ? "N/A" : "$" + data.az_price_avg90
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>BSR Avg (30 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "#" + data.bsr_avg30
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>BSR Avg (90 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "#" + data.bsr_avg90
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Variations Amount</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : data.variations && data.variations !== "null" ? (<>
                            {JSON.parse(data.variations).length}{"  "}&nbsp;
                            <button style={{padding: "0px 1px", cursor: "pointer", boxShadow: "0px 0px 2px 0px gray"}} onClick={() => setModalForVariations(true)}><i class="fa fa-table" aria-hidden="true"></i></button>
                            {modalForVariations && <ModalForVariationsTable variations={data.variations} currAsin={props.advancedDataProduct.asin.split("/")[props.advancedDataProduct.asin.split("/").length - 1]} modalForVariations={modalForVariations} setModalForVariations={setModalForVariations} isMobile={props.isMobile}/>}
                            </>
                          ) : (
                            0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Estimated Monthly Sales</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.estimate_sales_30_days
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{width: props.isMobile ? "100%" : "55%", margin: "auto", marginLeft: props.isMobile ? "5%" : "25%"}}>
                  {props.isMobile && <br/>}
                  {data === null ? (
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  ) : (
                    <HistoryGraph
                      valueName="Offers Count"
                      history={data.new_offers_graph}
                      color="darkblue"
                    />
                  )}
                </div>
            </p>
            <div className="columns is-mobile is-centered">
              <button
                className="button is-rounded is-danger"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => {
                  props.setAdvancedDataProduct(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OOSAdvancedDataModal;
