import "../../Products/Components/Card.css";
import FavoritesIcon from "../../Products/Components/FavoritesIcon";
import { OOSbyZIPSuppliers } from "../../Products/Components/OOSbyZIP";
import { signupDiscountSuppliers } from "../../Products/Components/RegisterForDiscount";

function OOSCard(props) {
  const mainProductPage = ["big5sportinggoods", "dillards", "famousfootwear", "kohls", "dickssportinggoods", "boscovs", "jcpenney", "fivebelow", "nike", "belk", "underarmour", "crocs", "champssports", "sephora", "rei", "joann"]
  const cardStyle = {
    backgroundColor: "#e6cede",
    borderRadius: "20px",
    color: "#000",
    border: "1px solid rgba(0,0,0,0.2)",
  };

  const spanStyle = {
    backgroundColor: "#8c3e7c",
    color: "#e6cede",
    width: "100%",
    maxWidth: "25vw",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whitSpace: "nowrap",
    padding: "0% 1%",
    fontSize: "4vw",
  };

  return (
    <div
      className="card-content has-text-centered has-text-weight-bold"
      style={cardStyle}
    >
      <div className="columns" style={{ height: "120px" }}>
        <div className="column is-flex is-flex-direction-column">
          <p
            className="is-align-items-flex-start content is-size-8"
            style={{ whiteSpace: "nowrap" }}
          >
            {props.product.title.slice(0, 30)}...
            <br />
            <a
              href={props.product.asin}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "underline",
                fontWeight: "900",
                color: "black",
              }}
            >
              {
                props.product.asin.split("/")[
                  props.product.asin.split("/").length - 1
                ]
              }
            </a>
          </p>
        </div>

        <div className="columns is-mobile mt-1">
          <img
            src={props.product.img}
            alt="product"
            style={{ width: "27.5vw", height: "27.5vw", marginLeft: "5vw" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/assets/noimage.png";
            }}
          />
          <img
            src={(props.product.az_img !== null && props.product.az_img !== "") ? 
                  props.product.az_img : 
                  `https://images.amazon.com/images/P/${props.product.asin.split("/")[props.product.asin.split("/").length - 1]}.jpg`}
            alt="product"
            style={{ width: "27.5vw", height: "27.5vw", marginLeft: "10vw" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/assets/noimage.png";
            }}
          />
        </div>
      </div>
      <div
        className="columns is-mobile is-variable is-6"
        style={{ height: "10vh", marginTop: "15vw", marginBottom: "0" }}
      >
        <div className="column">
          <span
            className="tag is-rounded has-text-weight-normal"
            style={spanStyle}
            onClick={() => {
              let message = "";
              if (mainProductPage.includes(props.product.source_name)) {
                message +=
                  "You will see the main product page.\nYou will need to set the correct variation yourself.\n";
              }
              if (
                signupDiscountSuppliers.includes(
                  props.product.source_name
                )
              ) {
                message +=
                  "To receive the lowest price, this supplier requires you to sign up to their website.";
              }
              if (
                OOSbyZIPSuppliers.includes(
                  props.product.source_name
                )
              ) {
                message +=
                  "\nIf the product is out of stock - Change the ZIP CODE!\n";
              }
              if (message !== "" && (localStorage.getItem("showSuppMesssage") === 'true' || localStorage.getItem("showSuppMesssage") === null)) {
                props.setShowMessageObj({
                  message: message,
                  messageAction: props.openSuppLink,
                  messageActionArg: props.product,
                });
              } else {
                props.openSuppLink(props.product);
              }
            }}
          >
            &ensp;{props.product.source_name}&ensp;
          </span>
          <p className="content is-large">${props.product.price.toFixed(2)}</p>
        </div>
        <div className="column">
          <span
            className="tag is-rounded has-text-weight-normal"
            style={spanStyle}
            onClick={() => {
              window.open(props.product.asin);
            }}
          >
            &ensp;Amazon&ensp;
          </span>
          <p className="content is-large">N/A</p>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(200,0,0,0.1)",
          border: "1px solid purple",
          borderRadius: "20px",
          marginTop: "-2vw",
          marginBottom: "5vw",
        }}
      >
        x{props.product.qty} | MOQ: {props.product.moq} | #{props.product.sales_rank}
      </div>
      <img
        alt="graph"
        style={{
          height: "17vh",
          marginTop: "1vw",
          marginBottom: "1.5vh",
        }}
        onClick={() =>
          window.open(
            `https://keepa.com/#!product/1-${
              props.product.asin.split("/")[
                props.product.asin.split("/").length - 1
              ]
            }}`,
            "_blank"
          )
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/assets/noimage.png";
        }}
        src={`https://graph.keepa.com/?asin=${
          props.product.asin.split("/")[
            props.product.asin.split("/").length - 1
          ]
        }&domain=com`}
      />
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
        <strong
          className="button is-rounded is-small"
          onClick={() => {
            let message = "";
            if (mainProductPage.includes(props.product.source_name)) {
              message +=
                "You will see the main product page.\nYou will need to set the correct variation yourself.\n";
            }
            if (
              signupDiscountSuppliers.includes(
                props.product.source_name
              )
            ) {
              message +=
                "To receive the lowest price, this supplier requires you to sign up to their website.";
            }
            if (
              OOSbyZIPSuppliers.includes(
                props.product.source_name
              )
            ) {
              message +=
                "\nIf the product is out of stock - Change the ZIP CODE!\n";
            }
            if (message !== "" && (localStorage.getItem("showSuppMesssage") === 'true' || localStorage.getItem("showSuppMesssage") === null)) {
              props.setShowMessageObj({
                message: message,
                messageAction: props.openSuppLink,
                messageActionArg: props.product,
              });
            } else {
              props.openSuppLink(props.product);
            }
          }}
          style={{
            backgroundColor: "#8c3e7c",
            color: "white",
            fontSize: "4vw",
            padding: "0px 12vw",
          }}
        >
          LINK
        </strong>
        <FavoritesIcon oos={true} isMobile={true} url={props.product.url} favorite={props.product.favorite}/>
      </div>
      {props.product.title.includes("Last Update") && <span style={{position: 'absolute', fontSize: "1.6vh", width: "80vw", opacity: "0.5", left: "-2%", top: "91.2%", height: "0px"}}><br/>Found on {new Date(props.product.title.split("Last Update: ")[1]).toLocaleString().includes("Invalid") ? props.product.title.split("Last Update: ")[1] : new Date(props.product.title.split("Last Update: ")[1]).toLocaleString()}</span>}
    </div>
  );
}

export default OOSCard;
