import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState, useEffect } from "react";

const initialOptions = {
  clientId: "AXOglQsiyPeBp1GYe2Ehys3IeS7Od9AngwurkI54YnGXQuWYY9Pyl5aA2GwCpW7Oz1wRRF5JVWxTAh5Y",
  vault: true,
  intent: "subscription",
};
function RAEXTPricing(props) {
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [email, setEmail] = useState(urlParams.has("email") ? urlParams.get("email") : false);
  const [subData, setSubData] = useState(false);
  const [noTrial, setNoTrial] = useState(undefined);

  if (!email) {
    return <div style={{ height: "80vh", textAlign: "center", marginTop: "10vh" }}>
      <h1>Enter your email address:</h1>
      <input class="input" placeholder="Email Address" onBlur={(e) => setEmail(e.target.value)} />
      <button className="button is-success">Go!</button>
    </div>
  }

  const getSub = async () => {
    setSubData(true);
    setLoadingRedirect(true);
    fetch(
      `https://server.nepeto.com/nepeto-ra-subscription-details/${email}/`,
      {
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setSubData(data)
      })
      .catch(() => {
        alert("There has been an error. Please contact our support, and they will help you ASAP.");
        setLoadingRedirect(false);
      });
  };

  const cancelSub = async () => {
    fetch(
      `https://server.nepeto.com/nepeto-ra-cancel/${email}/`,
      {
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.reload();
      })
      .catch(() => {
        alert("There has been an error. Please contact our support, and they will help you ASAP.");
      });
  };

  if (urlParams.has("edit")) {
    if (subData === false) {
      getSub();
    }
    return <div style={{height: "80vh", textAlign: "center", marginTop: "5vh"}}>{(subData !== true && subData !== false) ? <>
      <h1>
        {subData && (subData['active'] ? "Subscription is active" : "Subscription is not active")}<br />
        {subData && subData['active'] && subData['next_billing_time'] && `Next billing: ${new Date(subData['next_billing_time']).toLocaleString()}`}<br /><br />
        {subData && subData['active'] && <button className="button is-warning" style={{ fontSize: "75%" }} onClick={(e) => { e.target.disabled = true; e.target.style.backgroundColor = "gray"; e.target.innerHTML = "Loading...";  cancelSub();}}>Cancel Subscription</button>}
      </h1>
    </> : "Loading..."}</div>
  }

  const getHadTrial = async () => {
    setNoTrial("loading")
    fetch(
      `https://server.nepeto.com/nepeto-ra-oa-had-trial/${email}/`,
      {
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setNoTrial(data)
      })
      .catch(() => {
        alert("There has been an error. Please contact our support, and they will help you ASAP.");
      });
  };

  if (noTrial === undefined || noTrial === "loading") {
    if (noTrial === undefined) {
      getHadTrial();
    }
    return <div style={{height: "80vh", textAlign: "center", marginTop: "5vh"}}>Loading...</div>
  }

  const buySubscription = async (subscription_id) => {
    setLoadingRedirect(true);
    fetch(
      `https://server.nepeto.com/nepeto-ra-oa-start/${email}/${subscription_id}/${(localStorage.getItem('affiliate') || "none")}/`,
      {
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        alert("Thank you for choosing Nepeto! Go ahead and download the Mobile App and Extension, and enjoy our software!")
        window.location.href = "/app-ext";
        setLoadingRedirect(false);
      })
      .catch(() => {
        alert("There has been an error. Please contact our support, and they will help you ASAP.");
        setLoadingRedirect(false);
      });
  };

  return <div>
    <img src="logo512.png" style={{ margin: "auto", display: "block", width: "10%", marginTop: "1%" }} />
    <h1
      style={{
        fontSize: props.isMobile ? "3vh" : "2vw",
        fontWeight: "380",
        textAlign: "center",
        color: "#8c3e7c",
        marginBottom: props.isMobile && "2vh",
      }}
    >
      <strong style={{ color: "#8c3e7c" }}>Nepeto's RA Mobile App & Chrome Extension</strong><br />
      <strong style={{ color: "#8c3e7c" }}>2 Mobile App Installs and 2 Chrome Extension Installs</strong>
      <br /><br />
      Get Unlimited Scans and explore the latest features (RA Explorer, etc.)!
      <br /><br />
      Now only <strong style={{color: "#8c3e7c"}}>$14.99/month</strong> (instead of <i style={{textDecoration: "line-through"}}>$24.99/month</i>){noTrial ? " as a special offer for early users." : "."}<br />
      {noTrial ? "Upgrade Today:" : "Start your 14-day free trial now:"}
    </h1>
    <br />
    <div style={{ margin: "auto", display: "inline-grid", width: "100%", marginTop: "5px" }}>
      <div style={{ margin: "auto" }}>
        {<PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            style={{
              shape: "rect",
              layout: "vertical",
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                "plan_id": noTrial ? "P-7Y169561G3676371DM4OOWZQ" : "P-9A078389W6247063UM4OOVSQ",
                application_context: {
                  shipping_preference: "NO_SHIPPING"
                }
              });
            }}
            onApprove={(data) => {
              buySubscription(data.subscriptionID)
            }}
            onCancel={() => ""}
            onError={() => console.log("Please try again.")} />
        </PayPalScriptProvider>}

        {(loadingRedirect) && <div style={{
          position: 'absolute',
          zIndex: 2147483647,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transform: 'translate3d(0, 0, 0)',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          background: 'radial-gradient(50% 50%, ellipse closest-corner, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0.8) 100%)',
          color: '#fff',
          textAlign: "center",
          lineHeight: "80vh"
        }}>Loading...</div>}
      </div>
    </div>
    <br /><br /><br /><br /><br /><br /><br /><br />
  </div>
}
export default RAEXTPricing;
