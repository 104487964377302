import { Helmet } from "react-helmet";

function Bundles(props) {
  return (
    <>
      <Helmet>
        <title>Nepeto - Bundles Deals for Amazon</title>
        <meta
          name="description"
          content="Explore bundle deals for a higher return on investment (ROI). Discover diverse deals containing multiple ASINs and enjoy significant discounts."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, bundles, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div className="columns" style={{ marginBottom: "8vh" }}>
        <div className="column is 6" style={{ marginLeft: "5vw" }}>
          <h1
            style={{
              fontSize: props.isMobile ? "5vh" : "4vw",
              marginTop: props.isMobile ? "5vh" : "13vh",
              color: "#8c3e7c",
            }}
          >
            Bundles Deals
          </h1>
          <h2
            style={{
              fontSize: props.isMobile ? "2.5vh" : "2vw",
              color: "#8c3e7c",
            }}
          >
            Finding great bundles to resell on Amazon!
          </h2>
          <br />
          <p style={{ width: props.isMobile ? "90vw" : "38vw" }}>
            Explore our special category featuring bundles tailored for Amazon
            sales. These supplier-provided bundles come with detailed
            breakdowns, specifying quantity, type, and more for each item in the
            bundle. The big benefit? Buying products in bundles means enjoying
            discounted prices, giving you a competitive advantage in the
            marketplace!
          </p>
          <br />
          <p>Coming soon...</p>
          <br />
          <form
            action="https://server.nepeto.com/email"
            method="post"
            accept-charset="UTF-8"
          >
            Sign up for updates:
            <label className="is-block mb-4">
              <input
                required
                name="email"
                type="email"
                className="input"
                placeholder="Email address"
                style={{
                  width: props.isMobile ? "60vw" : "25vw",
                  marginTop: "5px",
                }}
              />
              <button
                type="submit"
                className="button px-4"
                style={{
                  backgroundColor: "#8c3e7c",
                  color: "white",
                  marginTop: "5px",
                }}
              >
                Subscribe
              </button>
              <div
                style={{
                  textIndent: "-99999px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  position: "absolute",
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="_gotcha"
                  tabindex="-1"
                  autocomplete="off"
                />
                <input
                  type="text"
                  name="what_is_it"
                  value={"bundles"}
                  tabindex="-1"
                  autocomplete="off"
                />
              </div>
            </label>
          </form>
        </div>
        <div className="column is 6">
          <img
            alt="bundles"
            style={{
              height: !props.isMobile && "65vh",
              float: !props.isMobile && "right",
              marginRight: "7.5vw",
              marginTop: "5vh",
            }}
            src={"/assets/bundles.jpg"}
          />
        </div>
      </div>
    </>
  );
}

export default Bundles;
