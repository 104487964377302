import './PackmanLoader.css'

function PrivacyTerms(props) {
  return <div style={{
    height: "100vh",
    margin: 'auto',
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginLeft: props.isMobile ? "-17%" : "0",
    overflowY: "scroll"
  }}
  >
    <div style={{ margin: "auto", width: "70vw", }}>
      <h2>Privacy Terms</h2>
      <p><strong>Effective Date:</strong> October 26, 2023</p>

      <h3>Introduction</h3>
      <p>
        These Privacy Terms ("Provisions") govern the acquisition, utilization, and dissemination of personal data when you interact with or utilize the services provided by Nepeto ("we," "us," or "our") through our website, application, or affiliated offerings, collectively referred to as the "Services."
      </p>
      <p>
        By engaging with or utilizing our Services, you grant your consent to the procedures delineated in these Provisions and agree to the gathering, utilization, and disclosure of your personal data as expounded below. If you dissent with these Provisions, kindly abstain from using our Services.
      </p>

      <h3>Information We Gather</h3>
      <p>We may amass the ensuing personal data from you, either voluntarily furnished or through your interaction with our Services:</p>
      <ul>
        <li><strong>Email Address:</strong> The collection of your email address is intended for disseminating crucial updates, notifications, and promotional material germane to our Services.</li>
        <li><strong>Telephone Number:</strong> Your telephone number is amassed to expedite communication with you pertaining to your account or any inquiries you may possess.</li>
        <li><strong>Hidden Products:</strong> The details of the products you hid to help us suggest similar products.</li>
        <li><strong>Credit Card Data:</strong> Your credit card data is collected and securely stored via a reputable third-party payment processor like Stripe to streamline your purchases and facilitate payment processing for the products you request.</li>
      </ul>

      <h3>Utilization of Personal Data</h3>
      <p>We leverage the personal data amassed for the ensuing objectives:</p>
      <ul>
        <li><strong>Service Provision:</strong> Your hidden products, telephone number, email address, and product particulars are employed to grant you access to our Services and to bestow functionality, including account management, order processing, and client support.</li>
        <li><strong>Communication:</strong> Your telephone number and email address may be employed to initiate communication concerning your account and responses to your inquiries.</li>
      </ul>

      <h3>Data Security</h3>
      <p>
        We execute judicious security measures to safeguard your personal data from unauthorized access, revelation, modification, or obliteration. Nevertheless, no mode of internet transmission or electronic storage can warrant unalloyed security, and we are unable to vouchsafe complete security.
      </p>

      <h3>Retention of Personal Data</h3>
      <p>
        We maintain your personal data for the duration essential to fulfill the objectives delineated in these Provisions, except in instances where the law requires or permits an extended retention period.
      </p>

      <h3>Disclosure of Personal Data</h3>
      <p>We may reveal your personal data to third parties under the ensuing situations:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may enlist reputable third-party service providers to aid in the provision or enhancement of our Services, including payment processors. These providers will gain access to your personal data but are bound by a commitment to preserve its confidentiality and employ it solely for purposes defined by us.</li>
        <li><strong>Legal Compliance:</strong> We may divulge your personal data when compelled by law or in response to valid legal requests, such as court orders or subpoenas.</li>
      </ul>

      <h3>Your Options and Entitlements</h3>
      <p>You possess the ensuing options and entitlements regarding your personal data:</p>
      <ul>
        <li><strong>Access and Revision:</strong> You may access and revise your personal data by contacting us via the details furnished below.</li>
        <li><strong>Erasure:</strong> You may request the erasure of your personal data by reaching out to us using the details provided below. Nevertheless, please be aware that specific data may need to be retained for legal or legitimate business motives.</li>
      </ul>

      <h3>Contacting Us</h3>
      <p>If you have queries, reservations, or grievances regarding our privacy practices or wish to avail yourself of your entitlements, kindly reach out to us at: <a href="mailto:support@nepeto.com">support@nepeto.com</a></p>

      <h3>Modifications to these Provisions</h3>
      <p>
        We may periodically revise these Privacy Provisions. The most current iteration of the Privacy Provisions will be published on our website. We encourage you to review these Provisions at intervals.
      </p>
      <p>
        By continuing to utilize our Services after the effective date of any modifications, you embrace and concur with the revised Privacy Provisions.
      </p>

      <p>We appreciate your diligence in reading and comprehending our privacy provisions.</p>
      <p><strong>Nepeto.</strong></p>
    </div>
  </div>
}

export default PrivacyTerms;
