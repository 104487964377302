import "./App.css";
import Home from "./pages/Home/Home";
import Pricing from "./pages/Pricing/Pricing";

import ProductsPage from "./pages/Products/Products";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import OOSProductsPage from "./pages/OOSProducts/OOSProducts";
import ContactUs from "./pages/ContactUs/ContactUs";
import { useEffect, useState } from "react";
import FAQ from "./pages/FAQ/FAQ";
import Telegram from "./pages/Telegram/Telegram";
import Bundles from "./pages/Bundles/Bundles";
import Liquidations from "./pages/Liquidations/Liquidations";
import Footer from "./components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import Blogs from "./pages/Blogs/Blogs";
import IntroductionToAmazonFBA from "./pages/Blogs/blogs/IntroductionToAmazonFBA";
import StartingAmazonFBA from "./pages/Blogs/blogs/StartingAmazonFBA";
import AmazonFbaProductSourcing from "./pages/Blogs/blogs/AmazonFbaProductSourcing";
import ListingProductsPricingStrategies from "./pages/Blogs/blogs/ListingProductsPricingStrategies";
import CustomerFeedback from "./pages/Blogs/blogs/CustomerFeedback";
import GrowthProfit from "./pages/Blogs/blogs/GrowthProfit";
import GettingStartedFBABeginners from "./pages/Blogs/blogs/GettingStartedFBABeginners";
import Affiliate from "./pages/Affiliate/Affiliate";
import SkyrocketSalesAmazonSeller from "./pages/Blogs/blogs/SkyrocketSalesAmazonSeller";
import TheBestAmazonArbitrageProducts from "./pages/Blogs/blogs/TheBestAmazonArbitrageProducts";
import HowToFindAmazonArbitrageProducts from "./pages/Blogs/blogs/HowToFindAmazonArbitrageProducts";
import AmazonArbitrageBoostProfit from "./pages/Blogs/blogs/AmazonArbitrageBoostProfit";
import HowToFindAmazonArbitrageProducts2 from "./pages/Blogs/blogs/HowToFindAmazonArbitrageProducts2";
import ExploringPrep from "./pages/Blogs/blogs/ExploringPrep";
import NepetoAlternatives from "./pages/Blogs/blogs/NepetoAlternatives";
import AffiliateDashboard from "./pages/AffiliateDashboard/AffiliateDashboard";
import GA from "./pages/Pricing/CustomPricing/GA";
import Whop from "./pages/Whop/Whop";
import Courses from "./pages/Courses/Courses";
import ChromeExtenstion from "./pages/ChromeExtenstion/ChromeExtenstion";
import WhatIsNepeto from "./pages/Blogs/blogs/WhatIsNepeto";
import Scanner from "./pages/Scanner/Scanner";
import A2AProductsPage from "./pages/AmazonFlips/A2AProducts";
import WhyFBAPrepIsCrucial from "./pages/Blogs/blogs/WhyFBAPrepIsCrucial";
import WhatAreOOSProducts from "./pages/Blogs/blogs/WhatAreOOSProducts";
import SuccessInQ4WithNepeto from "./pages/Blogs/blogs/SuccessInQ4WithNepeto";
import JimPricing from "./pages/Pricing/CustomPricing/Jim";
import JimProductsPage from "./pages/Products/JimProducts";
import A2WProductsPage from "./pages/AmazonToWalmart/A2WProducts";
import MismatchUpdate from "./pages/MismatchUpdate/MismatchUpdate";
import SellerAssistantApp from "./pages/Blogs/blogs/SellerAssistantApp";
import UserActivityDashboard from "./pages/UserActivityDashboard/UserActivityDashboard";
import Dashboard from "./pages/Dashboard/Dashboard";
import PackmanLoader from "./components/PacmanLoader";
import Joyride from 'react-joyride';
import GuideCircle from "./components/guideCircle";
import StorefrontScanner from "./pages/StorefrontScanner/StorefrontScanner";
import ProductFinder from "./pages/ProductFinder/ProductFinder";
import AppExtLandingPage from "./pages/AppExtLandingPage/AppExtLandingPage";
import PrivacyTerms from "./components/PrivacyTerms";
import LiveSearch from "./pages/LiveSearch/LiveSearch";
import SubscribeButtonPayPal from "./pages/Pricing/components/SubscribeButtonPayPal";
import Admin from "./pages/Admin/Admin";
import RAEXTPricing from "./pages/Pricing/RAEXTPricing";
import SubscribeButtonPPG from "./pages/Pricing/components/SubscribeButtonPPG";

function App() {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [isMobile, setIsMobile] = useState(false);
  const [startSubscription, setStartSubscription] = useState(false);
  const [userStatsLoading, setUserStatsLoading] = useState(true);
  const [threeNotif, setThreeNotif] = useState(localStorage.getItem("notCount") !== "1");
  const [threeNotVisible, setThreeNotVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const [matches, setMatches] = useState(0);
  const [hidden, setHidden] = useState([]);
  const [hideCredits, setHideCredits] = useState(0);
  const [isAff, setIsAff] = useState(false);
  const [activePlanLevel, setActivePlanLevel] = useState("");
  const [nextBillingTime, setNextBillingTime] = useState("");
  const [subscriptionActive, setSubscriptionActive] = useState(undefined);
  const [justHide, setJustHide] = useState("");
  const [sellerId, setSellerId] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [appExtMobile, setAppExtMobile] = useState(localStorage.getItem("appExtMobile") !== "1");
  const [joyrideRun, setJoyrideRun] = useState(localStorage.getItem("arianna") ? "" : "arianna");
  const joyrideSteps = {
    "arianna": [
      {
        target: '.guideCircle',
        title: "Hey there! Great to meet you! I'm Arianna!",
        content: "Feel free to click on me anytime for a quick guide on the page you're on!",
        disableBeacon: true,
        disableScrolling: true
      }],
    "/": [
      {
        "target": "#profileBlock",
        "content": "This is your user's data!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#infoIcon",
        "content": "Hover over this icon to learn more about each number!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#retailSourcing",
        "content": "Click here to find profitable products from Walmart, Target, Nike, and more!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#distributorsSourcing",
        "content": "Find profitable products from online stores like Dollar Tree, Webstaurantstore, and more!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#wholesaleSourcing",
        "content": "Buy Wholesale (High MOQ) and get a higher ROI!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#oosSourcing",
        "content": "Products that have gone out of stock on Amazon, and you can be the only seller!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#a2aSourcing",
        "content": "Find products on sale on Amazon, so you can buy them now and sell later!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#a2wSourcing",
        "content": "Do you sell on Walmart? This page is for you!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#storeFrontScanner",
        "content": "Use our Storefront Scanner to quickly source from other storefronts!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#chromeExtBtn",
        "content": "Use our Chrome extensions: Reverse Sourcing (Nepeto Alert) and Walmart Scanner!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#howToUseNep",
        "content": "Watch our hands-on tutorials here!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#userActBtn",
        "content": "Hire VAs to source for you and track their hours here!",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": "#retailSourcing",
        "content": "Start Here",
        "disableBeacon": true,
        "disableScrolling": true
      }
    ],
    "baseTable": [
      {
        "target": ".sourceImage",
        "content": "Click here to see the product from the supplier that you buy.",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": ".amazonImage",
        "content": "Click here to see the Amazon listing that you will sell.",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": ".adv-data-content-text",
        "content": "Click here to see all the data you need to make the best decision.",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#variationTt",
        "content": "Hover here to see the full name.",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#foundOnText",
        "content": "Here you can see the found time!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "marginTop": 10 } }
      },
      {
        "target": "#categoryTt",
        "content": "Category on Amazon",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#bsrTt",
        "content": "Sales rank number and Top BSR",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#variationBtnTt",
        "content": "By clicking here you can see all the listing's variations and if they exist on Nepeto!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#buyPriceTt",
        "content": "Buy Price",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#sellPriceTt",
        "content": "Sell Price (Current Lowest New Price + Shipping)",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#azFeesTt",
        "content": "Amazon Referral Fees + FBA Cost",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#costTt",
        "content": "Input additional cost to calculate ROI. Can be prep/shipping and such. It saves the number so reset if it's a one-time!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#azOffersTt",
        "content": "Offers count on the listing in a New condition",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#roiTt",
        "content": "Your expected ROI! *And profit on the left*",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#hideTt",
        "content": "You can hide this deal from other users by clicking here!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#searchTt",
        "content": "Compare this product's price on other suppliers!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#gatedTt",
        "content": "Click here to quickly check if you are gated!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#keepaTt",
        "content": "Check this product on Keepa",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#reportTt",
        "content": "Report a mismatch here. 50 true mismatches will grant you a free subscription!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#notInterested",
        "content": "Click here to not see this product for 30 days",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "marginTop": 10 } }
      },
      {
        "target": "#filterbarTt",
        "content": "The filter section! Filter out products and show only relevant products for YOU!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "padding": "8vh", "marginTop": "-5vh" } }
      },
      {
        "target": "#exportTt",
        "content": "Export page to a CSV (Excel)!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#filterbarTt",
        "content": "We recommend you check every filter here! You can filter by everything!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "padding": "8vh", "marginTop": "-5vh" } }
      },
      {
        "target": "#filterbarTt",
        "content": "Filter out products that Amazon sells, Buy Box, no Private Label, Minimum ROI, Top BSR %, Minimum AZOffers (Offer count), and more!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "padding": "8vh", "marginTop": "-5vh" } }
      },
      {
        "target": "#showdataTt",
        "content": "Click here to hide our Advanced Data row for a cleaner view :)",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#moreAdvTt",
        "content": "Click here to see more filtering options!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#filtersupTt",
        "content": "Here you will see all the suppliers we have, and you can filter by them!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      }
    ],
    "/oos": [
      {
        "target": ".sourceImage",
        "content": "Click here to see the product from the supplier that you buy",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": ".amazonImage",
        "content": "Click here to see the Amazon listing that you will sell on",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": ".advDataTt",
        "content": "Click here to see all the data you need to make the best decision - include last ROI and OOS stats",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#foundOnText",
        "content": "Here you can see the found time!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "marginTop": 10 } }
      },
      {
        "target": "#buyPriceTt",
        "content": "Buy Price",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#dyamicKeepaTt",
        "content": "Dynamic Keepa Graph (Can be used to quickly see the last price) - Hover to enlarge and click to configure parameters shown.",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#hideTt",
        "content": "You can hide this deal from other users by clicking here!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": ".gatedTt",
        "content": "Click here to quickly check if you are gated!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": ".keepaTt",
        "content": "Check this product on Keepa",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#filterbarTt",
        "content": "The filter section! Filter out products and show only relevant products for YOU!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "padding": "8vh", "marginTop": "-5vh" } }
      },
      {
        "target": "#proFeatTt",
        "content": "In the pro features, you can export to CSV!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#filterbarTt",
        "content": "We recommend you check every filter here! You can filter by everything here!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "padding": "8vh", "marginTop": "-5vh" } }
      },
      {
        "target": "#filtersupTt",
        "content": "Here you will see all the suppliers we have, and you can filter by them!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      }
    ],
    "/amazon-flips": [
      {
        "target": ".amazonImage",
        "content": "Click here to see the Amazon listing that you will flip",
        "disableBeacon": true,
        "disableScrolling": true
      },
      {
        "target": ".advDataTt",
        "content": "Click here to see all the data you need to make the best decision for this flip",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#averageTt",
        "content": "Average Price for 30 days and under it for 90 days",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#nowTt",
        "content": "The price now",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#estProfTt",
        "content": "The estimated gross profit",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#offersTt",
        "content": "Offers count",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#bsrDropsTt",
        "content": "BSR drops for 30 days and under it for 90 days",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#reviewsTt",
        "content": "Reviews count for the product",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#dynamicKeepaTt",
        "content": "Dynamic Keepa Graph - Hover to enlarge and click to configure",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#hideTt",
        "content": "You can hide this deal from other users by clicking here!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#gatedTt",
        "content": "Click here to quickly check if you are gated!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#keepaTt",
        "content": "Check this product on Keepa",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false
      },
      {
        "target": "#filterbarTt",
        "content": "The filter section! Filter out products and show only relevant products for YOU!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "padding": "8vh", "marginTop": "-5vh" } }
      },
      {
        "target": "#filterbarTt",
        "content": "We recommend you check every filter here! You can filter by everything here!",
        "disableBeacon": true,
        "disableScrolling": true,
        "spotlightClicks": false,
        "styles": { "spotlight": { "padding": "8vh", "marginTop": "-5vh" } }
      }
    ]
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Check if the 'affiliate' parameter exists in the query string
    if (urlParams.has("subscribed")) {
      setStartSubscription(true);
    }
    if (localStorage.getItem('affiliate') === null) {
      if (urlParams.has("a")) {
        localStorage.setItem("affiliate", urlParams.get('a'))
      } else if (urlParams.has("fbclid")) {
        localStorage.setItem("affiliate", "meta")
      } else if (urlParams.has("gclid")) {
        localStorage.setItem("affiliate", "google")
      }
    }
    if (urlParams.has("hide") && urlParams.has("asin") && !isLoading && isAuthenticated) {
      setJustHide(urlParams.get('asin'));
    }
    if (urlParams.has("hc") && urlParams.has("amount") && urlParams.has("hcid") && !isLoading && isAuthenticated) {
      const hideProductCreditsReq = async () => {
        const accessToken = await getAccessTokenSilently();
        fetch("https://server.nepeto.com/add_hide_credits/" + urlParams.get('amount') + "/" + urlParams.get("hcid") + "/", {
          mode: "cors",
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then((response) => response.json())
          .then((data) => {
            if (data === "yes") {
              setHideCredits(prev => prev + parseInt(urlParams.get('amount')))
            }
          });
      };
      hideProductCreditsReq()
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  // Check if it's a mobile device
  const checkIfMobile = (windowMatchMedia) => {
    if (windowMatchMedia.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const NavigateExternal = () => {
    window.location.href = "https://calendly.com/igprojects/nepeto-tutorial-call";
    return null;
  };

  // Check if it's mobile - init listeners
  useEffect(() => {
    const windowMatchMedia = window.matchMedia("(max-width: 700px)");
    checkIfMobile(windowMatchMedia); // Initial check

    const listener = (event) => checkIfMobile(event);

    windowMatchMedia.addEventListener("change", listener);

    return () => {
      windowMatchMedia.removeEventListener("change", listener);
    };
  }, []);

  // Update subscription status
  useEffect(() => {
    const updateSubscriptioon = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch("https://server.nepeto.com/update_subscription_status/" + (localStorage.getItem('affiliate') || 'none') + "/", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      }).then((response) => response.json())
        .then((data) => {
          if (data !== "No permissions" && data !== "Succeed!") {
            setMatches(data[0]);
            setIsAff(!!data[1]);
            setHidden(data[2]);
            setHideCredits(prev => prev + data[3]);
            setNextBillingTime(data[4])
            setSubscriptionActive(data[5])
            setUserStatsLoading(false)
          }
        });;
    };

    if (!isLoading && isAuthenticated) {
      updateSubscriptioon();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);

  // Update time spent
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const timeStart = new Date();
      let cip = "";
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => { cip = data.ip })
        .catch(error => console.log(error))


      const updateTimeSpent = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          fetch("https://server.nepeto.com/time_spent/" + ((new Date() - timeStart) / 1000) + "/" + cip + "/" + user.email + "/", {
            mode: "cors",
            headers: { Authorization: `Bearer ${accessToken}` },
          })
        } catch {
          console.log("ERR time spent")
        }
      };

      try {
        window.onbeforeunload = function () {
          try {
            updateTimeSpent();
          } catch {
            console.log("ERR time spent")
          }
        }
      } catch {
        console.log("ERR time spent")
      }

      const getActivePlan = async () => {
        const accessToken = await getAccessTokenSilently();
        fetch(
          "https://server.nepeto.com/active_plan",
          { mode: "cors", headers: { Authorization: `Bearer ${accessToken}` } }
        )
          .then((response) => response.json())
          .then((data) => {
            setActivePlanLevel(data)
          });
      };
      if (!isLoading && isAuthenticated) {
        getActivePlan();
        const checkSellerId = async () => {
          const res = await fetch("https://server.nepeto.com/mobile_app_get_amz_login/" + user.email + "/");
          const selId = await res.json();
          setSellerId(selId['spid'])
          setRefreshToken(selId['refreshtok'])
        }
        checkSellerId();
      }
    }
  }, [isLoading, isAuthenticated, user, getAccessTokenSilently])

  const sendPhone = async () => {
    try {
      if (phone !== "" && phone && phone.length > 1) {
        const accessToken = await getAccessTokenSilently();
        fetch("https://server.nepeto.com/update-phone/" + phone + "/", {
          mode: "cors",
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      }
    } catch {

    }
  }

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);

  return (
    <BrowserRouter>
      {!isMobile && joyrideRun !== "" && <Joyride
        steps={joyrideSteps.hasOwnProperty(joyrideRun) ? joyrideSteps[joyrideRun] : [
          {
            target: '.guideCircle',
            title: "Hey again!",
            content: "Sorry, but for this page's guide, you will have to send an email to our support team at support@nepeto.com :)",
            disableBeacon: true,
            disableScrolling: true
          }]}
        run={joyrideRun !== "" && !isLoading && isAuthenticated}
        continuous={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        hideCloseButton={true}
        showSkipButton={true}
        callback={(e) => { if (e.action === "reset") { localStorage.setItem("arianna", "1") } }}
        showProgress={joyrideSteps.hasOwnProperty(joyrideRun) ? joyrideSteps[joyrideRun].length > 1 : false}
        locale={{ last: "Close" }}
        styles={{
          spotlight: {
            borderRadius: 20,
          },
          tooltip: {
            padding: '3%',
          },
          tooltipContainer: {
            textAlign: 'center',
          },
          tooltipTitle: {
            fontSize: '100%',
            padding: "3% 1% 1% 1%"
          },
          tooltipContent: {
            fontSize: "150%",
            padding: '3% 3% 2% 3%'
          },
          options: {
            arrowColor: '#E3D0DF',
            backgroundColor: '#E3D0DF',
            primaryColor: '#8c3e7c',
            textColor: 'black',
            zIndex: 999
          },
        }}
      />}
      {!isMobile && <GuideCircle onClick={async () => { await setJoyrideRun(""); await setJoyrideRun((window.location.pathname === "/retail" || window.location.pathname === "/distributors" || window.location.pathname === "/wholesale") ? "baseTable" : window.location.pathname) }} />}

      <Navbar isAff={isAff} activePlanLevel={activePlanLevel} />
      <div class="montserrat newsMessage" style={{ backgroundColor: "rgba(250, 250, 100, 0.6)", boxShadow: "0px 0px 2px 0px black", marginTop: "1vh", width: "100vw", textAlign: "center", padding: "0" }}>
        <strong style={{ fontWeight: "1000" }}>
          <a href="https://calendly.com/igprojects/nepeto-tutorial-call" target="_blank" rel="noreferrer" style={{ color: "darkblue", textDecoration: "underline" }}>FREE walkthrough with an Amazon Expert</a>&emsp;{" |"}&emsp;
          <a href="/app-ext" target="_blank" rel="noreferrer" style={{ color: "darkgreen", textDecoration: "underline" }}>Download our Scanning App and Profit Calculator!</a>&emsp;{"|"}&emsp;
          <a href="https://www.facebook.com/share/ofb5faZtiNzMDmPs/?mibextid=K35XfP" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>Join our <i class="fa fa-facebook-official" aria-hidden="true"></i> Community!</a>
        </strong>
      </div>
      <Routes>
        <Route path="/">
          <Route index element={isLoading ? <PackmanLoader isMobile={isMobile} /> : (!isLoading && isAuthenticated) ? <Dashboard hideCredits={hideCredits} userStatsLoading={userStatsLoading} isMobile={isMobile} user={user} activePlanLevel={activePlanLevel} matches={matches} hidden={hidden} /> : <Home isMobile={isMobile} />} />
          <Route path="pricing" element={<Pricing activePlanLevel={activePlanLevel} nextBillingTime={nextBillingTime} subscriptionActive={subscriptionActive} isMobile={isMobile} />} />
          <Route path="pricing/ga" element={<GA activePlanLevel={activePlanLevel} isMobile={isMobile} />} />
          <Route path="pricing/silentjim" element={<JimPricing activePlanLevel={activePlanLevel} isMobile={isMobile} />} />
          <Route
            path="silentjim"
            element={
              <JimProductsPage sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} supplierType={"silentjim"} matches={matches} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />
            }
          />
          <Route
            path="retail"
            element={
              <ProductsPage sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} supplierType={"retail"} matches={matches} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />
            }
          />
          <Route
            path="distributors"
            element={
              <ProductsPage sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} supplierType={"distributors"} matches={matches} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />
            }
          />
          <Route
            path="wholesale"
            element={
              <ProductsPage sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} supplierType={"wholesale"} matches={matches} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />
            }
          />
          <Route path="oos" element={<OOSProductsPage sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} matches={matches} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />} />
          <Route path="contact" element={<ContactUs isMobile={isMobile} />} />
          <Route path="faq" element={<FAQ isMobile={isMobile} />} />
          <Route path="telegram" element={<Telegram isMobile={isMobile} />} />
          <Route path="bundles" element={<Bundles isMobile={isMobile} />} />
          <Route
            path="liquidations"
            element={<Liquidations isMobile={isMobile} />}
          />
          <Route path="tutorials" element={<Courses isMobile={isMobile} />} />
          <Route path="whop" element={<Whop isMobile={isMobile} />} />
          <Route path="mmupdate" element={<MismatchUpdate />} />
          <Route path="affiliate" element={<Affiliate isMobile={isMobile} />} />
          <Route path="affiliate-dashboard" element={<AffiliateDashboard isMobile={isMobile} />} />
          <Route path="user-activity" element={<UserActivityDashboard isMobile={isMobile} />} />
          <Route path="how-to-use-nepeto" element={<Courses isMobile={isMobile} />} />
          <Route path="chrome-extension" element={<ChromeExtenstion isMobile={isMobile} />} />
          <Route path="amazon-flips" element={<A2AProductsPage sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} matches={matches} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />} />
          <Route path="amazon-to-walmart" element={<A2WProductsPage isMobile={isMobile} matches={matches} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />} />
          <Route path="csv-scanner" element={<Scanner sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} hideCredits={hideCredits} activePlanLevel={activePlanLevel} />} />
          <Route path="storefront-scanner" element={<StorefrontScanner sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} activePlanLevel={activePlanLevel} hideCredits={hideCredits} />} />
          <Route path="master-search" element={<ProductFinder sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} activePlanLevel={activePlanLevel} hideCredits={hideCredits} />} />
          <Route path="training" element={<NavigateExternal />} />
          <Route path="app-ext" element={<AppExtLandingPage isMobile={isMobile} />} />
          <Route path="privacyterms" element={<PrivacyTerms />} />
          <Route path="ultimateplan" element={<div>
            <img src="logoNew.png" style={{ margin: "auto", display: "block" }} />
            <h1
              style={{
                fontSize: isMobile ? "3vh" : "2vw",
                fontWeight: "380",
                textAlign: "center",
                color: "#8c3e7c",
                marginBottom: isMobile && "2vh",
              }}
            >
              <h1
                style={{
                  fontSize: isMobile ? "4vh" : "3vw",
                  fontWeight: "380",
                  textAlign: "center",
                  color: "#8c3e7c",
                  marginBottom: isMobile && "3vh",
                }}
              >1-on-1 Expert Calls + Nepeto Ultimate Plan</h1>
              {activePlanLevel === 0 ? "7 Days Free Trial, And Then " : ""}Only $30 for the First Month!<br />
              For a limited time, get the STRONGEST version<br />of Nepeto for just $30 in your first month!</h1>
            <br />
            <div style={{ margin: "auto", display: "inline-grid", width: "100%", marginTop: "5px" }}>
              <SubscribeButtonPPG prd_id={activePlanLevel === 0 ? "101491" : "101492"} text={"Click here to start using Credit/Debit Card!"} />
              <span style={{ margin: "auto", marginBottom: "20px" }}>-OR-</span>
              <SubscribeButtonPayPal
                plan={"Master"}
                plan_id={activePlanLevel === 0 ? "P-2N222089KE757881RM4DR2XI" : "P-77F657104W9241135M4NHCRY"}
                prd_id={activePlanLevel === 0 ? "101491" : "101492"}
                planLevel={-1}
                monthly={true}
                activePlanLevel={activePlanLevel}
                price={30}
                traObj={{
                  plan: "Master",
                  plan_obj: false,
                  has_trial: activePlanLevel === 0,
                  planLevel: -1,
                  monthly: true,
                  activePlanLevel: activePlanLevel,
                  price: 30,
                  isMobile: isMobile
                }}
              />
            </div>
            <br /><br /><br /><br /><br /><br /><br /><br />
          </div>} />
          <Route path="live-search" element={<LiveSearch activePlanLevel={activePlanLevel} sellerId={sellerId} refreshToken={refreshToken} isMobile={isMobile} hideCredits={hideCredits} />} />
          <Route path="admin-ig-private" element={<Admin />} />
          <Route path="ra-ext-pricing" element={<RAEXTPricing isMobile={isMobile} />} />
          <Route path="blog/*" element={<Blogs isMobile={isMobile} />} />
          <Route
            path="*"
            element={
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10vh",
                  marginBottom: "70vh",
                }}
              >
                Page not found.
              </div>
            }
          />

          {/* Blogs */}
          <Route
            path="blog/getting-started-with-amazon-fba-for-beginners"
            element={<GettingStartedFBABeginners isMobile={isMobile} />}
          />

          <Route
            path="blog/what-is-amazon-fba"
            element={<IntroductionToAmazonFBA />}
          />
          <Route
            path="blog/starting-amazon-fba-business"
            element={<StartingAmazonFBA />}
          />
          <Route
            path="blog/amazon-fba-product-sourcing"
            element={<AmazonFbaProductSourcing isMobile={isMobile} />}
          />

          <Route
            path="blog/listing-products-and-pricing-strategies"
            element={<ListingProductsPricingStrategies />}
          />
          <Route
            path="blog/best-practices-for-customer-relations-and-feedback-management"
            element={<CustomerFeedback />}
          />
          <Route
            path="blog/growth-strategies-and-profit-maximization"
            element={<GrowthProfit />}
          />

          <Route
            path="blog/how-to-skyrocket-your-sales-as-an-amazon-fba-seller"
            element={<SkyrocketSalesAmazonSeller />}
          />

          <Route
            path="blog/the-best-products-for-amazon-arbitrage"
            element={<TheBestAmazonArbitrageProducts />}
          />

          <Route
            path="blog/how-to-find-amazon-arbitrage-products"
            element={<HowToFindAmazonArbitrageProducts />}
          />

          <Route
            path="blog/amazon-arbitrage-boost-profit"
            element={<AmazonArbitrageBoostProfit />}
          />

          <Route
            path="blog/how-to-find-amazon-arbitrage-products-in-2023-part-2"
            element={<HowToFindAmazonArbitrageProducts2 />}
          />

          <Route
            path="blog/exploring-the-world-of-fba-prep"
            element={<ExploringPrep />}
          />

          <Route
            path="blog/top-nepeto-alternatives-for-2023"
            element={<NepetoAlternatives />}
          />

          <Route
            path="blog/what-is-nepeto"
            element={<WhatIsNepeto />}
          />

          <Route
            path="blog/why-fba-prep-is-crucial"
            element={<WhyFBAPrepIsCrucial />}
          />

          <Route
            path="blog/what-are-oos-products"
            element={<WhatAreOOSProducts />}
          />

          <Route
            path="blog/success-in-q4-with-nepeto"
            element={<SuccessInQ4WithNepeto />}
          />

          <Route
            path="blog/partner-seller-assistant-app"
            element={<SellerAssistantApp />}
          />
        </Route>
      </Routes>

      {localStorage.getItem("acceptCookies") === null && (
        <div className="notification is-link is-light cookiesPopup">
          We use cookies. Click <a href="/cookies.pdf">here</a> for
          details.&emsp;
          <button
            className="acceptCookies"
            onClick={(e) => {
              localStorage.setItem("acceptCookies", "yes");
              e.target.offsetParent.style.display = "none";
            }}
          >
            Accept
          </button>
        </div>
      )}

      {isMobile && appExtMobile && window.location.pathname === "/" && (
        <div id="modal-js-example" className="modal is-active">
          <div class="modal-background" onClick={() => { setAppExtMobile(false); }}></div>

          <div className="modal-content" style={{ width: isMobile ? "90vw" : "40vw" }}>
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "200%" }}>Looking for our Retail Arbitrage Scanning Mobile App?</p><br />
              <br />
              <button class="button is-success" style={{ fontSize: isMobile ? "170%" : "1.8vw" }} onClick={() => { setAppExtMobile(false); window.location.href = "/app-ext" }}>Click Here</button>
              <br /><br /><button class="button is-danger" onClick={() => { setAppExtMobile(false); localStorage.setItem("appExtMobile", "1"); }}>Don't Show Again</button>
            </div>
          </div>
        </div>
      )}

      {startSubscription && (
        <div id="modal-js-example" class="modal is-active">
          <div class="modal-background" onClick={() => { setStartSubscription(false); sendPhone(); window.location.href = "https://nepeto.com/"; }}></div>

          <div class="modal-content" style={{ width: "80%" }}>
            <div class="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: isMobile ? "100%" : "200%", fontFamily: "fantasy" }}>Thank you for choosing Nepeto!</p>
              <p style={{ fontSize: isMobile ? "150%" : "300%", fontFamily: "sans-serif" }}>Schedule an onboarding tutorial at no additional cost.<br />
                We'll show you <strong style={{ borderBottom: "5px solid #8B3C7E" }}>how to make money using Nepeto</strong>!</p><br />
              <div class="calendly-inline-widget" data-url="https://calendly.com/igprojects/one-on-one-session?primary_color=8b3c7e" style={{ minWidth: "320px", height: "700px" }}></div>
              <div class="field" style={{ width: isMobile ? "100%" : "25%", margin: "auto", textAlign: "left" }}>
                Please enter your phone number:
                <p class="control has-icons-left">
                  <input class="input" type="tel" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value.replace(/[^0-9+]/g, ''))} />
                  <span class="icon is-small is-left">
                    <i class="fa fa-phone"></i>
                  </span>
                </p>
              </div>
              <br /><br /><button class="button is-success" onClick={() => { setStartSubscription(false); sendPhone(); window.location.href = "https://nepeto.com/"; }}>Click here to start!</button>
            </div>
          </div>
        </div>
      )}

      {justHide && (
        <div id="modal-js-example" class="modal is-active">
          <div class="modal-background" onClick={() => window.location.href = "/"}></div>

          <div class="modal-content">
            <div class="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: "200%" }}><strong>{justHide}</strong> will be hidden from other Nepeto users!</p><br />
              <p>You will be able to see how many days are left in 'My Hidden Products'<br />or on the dashboard by clicking 'Show' under 'Hidden Products'.</p>
              <br /><br /><button class="button is-success" onClick={() => window.location.href = "/"}>Okay</button>
            </div>
          </div>
        </div>
      )}

      {threeNotif && !isMobile && <div className="tutNotify" style={{ zIndex: "999999999", cursor: "pointer", position: "sticky", width: "3vw", height: "3vw", bottom: "25px", left: "10px", backgroundColor: "#e6cede", borderRadius: "1000px", textAlign: "center", boxShadow: "0px 0px 10px 0px #8c3e7c" }}>
        <i className="fa fa-bell-o tutNotifyIcon" aria-hidden="true" style={{ fontSize: "1.5vw", padding: "0.75vw" }} onClick={() => setThreeNotVisible(p => !p)}></i>

        {threeNotVisible && <div style={{ position: 'absolute', marginTop: "-11.5vw", width: "20vw", height: "7vw", border: "4px solid #8c3e7c", borderRadius: "30px", backgroundColor: "white", boxShadow: "0px 0px 10px 0px gray", cursor: "default", fontSize: "0.63vw" }}>
          <span style={{ margin: "2.5%", textAlign: "left", display: "flex" }}><div style={{ textAlign: "center", borderRadius: "30px", backgroundColor: "purple", color: "white", padding: "0.3% 2%", marginRight: "2%" }}>1</div> Attend our Basics Tutorial. &nbsp;<a target="_blank" rel="noreferrer" href="https://calendly.com/igprojects/nepeto-tutorial-call">Click here to schedule.</a></span>
          <span style={{ margin: "2.5%", textAlign: "left", display: "flex" }}><div style={{ textAlign: "center", borderRadius: "30px", backgroundColor: "purple", color: "white", padding: "0.3% 2%", marginRight: "2%" }}>2</div> Attend our Advanced Tutorial. &nbsp;<a target="_blank" rel="noreferrer" href="https://calendly.com/igprojects/nepeto-advanced-tutorial-call">Click here to schedule.</a></span>
          <span style={{ margin: "2.5%", textAlign: "left", display: "flex" }}><div style={{ textAlign: "center", borderRadius: "30px", backgroundColor: "purple", color: "white", padding: "0.3% 2%", marginRight: "2%" }}>3</div> Join our Facebook Community. &nbsp;<a target="_blank" rel="noreferrer" href="https://www.facebook.com/share/ofb5faZtiNzMDmPs/?mibextid=K35XfP">Click here to join.</a></span>
          <button onClick={() => { localStorage.setItem("notCount", "1"); setThreeNotif(false) }} style={{ fontFamily: "sans-serif", fontSize: "120%", cursor: "pointer", backgroundColor: "lightgreen", border: "0px", boxShadow: "0px 0px 5px 0px gray", borderRadius: "30px", marginLeft: "2%", cursor: "pointer" }}>Got all three must-do’s done! <i class="fa fa-check" aria-hidden="true"></i></button>
        </div>}
      </div>}

      <Footer isMobile={isMobile} />
    </BrowserRouter>
  );
}

export default App;
