import React, { useState, useEffect } from "react";
import "../Products/Products.css";
import { useAuth0 } from "@auth0/auth0-react";
import A2ACardSwipper from "./Components/A2ACardSwipper";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import A2AFilterBar from "./Components/A2AFilterBar";
import A2AProductsTable from "./Components/A2AProductsTable";

export default function A2AProductsPage(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [noPermissions, setNoPermissions] = useState(false);
  const [filters, setFilters] = useState({search: urlParams.has("asin") ? urlParams.get('asin') : ""});

  useEffect(() => {
    const loadProducts = async () => {
      setIsProductsLoading(true);
      const accessToken = await getAccessTokenSilently();
      fetch(
        "https://server.nepeto.com/load_az_flip_products_by_page/" +
          page +
          "/" +
          JSON.stringify(filters) +
          "/",
        { mode: "cors", headers: { Authorization: `Bearer ${accessToken}` } }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data === "No permissions") {
            setNoPermissions(true);
          } else {
            setNoPermissions(false);
            setData(data);
            setIsProductsLoading(false);
          }
        });
    };

    if (!isLoading && isAuthenticated) {
      loadProducts();
    }
  }, [page, filters, getAccessTokenSilently, isLoading, isAuthenticated]);


  if (noPermissions) {
    navigate("/pricing");
  }
  if (!isLoading && !isAuthenticated) {
    return (
      <div style={{ textAlign: "center", marginBottom: "80vh" }}>
        <Helmet>
          <title>Nepeto - Amazon Flips</title>
          <meta
            name="description"
            content="Explore Amazon 2 Amazon (Amazon Flips) products. Discover profitable opportunities."
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, a2a, amazon flips, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
        <br />
        <br />
        <p
          style={{
            width: "80vw",
            margin: "auto",
            fontSize: "200%",
            boxShadow: "0px 0px 5px 1px #8c3e7c",
            padding: "2%",
          }}
        >
          To use Nepeto, sign up and subscribe to one of our plans. You can
          start with a free sign-up and enjoy a free trial!
          <br />
          <br />
          <button
            className="button is-rounded is-size-6-mobile is-size-6-tablet"
            style={{
              backgroundColor: "#32CD32",
              margin: "auto",
              color: "#ffffff",
            }}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                },
                appState: { returnTo: "/pricing?s=1" },
              })
            }
          >
            Sign up for Free!
          </button>
        </p>
      </div>
    );
  }

  return (
    <div className="products">
        <Helmet>
          <title>Nepeto - Amazon Flips</title>
          <meta
            name="description"
            content="Explore Amazon 2 Amazon (Amazon Flips) products. Discover profitable opportunities."
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, a2a, amazon flips, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "1vh",
        }}
      >
        Amazon Flips (A2A)
        <br/>
        <p style={{fontSize: "50%", marginTop:"-0.5%", marginBottom: "0.5%"}}>
        Buy from Amazon on a price drop and sell on Amazon when the price goes back up</p>
      </h1>
      {isProductsLoading ? (
        <progress className="progress" style={{ marginBottom: "77vh" }} />
      ) : props.isMobile ? (
        <>
          <A2AFilterBar
            isMobile={props.isMobile}
            setFilters={setFilters}
            filters={filters}
          />
          <A2ACardSwipper data={data} setPage={setPage} hideCredits={props.hideCredits} />
        </>
      ) : (
        <>
          <A2AFilterBar
            isMobile={props.isMobile}
            setFilters={setFilters}
            filters={filters}
          />
          <A2AProductsTable
            data={data}
            setPage={setPage}
            page={page}
            isMobile={props.isMobile}
            hideCredits={props.hideCredits}
            setFilters={setFilters}
            filters={filters}
            sellerId={props.sellerId}
            refreshToken={props.refreshToken}
          />
        </>
      )}
    </div>
  );
}
