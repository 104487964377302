import { Helmet } from "react-helmet";

function Liquidations(props) {
  return (
    <>
      <Helmet>
        <title>Nepeto - Liquidations for Amazon</title>
        <meta
          name="description"
          content="Unlock great opportunities with Amazon liquidations. Discover discounted merchandise, closeout deals, and more. Explore the world of liquidations for Amazon sellers and boost your inventory."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, liquidations, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div className="columns" style={{ marginBottom: "6vh" }}>
        <div className="column is 6" style={{ marginLeft: "5vw" }}>
          <h1
            style={{
              fontSize: props.isMobile ? "5vh" : "4vw",
              marginTop: props.isMobile ? "3vh" : "10vh",
              color: "#8c3e7c",
            }}
          >
            Liquidations for Amazon!
          </h1>
          <h2
            style={{
              fontSize: props.isMobile ? "2.5vh" : "2vw",
              color: "#8c3e7c",
            }}
          >
            Finding the best liquidations deals for Amazon!
          </h2>
          <br />
          <p style={{ width: props.isMobile ? "90vw" : "38vw" }}>
            Liquidation sales are like the hidden treasures of the retail world.
            Within this category, we'll be guiding you to some of the best
            suppliers known for their liquidation sales. Not only will you get
            introduced to these suppliers, but you'll also find a range of
            products specifically sold at these impressive liquidation
            discounts. This is your chance to discover products at prices that
            will give you a particularly high ROI!
            <br />* <i>Amazon Returns</i> will be here as well!
          </p>
          <br />
          <p>Coming soon...</p>
          <br />
          <form
            action="https://server.nepeto.com/email"
            method="post"
            accept-charset="UTF-8"
          >
            Sign up for updates:
            <label className="is-block mb-4">
              <input
                required
                name="email"
                type="email"
                className="input"
                placeholder="Email address"
                style={{
                  width: props.isMobile ? "60vw" : "25vw",
                  marginTop: "5px",
                }}
              />
              <button
                type="submit"
                className="button px-4"
                style={{
                  backgroundColor: "#8c3e7c",
                  color: "white",
                  marginTop: "5px",
                }}
              >
                Subscribe
              </button>
              <div
                style={{
                  textIndent: "-99999px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  position: "absolute",
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="_gotcha"
                  tabindex="-1"
                  autocomplete="off"
                />
                <input
                  type="text"
                  name="what_is_it"
                  value={"liquid"}
                  tabindex="-1"
                  autocomplete="off"
                />
              </div>
            </label>
          </form>
        </div>
        <div className="column is 6">
          <img
            alt="liquidations"
            style={{
              height: !props.isMobile && "65vh",
              float: !props.isMobile && "right",
              marginRight: "7.5vw",
              marginTop: "7.5vh",
            }}
            src={"/assets/liqs.jpeg"}
          />
        </div>
      </div>
    </>
  );
}

export default Liquidations;
