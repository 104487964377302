function MessageBeforeLinkPopup(props) {
  return (
    <div
      id="modal-js-example"
      className={"modal" + (props.showMessageObj !== null ? " is-active" : "")}
      style={{zIndex: "999999999999999"}}
    >
      <div className="modal-background" onClick={() => {
                props.setShowMessageObj(null)
              }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "auto" }}
      >
        <div className="box" style={{textAlign: "center"}}>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            <strong>{props.showMessageObj.message.includes("ZIP CODE") ? "CHECK ZIP CODE" : "Hey :)"}</strong><br/><br/>
            <hr/>
            {props.showMessageObj.message.split("<br/>").map((msg) => 
            <><span style={{whiteSpace: "pre-line", fontWeight: msg.includes("main product page") && "700"}}>{msg.startsWith('\n') ? msg.substring(1) : msg}</span>
            {msg.includes("main product page") && <><br/><img className="variGif" src="https://i.ibb.co/x2cZJPV/Video-2024-05-14-1online-video-cutter-com-ezgif-com-video-to-gif-converter.gif" alt="variation gif" style={{margin: "14px", border: "1px solid black"}}/></>}
            {msg.includes("ZIP CODE") && <img className="variGif" src="/assets/oos.png" alt="Zip Code" style={{margin: "5%", width: "60%", border: "1px solid black"}}/>}
            <hr/></>)}
          </p>
          <br />
          <label class="checkbox" style={{fontSize: "110%"}}>
            <input type="checkbox" onChange={(e) => localStorage.setItem("showSuppMesssage" + (props.showMessageObj.source ? props.showMessageObj.source : ""), !e.target.checked)}/>
            &nbsp;Never show these messages again{props.showMessageObj.source && <> on <strong>{props.showMessageObj.source.charAt(0).toUpperCase() + props.showMessageObj.source.slice(1)}</strong></>}.
          </label>
          <br /><br /><br />
          <div className="columns is-mobile is-centered" style={{marginBottom: "1.5%"}}>
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setShowMessageObj(null)
              }}
            >
              Cancel
            </button>
            &emsp;
            <button
              className="button is-rounded is-success"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {props.showMessageObj.messageAction(props.showMessageObj.messageActionArg); props.setShowMessageObj(null)}}
            >
              Okay!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageBeforeLinkPopup;
