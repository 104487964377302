import React, { useState } from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const initialOptions = {
    clientId: "AXOglQsiyPeBp1GYe2Ehys3IeS7Od9AngwurkI54YnGXQuWYY9Pyl5aA2GwCpW7Oz1wRRF5JVWxTAh5Y",
    currency: "USD",
    intent: "capture",
};

const Checkout = ({price, completeAction, width="20vw", margin="auto"}) => {
    const onCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: price,
                    },
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        });
    }

    return (
        <div style={{ width: width, margin: margin }}>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                    }}
                    createOrder={(data, actions) => onCreateOrder(data, actions)}
                    onApprove={(data, actions) => completeAction()}
                    onCancel={() => ""}
                    onError={() => console.log("Please try again.")}
                />
            </PayPalScriptProvider>
        </div>
    );
}

export default Checkout;
