import BlogCardPreview from "./components/BlogCardPreview";
import "./Blog.css";
import { Helmet } from "react-helmet";

function Blogs(props) {
  return (
    <>
      <Helmet>
        <title>Nepeto - Blog</title>
        <meta
          name="description"
          content="Discover valuable insights on Amazon FBA and product sourcing with Nepeto's blog. Learn expert tips and strategies for e-commerce success. Start your journey to growth and profit today!"
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, e-commerce, success, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "200",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "2vh",
        }}
      >
        Nepeto Blog
      </h1>

      <div class="columns">
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Getting Started with Amazon FBA for Beginners"}
            img={"/blogs/blog7/preview.png"}
            writer={"Jon Hlyo"}
            minRead={13}
            href={"/blog/getting-started-with-amazon-fba-for-beginners"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"What is Amazon FBA"}
            img={"/blogs/blog1/preview.png"}
            writer={"Jon Hlyo"}
            minRead={3}
            href={"/blog/what-is-amazon-fba"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Starting Amazon FBA Business"}
            img={"/blogs/blog2/preview.png"}
            writer={"Jon Hlyo"}
            minRead={4}
            href={"/blog/starting-amazon-fba-business"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Amazon FBA Product Sourcing"}
            img={"/blogs/blog3/preview.png"}
            writer={"Jon Hlyo"}
            minRead={5}
            href={"/blog/amazon-fba-product-sourcing"}
          />
        </div>
      </div>
      <div class="columns">
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Listing Products and Pricing Strategies"}
            img={"/blogs/blog4/preview.png"}
            writer={"Jon Hlyo"}
            minRead={2}
            href={"/blog/listing-products-and-pricing-strategies"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={
              "Best Practices for Customer Relations and Feedback Management"
            }
            img={"/blogs/blog5/preview.png"}
            writer={"Jon Hlyo"}
            minRead={3}
            href={
              "/blog/best-practices-for-customer-relations-and-feedback-management"
            }
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Growth Strategies and Profit Maximization"}
            img={"/blogs/blog6/preview.png"}
            writer={"Jon Hlyo"}
            minRead={2}
            href={"/blog/growth-strategies-and-profit-maximization"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"How to Skyrocket your Sales as an Amazon FBA Seller"}
            img={"/blogs/blog8/preview.png"}
            writer={"Jon Hlyo"}
            minRead={12}
            href={"/blog/how-to-skyrocket-your-sales-as-an-amazon-fba-seller"}
          />
        </div>
      </div>

      <div class="columns">
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"The Best Products for Amazon Arbitrage"}
            img={"/blogs/blog9/preview.png"}
            writer={"Jon Hlyo"}
            minRead={11}
            href={"/blog/the-best-products-for-amazon-arbitrage"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"How to Find Amazon Arbitrage Products"}
            img={"/blogs/blog10/preview.png"}
            writer={"Jon Hlyo"}
            minRead={13}
            href={"/blog/how-to-find-amazon-arbitrage-products"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Guide to Elevate Your Profit Game"}
            img={"/blogs/blog11/preview.png"}
            writer={"Jon Hlyo"}
            minRead={17}
            href={"/blog/amazon-arbitrage-boost-profit"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"How to Find Amazon Arbitrage Products in 2023 - Part 2"}
            img={"/blogs/blog12/preview.png"}
            writer={"Jon Hlyo"}
            minRead={19}
            href={"/blog/how-to-find-amazon-arbitrage-products-in-2023-part-2"}
          />
        </div>
      </div>

      <div class="columns">
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Unravelling the Mystery: Exploring the World of FBA Prep"}
            img={"/blogs/blog13/preview.png"}
            writer={"Jon Hlyo"}
            minRead={11}
            href={"/blog/exploring-the-world-of-fba-prep"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Top Nepeto Alternatives for 2023"}
            img={"/blogs/blog14/preview.png"}
            writer={"Jon Hlyo"}
            minRead={9}
            href={"/blog/top-nepeto-alternatives-for-2023"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"What is Nepeto"}
            img={"/blogs/blog15/preview.png"}
            writer={"Jon Hlyo"}
            minRead={10}
            href={"/blog/what-is-nepeto"}
          />
        </div>
        <a
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
          href="https://jordiob.com/amazon-tools/nepeto/"
        >
          <BlogCardPreview
            title={"Jordi Ordonez's Review"}
            img={"/assets/jordi.jpeg"}
            writer={"Jordi Ordonez"}
            minRead={7}
          />
        </a>
      </div>
      
      <div class="columns">
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Why FBA Prep Is Crucial"}
            img={"/blogs/blog16/preview.png"}
            writer={"Jon Hlyo"}
            minRead={13}
            href={"/blog/why-fba-prep-is-crucial"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"What Are OOS Products"}
            img={"/blogs/blog17/preview.png"}
            writer={"Jon Hlyo"}
            minRead={9}
            href={"/blog/what-are-oos-products"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Success In Q4 With Nepeto"}
            img={"/blogs/blog18/preview.png"}
            writer={"Jon Hlyo"}
            minRead={16}
            href={"/blog/success-in-q4-with-nepeto"}
          />
        </div>
        <div
          class="column is-3"
          style={{ padding: props.isMobile ? "10%" : "2.5%" }}
        >
          <BlogCardPreview
            title={"Partner - Seller Assistant App"}
            img={"/blogs/blog19/preview.png"}
            writer={"Seller Assistant App"}
            minRead={9}
            href={"/blog/partner-seller-assistant-app"}
          />
        </div>
      </div>
    </>
  );
}

export default Blogs;
