import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

const FavoritesIcon = (props) => {
  const [fav, setFav] = useState(props.favorite || false);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const changeFav = async (addFav) => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently();
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json; charset=utf8",
        "Content-Type": "application/json; charset=utf8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        url: props.url,
        fav: (+addFav),
        oos: (+props.oos)
      }),
    };
    fetch("https://server.nepeto.com/add_to_favorite", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data === "No permissions") {
          alert("You need to subscribe to Nepeto to use the favorites feature.");
        } else {
          setFav(addFav)
        }
        setLoading(false)
      }).catch((error) => {
        alert("Error! Please try again.");
        setLoading(false)
      });
  };

  return (
    <span>
      {!loading ?<i
        style={{
          cursor: "pointer",
          fontSize: props.isMobile ? "3.7vh" : "1.5vw",
          paddingTop: "10%",
          marginLeft: "10%",
        }}
        onClick={() => {
          changeFav(!fav)
        }}
        class={"fa fa-heart" + (!fav ? "-o" : "")}
        aria-hidden="true"
      ></i> : <i
      style={{
        fontSize: props.isMobile ? "3.7vh" : "1.5vw",
        paddingTop: "10%",
        marginLeft: "10%",
      }}
      className="fa fa-spinner fa-spin"
      aria-hidden="true"
    ></i>}
    </span>
  );
};

export default FavoritesIcon;
