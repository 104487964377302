import React from "react";
import {
  PieChart, Pie, Cell, ResponsiveContainer, LabelList, Tooltip,
} from "recharts";

const COLORS = ['#8B3C7E', '#A3A0DF', '#5388FD', '#00C49F', '#FFBB28', '#FF8042',
                '#618271', '#c6c55d', '#ea414c', '#4ac2b7', '#5da01e', '#edde43',
                '#3d2a64', '#a8f41a', '#402c9c', '#198248', '#d79a81', '#c7dd02',
                '#55d3e9', '#73444f', '#993e98', '#c83cec', '#4f6c43', '#b4caf0',
                '#b7e1dc', '#e372e1', '#530c25', '#ded331', '#6143ff', '#02ea89'];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{backgroundColor: "#E3D0DF", fontSize: "14px", padding: "5px",color:"#8B3C7E"}}>
        <p style={{fontWeight: "900"}}>{`${payload[0].name.replace("ATVPDKIKX0DER", "Amazon").replace("A2R2RITDJNW1Q6", "Amazon")}`}</p>
        <p><strong style={{color:"#8B3C7E"}}>{payload[0].value.toFixed(2)}%</strong> BB Winner</p>
      </div>
    );
  }

  return null;
};

export default function PieGraph(props) {
  return (
    <ResponsiveContainer width="100%" height="50%" className="pieChart">
        <PieChart>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={props.data}
          outerRadius={"72%"}
          fill="#08B8A1"
          label={(entry) => {return entry.name.replace("ATVPDKIKX0DER", "Amazon").replace("A2R2RITDJNW1Q6", "Amazon")}}
          onClick={(entry) => window.open("https://www.amazon.com/sp?seller=" + entry.name)}
          fontSize={14}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <LabelList
            dataKey={(entry) => {return entry.value.toFixed(1) + "%"}}
            position="right"
            style={{ fontSize: "14px", fontWeight: "0" }}
          />
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} style={{cursor: "pointer"}}/>
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />}/>
        </PieChart>
      </ResponsiveContainer>
  );
}