import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Whop(props) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Check if the 'affiliate' parameter exists in the query string
    if (urlParams.has("code")) {
      localStorage.setItem("affiliate", "code-" + urlParams.get("code"));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Nepeto - Whop</title>
        <meta
          name="description"
          content="Welcome to Nepeto! Enjoy your subscription!"
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, nepeto, whop, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div style={{ height: "70vh", marginTop: "10vh" }}>
        <div class="modal-content">
          <div class="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "200%" }}>
              Congratulations for joining Nepeto!
            </p>
            <br />
            <p>
              Explore our database of thousands of profitable products by
              visiting one of the supplier types pages: Retail, Distributors,
              Wholesale, or Out-of-Stock Products!<br/>
              If you have any questions, please do not hesitate to contact us :)
            </p>
            <br />
            <br />
            {!isLoading && !isAuthenticated ? (
              <button
                className="button is-rounded"
                style={{
                  backgroundColor: "#32CD32",
                  color: "#ffffff",
                  fontSize: props.fontSize,
                }}
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: "signup",
                    },
                    appState: { returnTo: "/" },
                  })
                }
              >
                Sign up to Start!
              </button>
            ) : (
              <button
                class="button is-success"
                onClick={() => (window.location.href = "/")}
              >
                Go!
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Whop;
