import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

function MismatchUpdate(props) {
  const {getAccessTokenSilently,} = useAuth0();
  const [email, setEmail] = useState("");
  const [mm, setMM] = useState(0);
  const [loading, setLoading] = useState(false);

  const updateMM = async () => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/update_mismatches/" + email + "/" + mm + "/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then((response) => response.json())
    .then((data) => {
      if(data === "Succeed!") {
        setEmail("Succeed!")
        setMM(0)
        setLoading(false)
    }});
  };

  return (
    <div className="container" style={{textAlign: "center", height: "80vh"}}>
      <h1
          style={{
            fontSize: props.isMobile ? "5vh" : "4vw",
            color: "#8c3e7c",
            marginTop: "3vh",
            textAlign: props.isMobile && "center"
          }}
        >
          Update Mismatches
        </h1>
      <br/><br/><br/>
      <input className="input" type="text" placeholder="User's Email" style={{width:"30%"}} onChange={(e) => setEmail(e.target.value)}/>
      <input className="input" type="number" placeholder="New Mismatches Found" style={{width:"15%"}} min={0} onChange={(e) => setMM(e.target.value)}/>
      <br/><br/><br/><p>Email: <strong><u>{email}</u></strong> | New Mismatches: <strong><u>{mm}</u></strong></p><br/>
      {loading ? "LOADING..." : <button class="button is-success" onClick={() => updateMM()}>Submit</button>}
    </div>
  );
}

export default MismatchUpdate;
