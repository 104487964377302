export const warningSuppliers = ["nat-procurement", "natprocurement", "packdistribution", "mazaldistribution", "honeysplace", "kaywholesaledeals", "utnwholesale", "888lots", "gsp", "kntradingllc", "tjsgroupllc", "topdawg", "sctopdeal", "northandsouthwholesalers"];

function NotDirectLinkPopup(props) {
  return (
    <div
      id="modal-js-example"
      className={"modal" + (props.linkUrl !== "" ? " is-active" : "")}
    >
      <div className="modal-background" onClick={() => {
                props.setLinkUrl("");
              }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "auto" }}
      >
        <div className="box" style={{textAlign: "center"}}>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            <strong>Hey :)</strong><br/>
            Please <strong>copy</strong> the next text and <strong>search</strong> for it on the website.<br/>
          </p>
          <br />
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            <span style={{fontSize: props.isMobile ? "2.5vh" : "1.7vw", color: "rgb(100,20,90)"}}>
                {props.linkUrl.split("?").length === 1 ? 
                props.linkUrl.split("/")[props.linkUrl.split("/").length-1] : 
                props.linkUrl.split("?")[props.linkUrl.split("?").length-1].includes("search=") ?
                props.linkUrl.split("?")[props.linkUrl.split("?").length-1].split("search=")[1] :
                props.linkUrl.split("?")[props.linkUrl.split("?").length-1]}
            </span>
          </p>
          <br />
          <br />
          <div className="columns is-mobile is-centered">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setLinkUrl("");
              }}
            >
              Cancel
            </button>
            &emsp;
            <button
              className="button is-rounded is-success"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => window.open("https://" + props.linkUrl.split("//")[1].split("/")[0])}
            >
              Go to the Website
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotDirectLinkPopup;
