import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PieGraph from "../Products/Components/PieGraph";
import CardSwipper from "../Products/Mobile/CardSwipper";
import ProductsTable from "./StorefrontProductsTable";
import StorefrontFilterBar from "./StorefrontFilterBar";
import Checkout from "../../components/Checkout";

function StorefrontScanner(props) {
  const [sellerData, setSellerData] = useState({});
  const [sellerId, setSellerId] = useState("");
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [errorMsg, setErrorMsg] = useState("");
  const [newAsins, setNewAsins] = useState("");
  const [pieChartView, setPieChartView] = useState(false);
  const [sortASC, setSortASC] = useState(false);
  const today = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '');
  const clickCount = parseInt(localStorage.getItem('clickCount' + today)) || 0;
  const CATEGORY_BSR_COUNT = {
    'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515,
    'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843,
    'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162,
    'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647,
    'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328,
    'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340,
    'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048,
    'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515,
    'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789,
    'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893,
    'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107,
    'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894,
    "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127,
    "Collectible Coins": 210648, "Camera & Photo": 4021127
  }

  function getHistoryScans() {
    let items = {};
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.startsWith("SFS_")) {
        let value = localStorage.getItem(key);
        items[key] = value;
      }
    }
    return items;
  }

  const histScans = getHistoryScans();

  function removeDuplicates(list) {
    var uniqueObjects = {};
    var result = [];

    list.forEach(function (obj) {
      var key = obj.asin + obj.source_name;

      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        result.push(obj);
      }
    });

    return result;
  }

  useEffect(() => {
    function calculateROI(item, settings) {
      let logisticsCost = parseFloat(localStorage.getItem("costPrep") || 0);
      let price = item.price * (1 - ((settings.priceCoupon || 0) / 100));
      let azPrice = item.az_price;
      let fbaCost = settings.isFBM ? 0 : item.fba_cost;
      let sellingOnAzFees = item.selling_on_az_fees;

      return (azPrice - price - fbaCost - logisticsCost - sellingOnAzFees) / (price + logisticsCost);
    }

    function filterData(data, filters) {
      return data.filter(item => {
        if (parseInt(filters.mustBB || "0") !== 0 && item.az_offers && !item.az_offers.includes('"is_buy_box_winner": true')) {
          return false;
        }

        // mustNotBB
        if (parseInt(filters.mustNotBB || "0") !== 0 && item.az_offers && item.az_offers.includes('"is_buy_box_winner": true')) {
          return false;
        }

        // showFeesNA
        if (parseInt(filters.showFeesNA || "0") === 0 && (item.fba_cost === 0 || item.selling_on_az_fees === 0)) {
          return false;
        }

        // amazonNotSeller
        if (parseInt(filters.amazonNotSeller || "0") !== 0) {
          if (item.az_offers && (item.az_offers.includes("ATVPDKIKX0DER") || item.az_offers.includes("A2R2RITDJNW1Q6"))) {
            return false;
          }
        }

        // top_bsr
        if (filters.top_bsr && (filters.top_bsr[0] !== -1 || filters.top_bsr[1] !== -1)) {
          let categoryCount = CATEGORY_BSR_COUNT[item.category] || 10000000;
          let salesRankPercentage = (item.sales_rank / categoryCount) * 100;

          if (filters.top_bsr[0] !== -1 && salesRankPercentage < filters.top_bsr[0]) {
            return false;
          }

          if (filters.top_bsr[1] !== -1 && salesRankPercentage > filters.top_bsr[1]) {
            return false;
          }
        }

        // profit
        if (filters.profit && (filters.profit[0] !== -1 || filters.profit[1] !== -1)) {
          let profitValue = item.az_price - (item.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : item.fba_cost) - item.selling_on_az_fees - parseFloat(localStorage.getItem("costPrep") || 0);

          if (filters.profit[0] !== -1 && profitValue < filters.profit[0]) {
            return false;
          }

          if (filters.profit[1] !== -1 && profitValue > filters.profit[1]) {
            return false;
          }
        }

        // search
        if (filters.search && filters.search !== "") {
          let searchLower = filters.search.toLowerCase();
          if (!item.title.toLowerCase().includes(searchLower) && !item.asin.includes(filters.search) && !item.url.toLowerCase().includes(searchLower)) {
            return false;
          }
        }

        // category
        if (filters.category && filters.category !== "Category" && !filters.category.includes(item.category)) {
          return false;
        }

        // qty, moq, bsr
        for (let filterAttr of ["qty", "moq", "bsr"]) {
          if (filters[filterAttr] && (filters[filterAttr][0] !== -1 || filters[filterAttr][1] !== -1)) {
            let value = item[filterAttr === "bsr" ? "sales_rank" : filterAttr];

            if (filters[filterAttr][0] !== -1 && value < filters[filterAttr][0]) {
              return false;
            }

            if (filters[filterAttr][1] !== -1 && value > filters[filterAttr][1]) {
              return false;
            }
          }
        }

        // price
        if (filters.price && (filters.price[0] !== -1 || filters.price[1] !== -1)) {
          let price = item.price * (1 - ((filters.priceCoupon || 0) / 100));

          if (filters.price[0] !== -1 && price < filters.price[0]) {
            return false;
          }

          if (filters.price[1] !== -1 && price > filters.price[1]) {
            return false;
          }
        }

        // roi
        if (filters.roi && (filters.roi[0] !== -1 || filters.roi[1] !== -1)) {
          let roi = calculateROI(item, filters);

          if (filters.roi[0] !== -1 && roi < (filters.roi[0] / 100)) {
            return false;
          }

          if (filters.roi[1] !== -1 && roi > (filters.roi[1] / 100)) {
            return false;
          }
        }

        // offers
        if (filters.offers && (filters.offers[0] !== -1 || filters.offers[1] !== -1)) {
          let offersCount = (item.az_offers.match(/{/g) || []).length;

          if (filters.offers[0] !== -1 && offersCount < filters.offers[0]) {
            return false;
          }

          if (filters.offers[1] !== -1 && offersCount > filters.offers[1]) {
            return false;
          }
        }

        // fbaoffers
        if (filters.fbaoffers && (filters.fbaoffers[0] !== -1 || filters.fbaoffers[1] !== -1)) {
          let fbaOffersCount = (item.az_offers.match(/"is_fba": true/g) || []).length;

          if (filters.fbaoffers[0] !== -1 && fbaOffersCount < filters.fbaoffers[0]) {
            return false;
          }

          if (filters.fbaoffers[1] !== -1 && fbaOffersCount > filters.fbaoffers[1]) {
            return false;
          }
        }

        // fbmoffers
        if (filters.fbmoffers && (filters.fbmoffers[0] !== -1 || filters.fbmoffers[1] !== -1)) {
          let fbmOffersCount = (item.az_offers.match(/"is_fba": false/g) || []).length;

          if (filters.fbmoffers[0] !== -1 && fbmOffersCount < filters.fbmoffers[0]) {
            return false;
          }

          if (filters.fbmoffers[1] !== -1 && fbmOffersCount > filters.fbmoffers[1]) {
            return false;
          }
        }

        // sourceName
        if (filters.sourceName && filters.sourceName !== "Supplier Name" && !filters.sourceName.includes(item.source_name)) {
          return false;
        }

        // supplierTopMinPurchase
        if (filters.supplierTopMinPurchase && parseFloat(filters.supplierTopMinPurchase) < parseFloat(item.source.minimum_order)) {
          return false;
        }

        // supplierAimAmazon
        if (filters.supplierAimAmazon && item.upc !== null) {
          return false;
        }

        return true;
      });
    }

    const sortFunction = (a, b) => {
      if (filters.sortBy) {
        let getKey = (n, key) => {
          if (filters.sortBy[0] === "roi") {
            return parseFloat(calculateROI(n, filters))
          }
          if (filters.sortBy[0] === "profit") {
            return parseFloat(n.az_price - (n.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : n.fba_cost) - n.selling_on_az_fees - parseFloat(localStorage.getItem("costPrep") || 0))
          }
          return parseFloat(n[key])
        }
        if (filters.sortBy[1]) {
          return getKey(a, filters.sortBy[0]) - getKey(b, filters.sortBy[0])
        } else {
          return getKey(b, filters.sortBy[0]) - getKey(a, filters.sortBy[0])
        }
      } else {
        return 1
      }
    }

    setFilteredData(filterData(data, filters).sort((a, b) => sortFunction(a, b)));
  }, [filters])

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setSellerId(urlParams.has("sellerId") ? urlParams.get('sellerId') : "")

    if (urlParams.has("clrSusg")) {
      localStorage.setItem('clickCount' + today, 0)
      window.location.href = "/storefront-scanner"
    }
  }, [today])

  useEffect(() => {
    if (sellerId && data && isProductsLoading === false) {
      const oldAsins = localStorage.getItem("SFS_" + sellerId) ? localStorage.getItem("SFS_" + sellerId).split(",") : ""
      const newAsinsNew = data.map(p => p.asin.split("/")[p.asin.split("/").length - 1].replace("B0", ""))
      setNewAsins(newAsinsNew.filter(x => !oldAsins.includes(x)).join(","))
      localStorage.setItem("SFS_" + sellerId, data.map(p => p.asin.split("/")[p.asin.split("/").length - 1].replace("B0", "")).join(","))
    }
  }, [data])

  const loadSellerData = async (e) => {
    const lastClickTimestamp = localStorage.getItem('lastClickTimestamp') || -1;
    const minutesSinceLastClick = (Date.now() - parseInt(lastClickTimestamp)) / (1000 * 60);
    if (minutesSinceLastClick < 2 && lastClickTimestamp !== -1) {
      setErrorMsg(`Please wait ${((2 - minutesSinceLastClick) * 60).toFixed(0)} seconds before scanning again!`);
      return;
    }
    if ((props.activePlanLevel === "Master Plan" && clickCount >= 30) ||
      (props.activePlanLevel === "Pro Plan" && clickCount >= 15) ||
      (props.activePlanLevel === "Advanced Plan" && clickCount >= 6) ||
      (props.activePlanLevel === "Starter Plan" && clickCount >= 3)) {
      setErrorMsg(`You've reached your daily scan limit.`);
      return;
    }

    localStorage.setItem('lastClickTimestamp', Date.now());

    e.target.style.cursor = "wait"
    e.target.disabled = true
    e.target.innerText = "SCANNING..."
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/get_seller_information/" + sellerId + "/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== "No permissions") {
          setSellerData(data);
          localStorage.setItem('clickCount' + today, clickCount + 1);
        } else {
          alert("You must be subscribed to use the Storefront Scanner!")
        }
      }).catch((error) => {
        alert("Invalid Seller ID. Please try again with a valid one. Click on 'Where can I find the Seller's ID?' for a guide.")
        e.target.style.cursor = "pointer"
        e.target.disabled = false
        e.target.innerText = "SCAN"
      })
  };

  useEffect(() => {
    const updateExists = async (seller_asins) => {
      const accessToken = await getAccessTokenSilently();
      await fetch("https://server.nepeto.com/asin_exists_array_storefront/", {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(seller_asins.map((asin) => "https://www.amazon.com/gp/product/" + asin))
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0 && !props.isMobile && !(new Date(data[0].title.split("Last Update: ")[1]).toDateString().includes("Invalid"))) {
            let filteredData = removeDuplicates(data.filter(product => (((new Date() - new Date(product.title.split("Last Update: ")[1])) / 1000 / 60 / 60) <= 72)))
            if (filteredData.length > 0) {
              let filteredDataWithHide = filteredData.filter(prod => (localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU") === null || (new Date() > localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU"))))
              if (filteredDataWithHide.length > 0) {
                setData((prevData) => [...prevData, ...filteredDataWithHide]);
              }
            }
          } else {
            setData((prevData) => [...prevData, ...data])
          }
        });
    }

    const updateExistsLoop = async () => {
      function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      }

      for (const chunk of chunkArray(sellerData['seller_asins'], 3000)) {
        await updateExists(chunk)
      }

      setIsProductsLoading(false);
    }

    if (sellerData.hasOwnProperty("seller_asins") && isProductsLoading === null) {
      setIsProductsLoading(true);
      updateExistsLoop();
    }
    if (data.length === 0 && isProductsLoading === false) {
      localStorage.setItem('clickCount' + today, clickCount - 1);
    }
  }, [getAccessTokenSilently, sellerData, clickCount, today, isProductsLoading, data.length, props.isMobile]);

  const Stars = ({ percentage }) => {
    const starsFilled = Math.round(percentage / 20);
    const starsEmpty = 5 - starsFilled;
    const starsArray = [];

    for (let i = 0; i < starsFilled; i++) {
      starsArray.push(<i key={i} className="fa fa-star" aria-hidden="true"></i>);
    }

    for (let i = 0; i < starsEmpty; i++) {
      starsArray.push(<i key={starsFilled + i} className="fa fa-star-o" aria-hidden="true"></i>);
    }

    return <>{starsArray}</>;
  };

  if (isLoading) {
    return <div style={{ height: "88vh", textAlign: "center" }}>
      <i
        className="fa fa-spinner fa-spin"
        aria-hidden="true"
        style={{ marginTop: "20vh", fontSize: "250%" }}
      ></i>
    </div>
  }

  if (!isLoading && !isAuthenticated) {
    return <div className="container">
      <Helmet>
        <title>Nepeto - Storefront Scanner</title>
        <meta
          name="description"
          content="Scan Amazon seller storefronts and sell their items using Nepeto."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, storefront, scanner, storefront scanner, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <br /><br /><br /><br />
      <h1 style={{ height: "60vh", textAlign: "center", fontSize: "200%" }}>
        Please log in to your account to use the Storefront Scanner :)
      </h1>
    </div>
  }

  if (!isLoading && isAuthenticated) {
    return <div>
      <Helmet>
        <title>Nepeto - Storefront Scanner</title>
        <meta
          name="description"
          content="Scan Amazon seller storefronts and sell their items using Nepeto."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, storefront, scanner, storefront scanner, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <br />
      <h1
        style={{
          fontSize: props.isMobile ? "3.5vh" : "2.5vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "2vh",
        }}
      >
        Storefront Scanner
      </h1><br />
      {!sellerData.hasOwnProperty("seller_name") ? <h1 style={{ height: "95vh", textAlign: "center", fontSize: props.isMobile ? "2.5vh" : "1.5vw" }}>
        Please enter the Amazon Seller's Id:<br />
        <p style={{ color: "SlateBlue", fontSize: "75%", textDecoration: "underline", cursor: "pointer", width: props.isMobile ? "80vw" : "20vw", margin: "auto" }} onClick={() => window.open("/assets/sellerid.png")}>Where can I find the Seller's Id?</p>
        <p style={{ fontSize: "75%", marginTop: "2vh" }}>{clickCount}/{props.activePlanLevel === "Master Plan" ? "30" : props.activePlanLevel === "Pro Plan" ? "15" : props.activePlanLevel === "Advanced Plan" ? "6" : "3"} Scans used today</p><br />
        <input className="input" placeholder="Seller ID" value={sellerId} onChange={(e) => setSellerId(e.target.value)} style={{ width: "20%", borderColor: "gray" }} />
        <button className="button is-success" disabled={sellerId.length === 0} onClick={(e) => { loadSellerData(e) }}>SCAN&nbsp;<i class="fa fa-search" aria-hidden="true"></i></button>
        <p style={{ color: "red", fontSize: "80%" }}>{errorMsg.includes("daily scan limit") ? <>{errorMsg}<br />
          <a href="/pricing" style={{ color: "Slateblue" }}>Upgrade your plan</a> or reset your daily usage now for only $9!<br /><br /><Checkout price="9.00" completeAction={() => window.location.href = "https://nepeto.com/storefront-scanner?clrSusg=true"} /></> : errorMsg}</p>
        <br /><br />
        <h1>Scanned Seller IDs</h1>
        <table
          class="table is-bordered products-table"
          style={{
            width: "20vw",
            display: "block",
            height: "70%",
            fontSize: "80%",
            overflowY: "scroll",
          }}
        >
          <thead>
            <tr>
              <th>Seller ID</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(histScans).map((perc, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: "70%" }}>
                    {perc[0].replace("SFS_", "")}&emsp;
                    <i class="fa fa-arrow-up" aria-hidden="true" onClick={() => {
                      setSellerId(perc[0].replace("SFS_", "")); window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                    }} style={{ borderRadius: "30px", border: "3px solid purple", padding: "1% 1.3%", cursor: "pointer" }}></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </h1> : <div>
        <h1 style={{ textAlign: "center", fontSize: props.isMobile ? "2.5vh" : "1.5vw" }}>Seller: <strong>{sellerData['seller_name']}</strong></h1>
        <p style={{ fontSize: "100%", color: "orange", textAlign: "center" }}>
          <Stars percentage={sellerData['seller_rating']} />
          {" "}({sellerData['seller_reviews']} ratings last 12 months)
        </p><br />
        {!props.isMobile && <div class="column center" style={{ marginTop: "-2vh", marginBottom: "-2vh" }}>
          <span
            style={{
              fontSize: "1.1rem",
              color: "rgb(140, 62, 124)",
              fontWeight: "bold",
              padding: "1rem",
            }}
          >
            Pie Chart View
          </span>{" "}
          <label class="switch">
            <input type="checkbox" defaultChecked onClick={() => setPieChartView(!pieChartView)} />
            <span class="slider round"></span>
          </label>
          <span
            style={{
              fontSize: "1.1rem",
              color: "rgb(140, 62, 124)",
              fontWeight: "bold",
              padding: "1rem",
            }}
          >
            Table View
          </span>{" "}
        </div>}
        {pieChartView ?
          <div style={{ height: "500px", width: "78vw", display: "flex", margin: "auto", marginTop: "5vh", marginBottom: "-15vh" }}>
            <strong>Seller's Listings Categories Distribution:</strong>
            <PieGraph data={sellerData["categories_percentages"]} />
            <strong>Seller's Listings Brands Distribution:</strong>
            <PieGraph data={sellerData["brands_percentages"]} />
          </div> :
          <div style={{ height: !props.isMobile ? "300px" : "0px", width: "78vw", margin: "auto", marginTop: !props.isMobile ? "5vh" : "0px", marginBottom: "32vh", display: "flex", flexDirection: props.isMobile ? "column" : "row" }}>
            {!props.isMobile && <strong>Seller's Listings<br />Categories Distribution:</strong>}
            {!props.isMobile && <table
              class="table is-bordered products-table"
              style={{
                width: "25%",
                display: "block",
                textAlign: "left",
                height: "400px",
                fontSize: "100%",
                overflow: "scroll",
                border: "1px solid black"
              }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Percentages</th>
                </tr>
              </thead>
              <tbody>
                {sellerData["categories_percentages"].map((perc, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ verticalAlign: "middle" }}>
                        {perc.name}
                      </td>
                      <td
                        style={{
                          verticalAlign: "middle",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {perc.value.toFixed(2)}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>}
            {!props.isMobile && <strong>Seller's Listings<br />Brands Distribution:</strong>}
            {!props.isMobile && <table
              class="table is-bordered products-table"
              style={{
                width: "22.5%",
                display: "block",
                textAlign: "left",
                height: "400px",
                fontSize: "100%",
                overflow: "scroll",
                border: "1px solid black"
              }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Percentages</th>
                </tr>
              </thead>
              <tbody>
                {sellerData["brands_percentages"].map((perc, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ verticalAlign: "middle" }}>
                        {perc.name}
                      </td>
                      <td
                        style={{
                          verticalAlign: "middle",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {perc.value.toFixed(2)}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>}
          </div>}
        <h1 style={{ textAlign: "center", fontSize: "150%", color: "#8c3e7c", marginBottom: !props.isMobile ? "10vh" : "3vh", marginTop: !props.isMobile ? "-5vh" : "-30vh" }}>Deals Found From The Seller's Storefront<br />
          {filteredData.length === 0 && filters && !props.isMobile && <span style={{ fontSize: "60%", color: "red" }}>No products matched the filters. Displaying all results.</span>}</h1>

        {!props.isMobile && <StorefrontFilterBar data={data} setFilters={setFilters} filters={filters} activePlanLevel={props.activePlanLevel} showAdv={true} suppliersList={Array.from(new Set(data.map(prod => prod.source_name)))} />}

        {props.isMobile ? (
          <CardSwipper data={filteredData.length > 0 ? filteredData : data} hideCredits={props.hideCredits} isMobile={props.isMobile} />
        ) : <ProductsTable
          data={filteredData.length > 0 ? filteredData : data}
          setData={setData}
          isMobile={props.isMobile}
          supplierType={"storefront"}
          hideCredits={props.hideCredits}
          showAdv={true}
          isProductsLoading={isProductsLoading}
          filters={filters}
          setSortASC={setSortASC}
          sortASC={sortASC}
          setFilters={setFilters}
          newAsins={newAsins}
          sellerId={props.sellerId}
          refreshToken={props.refreshToken}
        />
        }
      </div>}
      <br /><br /><br /><br />
    </div>
  }
}

export default StorefrontScanner;
