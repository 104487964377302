import { useEffect, useState } from "react";

function ModalForSettings(props) {
  const [minValue, setMinValue] = useState(-1);
  const [maxValue, setMaxValue] = useState(-1);

  useEffect(() => {
    if (props.filters[[props.modalSetting]]) {
      setMinValue(props.filters[[props.modalSetting]][0]);
      setMaxValue(props.filters[[props.modalSetting]][1]);
    }
  }, [props.modalSetting, props.filters]);

  return (
    <div
      id="modal-js-example"
      className={"modal" + (props.modalSetting.length > 1 ? " is-active" : "")}
    >
      <div className="modal-background" onClick={() => {
        props.setModalSetting("");
        setMaxValue(-1);
        setMinValue(-1);
      }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "24vw" }}
      >
        <div className="box">
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            Set {props.modalSetting !== "top_bsr" ? "Min &" : ""} Max Value{props.modalSetting !== "top_bsr" ? "s" : ""} For{" "}
            <strong>
              {props.modalSetting.replaceAll("_", " ").toUpperCase()}
            </strong>
            {props.modalSetting.includes("offers") && " count"}
            {(props.modalSetting === "roi" || props.modalSetting === "top_bsr") && " (%)"}
          </p>
          <br />
          <div className="columns is-mobile">
            {props.modalSetting !== "top_bsr" && <input
              className="input searchproduct column is-5"
              type="number"
              style={{
                backgroundColor: "rgb(179,127,170)",
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              value={minValue !== -1 ? minValue : ""}
              onChange={(e) => {
                setMinValue(e.target.value);
              }}
              placeholder="Enter Minimum"
            />}
            {props.modalSetting !== "top_bsr" ? <div className="column is-2" /> : <div className="column is-3" style={{ marginRight: "5%" }} />}
            <input
              className="input searchproduct column is-5"
              type="number"
              style={{
                backgroundColor: "rgb(179,127,170)",
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              value={maxValue !== -1 ? maxValue : ""}
              onChange={(e) => {
                if (props.modalSetting === "offers") {
                  setMaxValue(e.target.value > 20 ? 20 : e.target.value);
                } else {
                  setMaxValue(e.target.value);
                }

                if (props.modalSetting === "top_bsr" && e.target.value > 50) {
                  alert("Please note that it is a top BSR in percentages, not a sales rank number. Please select the option 'Sales Rank #' in the previous dialog.")
                }
              }}
              placeholder={(props.modalSetting === "top_bsr" ? "Top %" : "Enter Maximum")}
            />
          </div>
          <br />
          <div className="columns is-mobile is-centered">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setModalSetting("");
                setMaxValue(-1);
                setMinValue(-1);
              }}
            >
              Cancel
            </button>
            &emsp;
            <button
              className="button is-rounded is-success"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setFilters((filters) => ({
                  ...filters,
                  [props.modalSetting]: [
                    minValue !== "" ? parseFloat(minValue) : -1,
                    maxValue !== "" ? parseFloat(maxValue) : -1,
                  ],
                }));
                setMaxValue(-1);
                setMinValue(-1);
                props.setModalSetting("");
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForSettings;
