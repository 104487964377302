import React, { useState, useEffect } from "react";
import ModalForHide from "../../Products/Components/ModalForHide";
import DynamicKeepaGraphPopup from "../../../components/DynamicKeepaGraphPopup";
import A2AAdvancedDataModal from "./A2AAdvancedDataModal";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../../logo2.svg";

export default function A2AProductsTable(props) {
  const [bsr_state, setBsr] = useState(
    localStorage.getItem("DKGbsr") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbsr"))
  );
  const [az_state, setAz] = useState(
    localStorage.getItem("DKGaz") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGaz"))
  );
  const [new_state, setNew] = useState(
    localStorage.getItem("DKGnew") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGnew"))
  );
  const [bb_state, setBb] = useState(
    localStorage.getItem("DKGbb") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbb"))
  );
  const [fba_state, setFba] = useState(
    localStorage.getItem("DKGfba") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGfba"))
  );
  const [range_state, setRange] = useState(
    localStorage.getItem("DKGrange") === null
      ? 90
      : localStorage.getItem("DKGrange")
  );
  const [hideModalProduct, setHideModalProduct] = useState("");
  const [advancedDataProduct, setAdvancedDataProduct] = useState(null);
  const [searchModal, setSearchModal] = useState(null);
  const [DKGasin, setDKGAsin] = useState(null);
  const [gatedStatus, setGatedStatus] = useState({});
  const [loginWAZ, setLoginWAZ] = useState(false);
  const [headerStyle, setHeaderStyle] = useState({});
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();

  const handleScroll = () => {
    try {
      const table = document.getElementById('tableTable');
      const tableRect = table.getBoundingClientRect();

      if (tableRect.top <= 0) {
        // The header is out of view (or at the top of the viewport)
        setHeaderStyle({
          position: 'relative',
          top: -tableRect.top + "px",
          zIndex: 9,
        });
      } else {
        setHeaderStyle({});
      }
    } catch {}
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkGated = async (asin) => {
      if (props.sellerId) {
        await fetch(`https://server.nepeto.com/mobile_app_check_restrictions/`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sellerId: props.sellerId,
            asin: asin,
            refreshToken: props.refreshToken,
          })
        }).then(response => response.json())
          .then(data => {
            setGatedStatus((prevGS) => ({
              ...prevGS,
              [asin]: data,
            }));
          })
      }
    };

    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && props.sellerId !== "none" && props.sellerId !== "") {
      props.data.forEach(prd => { checkGated(prd.asin.split("/")[prd.asin.split("/").length - 1]) });
    }
    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && localStorage.getItem("azlog_pop_first") !== "1") {
      setLoginWAZ(true);
      localStorage.setItem("azlog_pop_first", "1");
    }
  }, [isLoading, isAuthenticated, props.data]);

  return (
    <>
      <div className="table-container products-table-container">
        <table id="tableTable" className="table is-hoverable products-table">
          <thead style={headerStyle}>
            <tr style={{ verticalAlign: "middle" }}>
              <th style={{ verticalAlign: "middle" }}>Product</th>
              <th style={{ verticalAlign: "middle" }}>Category</th>
              <th style={{ verticalAlign: "middle" }}>BSR<br />Reviews</th>
              <th style={{ fontSize: "80%", verticalAlign: "middle" }}>BSR Drops<br />30d|90d</th>
              <th style={{ verticalAlign: "middle" }}>ASIN</th>
              <th style={{ fontSize: "80%", verticalAlign: "middle" }}>Average (New)<br />30|90d</th>
              <th style={{ verticalAlign: "middle" }}>Now<br />Discount</th>
              <th style={{ verticalAlign: "middle" }} className="tooltip"> AZFees<span style={{ fontSize: "62%" }}>(?)</span>
                <span class="tooltiptext">
                  Amazon Referral Fee + Fulfillment Cost (FBA)
                </span></th>
              <th style={{ verticalAlign: "middle", fontSize: "95%" }} className="tooltip">
                <abbr
                  title="Profit"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"] === "profit" &&
                      "flex",
                  }}
                  onClick={() => {
                    if (props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"] === "profit") {
                      return
                    }
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: "profit",
                    }));
                  }}
                >
                  Profit<span style={{ fontSize: "62%" }}>(?)</span>
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"] === "profit" && (
                      <i
                        className="fa fa-arrow-down"
                        aria-hidden="true"
                        style={{ marginTop: "5px", marginBottom: "0px" }}
                      ></i>
                    )}
                </abbr>
                {!props.filters.hasOwnProperty("sortBy") && <br />}
                <abbr
                  title="ROI"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"] === "roi" &&
                      "flex",
                  }}
                  onClick={() => {
                    if (props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"] === "roi") {
                      return
                    }
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: "roi",
                    }));
                  }}
                >
                  ROI<span style={{ fontSize: "62%" }}>(?)</span>
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"] === "roi" && (
                      <i
                        className="fa fa-arrow-down"
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
                <span class="tooltiptext">
                  ROI and profit are calculated from the buy price "Now", the fees shown under AZFees, and the Average Price 30 days (as the sell price).
                </span>
              </th>
              <th style={{ verticalAlign: "middle" }}>Offers</th>
              <th style={{ verticalAlign: "middle" }}>Dynamic Keepa Graph</th>
              <th style={{ verticalAlign: "middle" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((product, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div style={{ display: "flex" }}>
                      <div className="amazonImage">
                        <p
                          onClick={() => {
                            window.open("https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1");
                          }}
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          Amazon
                        </p>
                        <img
                          alt="product_image"
                          loading="lazy"
                          src={product.img}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/noimage.png";
                          }}
                          style={{
                            borderRadius: "4.5vw",
                            border: "5px solid #E3D0DF",
                            height: "4.5vw",
                            width: "4.5vw",
                            objectFit: "scale-down",
                          }}
                          onClick={() => {
                            window.open("https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1");
                          }}
                        />
                      </div>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "10vw",
                          height: "11.3vh",
                          padding: "1.2vw 1vw",
                          textAlign: "center",
                        }}
                      >
                        {product.title}
                      </span>
                      <span
                        style={{
                          fontSize: "0.7vw",
                          position: "absolute",
                          marginTop: "5.9%",
                          marginLeft: "-0.5%",
                          opacity: "0.7",
                          height: "0px",
                        }}
                      >
                        Found {(t => t < 60 ? t + " minutes" : t < 1440 ? parseInt(t / 60) + " hours" : parseInt(t / 1440) + " days")(Math.floor((new Date(new Date().toISOString()) - new Date(product.found * 1000).getTime()) / 60000))} ago.
                      </span>
                    </div>
                  </td>
                  <td>
                    {product.category}
                  </td>
                  <td>
                    #{product.bsr}
                    <br />
                    {product.reviews}<i class="fa fa-pencil" aria-hidden="true" style={{ fontSize: "70%", textDecoration: "underline" }}></i>
                  </td>
                  <td id="bsrDropsTt">
                    {product.bsr_drops_30}<br />{product.bsr_drops_90}
                  </td>
                  <td>
                    <a
                      href={"https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1"}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline", color: "#8B3C7E" }}
                    >
                      {
                        product.asin
                      }
                    </a>
                  </td>
                  <td className="bg" id="averageTt">
                    ${product.average}<br />${product.average_90}
                  </td>
                  <td className="bg" id="nowTt">
                    ${product.now}<br />
                    <span style={{ fontSize: "80%", marginTop: "auto" }}><i class="fa fa-arrow-circle-o-down" aria-hidden="true"></i> {(((product.average - product.now) / (product.average)) * 100).toFixed(0)}%</span>
                  </td>
                  <td>
                    ${(product.fba_fee + product.ref_fee).toFixed(2)}
                  </td>
                  <td className="bg" id="estProfTt">
                    +${(
                      parseFloat(product.average) -
                      parseFloat(product.now) -
                      product.ref_fee -
                      product.fba_fee
                    ).toFixed(2)}
                    <br />
                    <strong style={{ color: "purple" }}>{(((parseFloat(product.average) -
                      parseFloat(product.now) -
                      product.ref_fee -
                      product.fba_fee) /
                      parseFloat(product.now)) *
                      100
                    ).toFixed(2)}
                      %</strong>
                  </td>
                  <td id="offersTt">
                    {product.offers}{" "}
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </td>
                  <td
                    className="no-bg dynamicKeepaTtOOS"
                    id="dynamicKeepaTt"
                    style={{
                      width: "20vw",
                      maxWidth: "20vw",
                      zIndex: "9999 !important",
                      position: "relative"
                    }}
                  >
                    <img
                      loading="lazy"
                      onClick={() =>
                        setDKGAsin(product.asin)
                      }
                      src={DKGasin === null ? `https://graph.keepa.com/?asin=${product.asin
                        }&domain=com&salesrank=${+bsr_state}&amazon=${+az_state}&new=${+new_state}&bb=${+bb_state}&fba=${+fba_state}&range=${range_state}` : '/assets/dkg_loading.png'}
                      alt="keepahist"
                      style={{ position: "relative", border: "1px solid gray" }}
                      className="keepagraphoos"
                    />
                  </td>
                  <td className="no-bg" style={{ width: "15vw" }}>
                    <div
                      className="columns is-centered"
                      id="hideTt"
                      style={{ display: "flex", zIndex: 1 }}
                    >
                      <button
                        className="button is-rounded"
                        style={{
                          backgroundColor: "#8B3C7E",
                          color: "white",
                          fontWeight: "700",
                          marginLeft: "3px",
                          fontSize: ".75vw", zIndex: 1,
                        }}
                        onClick={() => {
                          setHideModalProduct(product)
                        }}
                      >
                        HIDE FROM OTHERS
                      </button>
                    </div>
                    <div
                      className="columns is-centered"
                      style={{ display: "flex", marginTop: "1px" }}
                    >
                      <i class="fa fa-search graphicon tooltip" style={{
                        width: "2.25vw",
                        marginTop: "1px",
                        marginLeft: "3px",
                        height: "2.25vw",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        padding: "0.35vw 0vw",
                        cursor: "pointer",
                        fontSize: "1.35vw",
                      }} aria-hidden="true"
                        onClick={() => setSearchModal(product)}
                        id="searchTt"
                      ><span
                        class="tooltiptext"
                        style={{
                          marginTop: "-25%",
                          fontSize: ".75vw",
                          zIndex: "2000",
                          padding: "3px 6px",
                          border: "1px solid black",
                          fontFamily: "sans-serif",
                        }}
                      >
                          Check in other suppliers
                        </span></i>
                      <img
                        className="graphicon advDataTt"
                        alt="graph"
                        onClick={() => setAdvancedDataProduct(product)}
                        src={"/assets/graph.png"}
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "3px solid purple",
                          boxShadow: "0px 0px 5px 1px purple",
                          padding: "3px",
                        }}
                      />
                      <i
                        className="fa fa-unlock-alt graphicon"
                        id="gatedTt"
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "0.3vw 0.2vw",
                          cursor: "pointer",
                          fontSize: "1.6vw",
                          color: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? "white" : "rgb(195, 65, 2)",
                          backgroundColor: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions.length === 0 ? "green" : (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions[0].reasons[0].reasonCode === "APPROVAL_REQUIRED" ? "orange" : "red")) : "white"
                        }}
                        onClick={() => {
                          if (props.sellerId === "none" || props.sellerId === "") {
                            setLoginWAZ(true);
                          }
                          window
                            .open(
                              "https://sellercentral.amazon.com/product-search/search?q=" +
                              product.asin.split("/")[
                              product.asin.split("/").length - 1
                              ],
                              "_blank"
                            )
                            .focus()
                        }}
                        aria-hidden="true"
                      ></i>
                      <img
                        className="graphicon"
                        id="keepaTt"
                        alt="keepa"
                        onClick={() =>
                          window.open(
                            `https://keepa.com/#!product/1-${product.asin.split("/")[
                            product.asin.split("/").length - 1
                            ]
                            }}`,
                            "_blank"
                          )
                        }
                        src={"/assets/keepa.png"}
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "3px",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div
          style={{
            width: "70vw",
            margin: "auto",
            height: "10vh",
            textAlign: "center",
          }}
        >
          <button
            className="button"
            style={{
              float: "left",
              width: "10vw",
              fontSize: "1.1vw",
              backgroundColor: "#8B3C7E",
              color: "white",
            }}
            onClick={() => {
              props.setPage((page) => (page > 1 ? page - 1 : 1));
              window.scrollTo(0, 0);
            }}
          >
            Previous Page
          </button>
          <span>Page {props.page}</span>
          <button
            className="button"
            style={{
              float: "right",
              width: "10vw",
              fontSize: "1.1vw",
              backgroundColor: "#8B3C7E",
              color: "white",
            }}
            onClick={() => {
              props.setPage((page) => page + 1);
              window.scrollTo(0, 0);
            }}
          >
            Next page
          </button>
        </div>
      </div>
      {hideModalProduct !== "" && (
        <ModalForHide
          isMobile={props.isMobile}
          setHideModalProduct={setHideModalProduct}
          hideModalProduct={hideModalProduct}
          hideCredits={props.hideCredits}
        />
      )}
      {DKGasin !== null && (
        <DynamicKeepaGraphPopup
          asin={DKGasin}
          setDKGAsin={setDKGAsin}
          bsr_state={bsr_state}
          setBsr={setBsr}
          bb_state={bb_state}
          setBb={setBb}
          az_state={az_state}
          setAz={setAz}
          fba_state={fba_state}
          setFba={setFba}
          new_state={new_state}
          setNew={setNew}
          range_state={range_state}
          setRange={setRange} />
      )}
      {loginWAZ && localStorage.getItem("no_azlog_popup") !== "1" && (
        <div
          id="modal-js-example"
          className={
            "modal" + (loginWAZ !== null ? " is-active" : "")
          }
        >
          <div className="modal-background"></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center", fontWeight: "600" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Log in to your Amazon account to automate the eligibility check!
              </p>
              <br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "green"
                }} />&emsp;GREEN - You CAN sell this item!
              <br /><br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "red"
                }} />&emsp;RED - You CANNOT sell this item!
              <br /><br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "orange"
                }} />&emsp;ORANGE - You need to APPLY to sell this item!
              <br /><br /><br />
              <i class="fa fa-arrow-right" aria-hidden="true" style={{ fontSize: "25px", marginRight: "10px" }}></i>
              {user && user.email && <img src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" onClick={() => window.open('https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.5e50b4d6-1a6f-46fa-a523-7dc2bd0266a8&version=beta&state=' + user.email)} style={{ resizeMode: 'center', cursor: "pointer", boxShadow: "0px 0px 5px 0px gray", borderRadius: "5px", marginBottom: "-8px" }} />}
              <i class="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: "25px", marginLeft: "10px" }}></i>
              <br /><br /><br />
              <div className="columns is-mobile is-centered" style={{ marginBottom: "1%", marginTop: '1%' }}>
                <button
                  className="button is-rounded is-danger is-outlined"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                    localStorage.setItem("no_azlog_popup", "1")
                  }}
                >
                  Do not show this message again
                </button>&emsp;
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                  }}
                >
                  Close for Now
                </button>
              </div>
            </div>
          </div>
        </div>)}
      {advancedDataProduct !== null &&
        <A2AAdvancedDataModal
          advancedDataProduct={advancedDataProduct}
          setAdvancedDataProduct={setAdvancedDataProduct}
          isMobile={props.isMobile}
        />
      }
      {searchModal !== null && (
        <div
          id="modal-js-example"
          className={
            "modal" + (searchModal !== null ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setSearchModal(null);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Check in other suppliers
              </p>
              <p>
                A specific ASIN can be found from multiple suppliers in Nepeto. By clicking the Nepeto button, you can compare all prices from different suppliers.
              </p>
              <br />
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open(
                    `https://www.ebay.com/sch/i.html?&_nkw=${searchModal.title.split(" | At")[0].slice(0, 40)}&_odkw=${searchModal.title.split(" | At")[0].slice(0, 40)}`,
                    "_blank"
                  )
                }
                src={"/assets/ebay.png"}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.7vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />&emsp;&emsp;&emsp;
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://www.nepeto.com/retail?asin=" + searchModal.asin.split("/")[searchModal.asin.split("/").length - 1
                  ])}
                src={logo}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "3px",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />&emsp;&emsp;&emsp;
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://www.google.com/search?q=" + searchModal.title.split(" | At")[0])}
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/250px-Google_2015_logo.svg.png"}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.5vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />
              <br /><br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setSearchModal(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>)}
    </>
  );
}
