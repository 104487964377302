import { Helmet } from "react-helmet";

function ChromeExtenstion(props) {
  return (
    <>
      <Helmet>
        <title>Nepeto - Chrome Extension</title>
        <meta
          name="description"
          content="Chrome Extension for Nepeto! Can be used for Reverse Sourcing, Storefront Stalking and more!! The best way to source widely!"
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, chrome extension, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#8c3e7c",
          marginBottom: props.isMobile && "1vh",
        }}
      >
        Chrome Extensions
      </h1>
      <div className="columns" style={{ marginBottom: "20vh", marginTop: "7vh", justifyContent: "center" }}>
        <div className="column is-3" style={{ width: "auto", padding: !props.isMobile ? "2%" : "10%", borderRadius: "50px", boxShadow: "0px 0px 5px 0px gray" }}>
          <h1
            style={{
              fontSize: props.isMobile ? "2vh" : "1.5vw",
              fontWeight: "380",
              color: "#8c3e7c",
              marginBottom: props.isMobile && "1vh",
            }}
          >
            Nepeto Alert
          </h1>
          <p style={{ width: props.isMobile ? "90vw" : "20vw" }}>
            Use Nepeto Alert Chrome extension to see in a moment if this product is
            available from one of our suppliers that we scan (hundreds!). The
            alert will pop up, and when you click it, you will see in Nepeto
            where to buy it and how much ROI you will get!
            <br />
            No more manual reverse sourcing.
          </p>
          <br />
          <iframe
            src="https://www.youtube-nocookie.com/embed/t0jqDyHpmWk?si=K0tdZVeBR2I1UIOL"
            title="YouTube video player"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br /><br />
          <button
            className="button is-rounded"
            style={{
              backgroundColor: "#8B3C7E",
              color: "white",
              fontWeight: "600",
              marginLeft: "3px",
              fontSize: props.isMobile ? "1.8vh" : ".75vw",
            }}
            onClick={() => {
              window.open("https://chromewebstore.google.com/detail/nepeto-alert/cadiookmlcafhgpjmnndfgdpjmdellph")
            }}
          >
            Open in Chrome Extensions
          </button>
        </div>
        <div className="column is-3" style={{ transform: !props.isMobile && "scale(1.15)", width: "auto", marginTop: props.isMobile && "5vh", marginLeft: !props.isMobile && "5%", padding: !props.isMobile ? "2%" : '10%', borderRadius: "50px", boxShadow: "0px 0px 5px 0px gray" }}>
          <h1
            style={{
              fontSize: props.isMobile ? "2vh" : "1.5vw",
              fontWeight: "380",
              color: "#8c3e7c",
              marginBottom: props.isMobile && "1vh",
            }}
          >
            Nepeto - OA Chrome Extension
          </h1>
          <p style={{ width: props.isMobile ? "90vw" : "20vw" }}>
            Your favorite AMZ Online Arbitrage Sourcing Software, Nepeto.com, has launched incredible product analysis tools for AMZ Sellers!
            Featuring a powerful Chrome extension and a mobile app for Retail Arbitrage (RA), our Chrome extension instantly provides all necessary data.
            Discover monthly sales estimations (variation-sensitive), profitability insights, historical data, advanced analysis, AI-powered search, reverse sourcing, variation analysis, and much more!
          </p>
          <br />
          <iframe
            src="https://www.youtube.com/embed/XdjeNRCwilM?si=VS29t51NhGSpeibx"
            title="YouTube video player"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br /><br />
          <button
            className="button is-rounded"
            style={{
              backgroundColor: "#8B3C7E",
              color: "white",
              fontWeight: "600",
              marginLeft: "3px",
              fontSize: props.isMobile ? "1.8vh" : ".75vw",
            }}
            onClick={() => {
              window.open("https://chromewebstore.google.com/detail/nepeto/ehjjfmgnkkkhaeeocejeimgjgepajkeh")
            }}
          >
            Open in Chrome Extensions
          </button>
        </div>
        <div className="column is-3" style={{ width: "auto", marginTop: props.isMobile && "5vh", marginLeft: !props.isMobile && "5%", padding: !props.isMobile ? "2%" : '10%', borderRadius: "50px", boxShadow: "0px 0px 5px 0px gray" }}>
          <h1
            style={{
              fontSize: props.isMobile ? "2vh" : "1.5vw",
              fontWeight: "380",
              color: "#8c3e7c",
              marginBottom: props.isMobile && "1vh",
            }}
          >
            Nepeto - Walmart Scanner
          </h1>
          <p style={{ width: props.isMobile ? "90vw" : "20vw" }}>
            Use Nepeto - Walmart Scanner Chrome extension to see in a moment if any product from walmart is
            profitable to resell on Amazon.
            The alert will pop up, and when you click it, you will see in Nepeto
            more information and how much ROI you will get!
            <br />
            No more manual sourcing from Walmart.
          </p>
          <br />
          <iframe
            src="https://www.youtube-nocookie.com/embed/XSqSg_hxWRY?si=nI8BDAJBzguRv5IT"
            title="YouTube video player"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br /><br />
          <button
            className="button is-rounded"
            style={{
              backgroundColor: "#8B3C7E",
              color: "white",
              fontWeight: "600",
              marginLeft: "3px",
              fontSize: props.isMobile ? "1.8vh" : ".75vw",
            }}
            onClick={() => {
              window.open("https://chromewebstore.google.com/detail/nepeto-walmart-scanner/cphiklkenighklpenmopemmlblaejfmk")
            }}
          >
            Open in Chrome Extensions
          </button>
        </div>
      </div>
    </>
  );
}

export default ChromeExtenstion;
