import React, { useEffect, useState } from "react";
import ModalForHide from "../Products/Components/ModalForHide";
import NotDirectLinkPopup from "../Products/Components/NotDirectLinkPopup";
import MessageBeforeLinkPopup from "../Products/Components/MessageBeforeLinkPopup";
import ModalForVariationsTable from "../Products/Components/ModalForVariationsTable";
import logo from "../../logo2.svg";
import { useAuth0 } from "@auth0/auth0-react";

export default function ScannerProductsTable(props) {
  const [hideModalProduct, setHideModalProduct] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [page, setPage] = useState(1);
  const [prep, setPrep] = useState(localStorage.getItem("costPrep") || 0);
  const [showMessageObj, setShowMessageObj] = useState(null);
  const [searchModal, setSearchModal] = useState(null);
  const [render, setRender] = useState(0);
  const [modalForVariations, setModalForVariations] = useState(false);
  const [modalForVariationsAsin, setModalForVariationsAsin] = useState("");
  const [barcodeColorMap, setBarcodeColorMap] = useState({});
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const [loginWAZ, setLoginWAZ] = useState(false);
  const [gatedStatus, setGatedStatus] = useState({});
  const [headerStyle, setHeaderStyle] = useState({});
  const categorySalesRankCount = { 'Climate Pledge Friendly': 1500000, 'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515, 'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843, 'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162, 'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647, 'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328, 'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340, 'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048, 'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515, 'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789, 'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893, 'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107, 'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894, "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127, "Collectible Coins": 210648, "Camera & Photo": 4021127 }

  const handleScroll = () => {
    try {
      const table = document.getElementById('tableTable');
      const tableRect = table.getBoundingClientRect();

      if (tableRect.top <= 0) {
        // The header is out of view (or at the top of the viewport)
        setHeaderStyle({
          position: 'relative',
          top: -tableRect.top + "px",
          zIndex: 9,
        });
      } else {
        setHeaderStyle({});
      }
    } catch {}
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkGated = async (asin) => {
      if (props.sellerId) {
        await fetch(`https://server.nepeto.com/mobile_app_check_restrictions/`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sellerId: props.sellerId,
            asin: asin,
            refreshToken: props.refreshToken,
          })
        }).then(response => response.json())
          .then(data => {
            setGatedStatus((prevGS) => ({
              ...prevGS,
              [asin]: data,
            }));
          })
      }
    };

    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && props.sellerId !== "none" && props.sellerId !== "") {
      props.data.slice((page - 1) * 10, page * 10).forEach(prd => {
        if (!gatedStatus.hasOwnProperty(prd.asin.split("/")[prd.asin.split("/").length - 1])) {
          checkGated(prd.asin.split("/")[prd.asin.split("/").length - 1])
        }
        if (props.vlookupObj.hasOwnProperty(prd.asin)) {
          prd.price = props.vlookupObj[[prd.asin]].price
          prd.source = props.vlookupObj[[prd.asin]].source
        }
        if (prd.upcs) {
          if (prd.upcs.split(',').some(upc => props.vlookupObj.hasOwnProperty(upc.replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")))) {
            const matchingUPC = prd.upcs.split(',')
              .map(upc => upc.replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", ""))
              .find(upc => props.vlookupObj.hasOwnProperty(upc));
            if (matchingUPC) {
              prd.price = props.vlookupObj[[matchingUPC]].price.replace("$", "")
              prd.source = props.vlookupObj[[matchingUPC]].source.replace("$", "")
            }
          }
        }
      });
    }
    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && localStorage.getItem("azlog_pop_first") !== "1") {
      setLoginWAZ(true);
      localStorage.setItem("azlog_pop_first", "1");
    }
  }, [isLoading, isAuthenticated, props.data, page]);

  useEffect(() => {
    const generateLightColor = () => `rgb(${Math.floor(Math.random() * 128 + 128)},${Math.floor(Math.random() * 128 + 128)},${Math.floor(Math.random() * 128 + 128)})`;

    setBarcodeColorMap((prevBarcodeColorMap) => {
      if (props.identifier === "Barcode") {
        const newBarcodeColorMap = { ...prevBarcodeColorMap };
        props.data.forEach(({ Barcode }) => {
          if (!newBarcodeColorMap[Barcode]) {
            newBarcodeColorMap[Barcode] = generateLightColor();
          }
        });
        return newBarcodeColorMap;
      } else {
        return {};
      }
    });
  }, [props.data])

  return (
    <>
      <div className="table-container products-table-container" style={{ height: "auto" }}>
        <table id="tableTable" className="table is-hoverable products-table">
          <thead style={headerStyle}>
            <tr>
              <th>Product</th>
              <th>Category</th>
              <th>
                <abbr
                  title="BSR"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "sales_rank" &&
                      "flex",
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["sales_rank", props.sortASC],
                    }));
                  }}
                >
                  BSR
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "sales_rank" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th>BSR Drops 30d|90d</th>
              <th>ASIN</th>
              <th>
                <abbr
                  title="Price"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "price" &&
                      "flex",
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["price", props.sortASC],
                    }));
                  }}
                >
                  Your<br />Price
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "price" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th class="tooltip">AZPrice<span style={{ fontSize: "65%" }}>(?)</span>
                <span class="tooltiptext" style={{ marginTop: "-15%" }}>
                  Buy Box including shipping, if available, or the lowest new price (NOT including shipping)
                </span></th>
              <th class="tooltip">
                AZFees<span style={{ fontSize: "62%" }}>(?)</span>
                <span class="tooltiptext">
                  Amazon Referral Fee {!(props.filters.hasOwnProperty("isFBM") && props.filters.isFBM) && "+ Fulfillment Cost (FBA)"}
                </span>
              </th>
              <th class="tooltip" style={{ fontSize: "95%" }}>
                Costs<span style={{ fontSize: "62%" }}>(?)</span>
                <span class="tooltiptext">
                  Input any additional costs, such as prep cost, FBM, and
                  others.
                </span>
              </th>
              <th>AZOffers</th>
              <th>
                <abbr
                  title="Profit"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "profit" &&
                      "flex",
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["profit", props.sortASC],
                    }));
                  }}
                >
                  Profit
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "profit" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th>
                <abbr
                  title="ROI"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "roi" &&
                      "flex",
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["roi", props.sortASC],
                    }));
                  }}
                >
                  ROI
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "roi" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th>Estimated Sales/mo</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(props.data.length === 0 || props.data.slice((page - 1) * 10, page * 10).length === 0) && props.isProductsLoading === false && <td style={{ textAlign: "center" }} colSpan={12}>No products.{props.data.length === 0 && " No scan credit used."}</td>}
            {props.data.slice((page - 1) * 10, page * 10).map((product, index) => {
              return (<>
                <tr key={index} render={render}>
                  <td style={{ width: "22vw" }}>
                    <div style={{ display: "flex" }}>
                      <div className="amazonImage">
                        <p
                          onClick={() => {
                            window.open("https://amazon.com/dp/" + product.asin + "?th=1&psc=1");
                          }}
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          Amazon
                        </p>
                        <img
                          alt="product_image"
                          className="hoverImageTable"
                          loading="lazy"
                          src={product.image}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/noimage.png";
                          }}
                          style={{
                            borderRadius: "4.5vw",
                            border: "5px solid #E3D0DF",
                            height: "4.5vw",
                            width: "4.5vw",
                            objectFit: "scale-down",
                            position: "relative",
                            zIndex: "5"
                          }}
                          onClick={() => {
                            window.open("https://amazon.com/dp/" + product.asin + "?th=1&psc=1");
                          }}
                        />
                      </div>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "10vw",
                          height: "10vh",
                          padding: "9px",
                          textAlign: "center",
                          margin: "auto",
                          fontSize: "77.5%"
                        }}
                      >
                        {product.title && product.title !== null && product.title.split(" | At")[0].slice(0, 20)}...{product.title.split(" | At")[0].slice(-25)}
                        {product.title && product.title !== null && <span className="tooltip" style={{ position: "absolute", marginLeft: "2px", zIndex: "9" }}>
                          <i class="fa fa-info-circle" id="variationTt" aria-hidden="true" style={{ fontSize: "90%" }}></i>
                          <span className="tooltiptext" style={{ zIndex: "9", padding: "5px" }}>
                            {product.title.split(" | At")[0]}
                          </span>
                        </span>}<br />
                        <span title={props.identifier !== "Barcode" && product['upcs']} style={{ padding: "0% .8%", borderRadius: "30px", color: "black", backgroundColor: barcodeColorMap[product['Barcode']], position: "absolute", marginLeft: "-4.1%", zIndex: "8" }}>UPC{props.identifier !== "Barcode" ? "s" : ""}: {props.identifier === "Barcode" ? product['Barcode'].replace("'", "") : (product['upcs'].substring(0, product['upcs'].indexOf(',') !== -1 ? product['upcs'].indexOf(',') : product['upcs'].length) + "...")}</span>
                        {product.source && <span title={product.source} style={{ marginTop: "1.2%", padding: "0% .8%", borderRadius: "30px", color: "black", backgroundColor: 'lightgray', position: "absolute", marginLeft: "-4.1%", zIndex: "8" }}>Source: <a href={product.source} target="_blank" rel="noreferrer" style={{textDecoration: "underline"}}>{product.source.replace("www.", "").replace("https://", "").replace("http://", "").substring(0, 10)}...</a></span>}
                      </span>
                      {product.csv_image && <div className="sourceImage">
                        <p
                          style={{
                            textAlign: "center",
                            maxWidth: "4.5vw",
                            cursor: "pointer",
                          }}
                        >
                          CSV
                        </p>
                        <img
                          alt="product_image"
                          className="hoverImageTable"
                          loading="lazy"
                          src={product.csv_image ? product.csv_image : "/assets/noimage.png"}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/noimage.png";
                          }}
                          style={{
                            borderRadius: "4.5vw",
                            border: "5px solid #E3D0DF",
                            height: "4.5vw",
                            width: "4.5vw",
                            objectFit: "scale-down",
                            position: "relative",
                            zIndex: "5"
                          }}
                        />
                      </div>}
                    </div>
                  </td>
                  <td id="categoryTt" style={{ color: !categorySalesRankCount[product.category] && "darkred" }}>{product.category}</td>
                  <td id="bsrTt" style={{ minWidth: "5.6vw", color: !categorySalesRankCount[product.category] && "darkred" }}>
                    #{product.sales_rank}{categorySalesRankCount[product.category] && <><br /><span style={{ fontSize: "83%" }}>Top {((product.sales_rank / categorySalesRankCount[product.category]) * 100) < 0.01 ? "<0.01" : ((product.sales_rank / categorySalesRankCount[product.category]) * 100).toFixed(((product.sales_rank / categorySalesRankCount[product.category]) * 100) < 0.1 ? 2 : 1)}%</span></>}
                    <br /><span style={{ fontSize: "70%" }}>30d: #{product.bsr_avg30}</span>
                  </td>
                  <td>
                    {product.sales_rank_drops_30_days} | {product.sales_rank_drops_90_days}
                  </td>
                  <td id="asinTt">
                    <br />
                    <a
                      href={product.asin}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline", color: "#8B3C7E" }}
                    >
                      {
                        product.asin.split("/")[
                        product.asin.split("/").length - 1
                        ]
                      }
                    </a>
                    <button
                      className="graphicon"
                      id="variationBtnTt"
                      style={{
                        width: "calc(1.5vh + 5vw)",
                        height: "calc(1.2vh + .75vw)",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        objectFit: "contain",
                        fontSize: ".65vw",
                        cursor: "pointer",
                        color: "rgb(139, 60, 126)",
                        fontWeight: "600",
                        marginBottom: "7px",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        setModalForVariationsAsin(product.asin.split("/")[product.asin.split("/").length - 1]);
                        setModalForVariations(true)
                      }}
                    >VARIATIONS: {product.variations !== "null" ? JSON.parse(product.variations).length : 0}</button>
                  </td>
                  <td id="buyPriceTt" style={{ width: "7vw", maxWidth: "7vw" }} class="bg">
                    $<input style={{ width: "2.5vw" }} placeholder={product.price} onChange={(e) => { product.price = parseFloat(e.target.value); setRender(rnd => rnd + 1) }} />
                  </td>
                  <td id="sellPriceTt" style={{ width: "6.5vw", maxWidth: "6.5vw", paddingTop: product.used_bb ? "3.5%" : "2%" }} class="bg">
                    ${product.az_price.toFixed(2)}
                    {product.used_bb && <><br /><span style={{ borderRadius: "30px", backgroundColor: "lightgreen", padding: "4% 10%", fontSize: "80%" }}>BuyBox</span></>}<br />
                    <span style={{ fontSize: "80%" }}>30d: ${product.az_price_avg30.toFixed(1)}</span>
                  </td>
                  <td id="azFeesTt">
                    {product.ref_fee_perc === 0 || product.fba_cost === 0
                      ? "N/A"
                      : "-$" +
                      ((product.ref_fee_perc * product.az_price) + (!props.filters.isFBM && product.fba_fee)).toFixed(
                        2
                      )}
                  </td>
                  <td id="costTt">
                    <input
                      type="number"
                      style={{ width: "3vw" }}
                      value={prep}
                      min={0}
                      step={0.25}
                      onChange={(e) => { setPrep(e.target.value); localStorage.setItem("costPrep", e.target.value) }}
                      onBlur={(e) => e.target.value === "" && setPrep(0)}
                    />
                  </td>
                  <td id="azOffersTt">
                    {product.total_offers_count}{" "}
                    <i className="fa fa-user" aria-hidden="true"></i>
                    {product.az_sells_it && <><br /><span style={{ borderRadius: "30px", backgroundColor: "orange", padding: "4% 10%" }}>AMZ</span></>}
                  </td>
                  <td>
                    +$
                    {(
                      product.az_price -
                      (product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) -
                      (product.ref_fee_perc * product.az_price) - (!props.filters.isFBM && product.fba_fee) -
                      parseFloat(prep)
                    ).toFixed(2)}
                  </td>
                  <td class="bg" id="roiTt">
                    {(((product.az_price -
                      (product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) -
                      (product.ref_fee_perc * product.az_price) - (!props.filters.isFBM && product.fba_fee) -
                      parseFloat(prep)) /
                      ((product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) + parseFloat(prep))) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                  <td class="bg" id="roiTt">
                    {product.keepa_monthly_sold > 0 ? ("Verified: " + product.keepa_monthly_sold + "+") : product.estimate_sales_30_days}
                  </td>
                  <td style={{ width: "auto" }}>
                    <div
                      className={"columns is-centered" + (!localStorage.getItem("clickedActions") ? " actions" : "")}
                      style={{ display: "flex", flexDirection: "column", margin: "auto" }}
                      onClick={() => localStorage.setItem("clickedActions", true)}
                    >
                      <i class="fa fa-search graphicon tooltip" style={{
                        width: "2.25vw",
                        marginTop: "1px",
                        marginLeft: "3px",
                        height: "2.25vw",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        padding: "0.35vw 0vw",
                        cursor: "pointer",
                        fontSize: "1.35vw",
                      }} aria-hidden="true"
                        onClick={() => setSearchModal(product)}
                        id="searchTt"
                      ><span
                        class="tooltiptext"
                        style={{
                          marginTop: "-25%",
                          fontSize: ".75vw",
                          zIndex: "2000",
                          padding: "3px 6px",
                          border: "1px solid black",
                          fontFamily: "sans-serif",
                        }}
                      >
                          Check in other suppliers
                        </span></i>
                      <i
                        className="fa fa-unlock-alt graphicon tooltip"
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "0.3vw 0.2vw",
                          cursor: "pointer",
                          fontSize: "1.6vw",
                          color: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? "white" : "rgb(195, 65, 2)",
                          backgroundColor: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions.length === 0 ? "green" : (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions[0].reasons[0].reasonCode === "APPROVAL_REQUIRED" ? "orange" : "red")) : "white"
                        }}
                        id="gatedTt"
                        onClick={() => {
                          if (props.sellerId === "none" || props.sellerId === "") {
                            setLoginWAZ(true);
                          }
                          window
                            .open(
                              "https://sellercentral.amazon.com/product-search/search?q=" +
                              product.asin.split("/")[
                              product.asin.split("/").length - 1
                              ],
                              "_blank"
                            )
                            .focus()
                        }}
                        aria-hidden="true"
                      >
                        <span
                          class="tooltiptext"
                          style={{
                            zIndex: "2000",
                            fontFamily: "sans-serif",
                            marginTop: "-25%",
                            fontSize: ".75vw",
                          }}
                        >
                          Check if Gated
                        </span>
                      </i>
                      <img
                        className="graphicon"
                        alt="keepa"
                        id="keepaTt"
                        onClick={() =>
                          window.open(
                            `https://keepa.com/#!product/1-${product.asin.split("/")[
                            product.asin.split("/").length - 1
                            ]
                            }}`,
                            "_blank"
                          )
                        }
                        src={"/assets/keepa.png"}
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "3px",
                        }}
                      />
                    </div>
                  </td>
                </tr>
                {!localStorage.getItem("csvDataMsg") && <tr>
                  <td colSpan={"12"} style={{ borderColor: "#E3D0DF" }}>
                    <div style={{ height: "7.5vh", cursor: "pointer" }}
                      onClick={(e) => { window.open("https://chromewebstore.google.com/detail/nepeto/ehjjfmgnkkkhaeeocejeimgjgepajkeh"); localStorage.setItem("csvDataMsg", "1") }}>
                      <div className="adv-data-content-load"></div>

                      <div class="adv-data-content-text" style={{ marginLeft: "-8vw" }}>
                        <h1 style={{ fontWeight: "600" }}>Use our new Chrome extension to see the advanced product data! Click here to download the Chrome extension.</h1>
                      </div>
                    </div>
                  </td></tr>}
              </>
              );
            })}
            {props.isProductsLoading === true && props.data.slice((page - 1) * 10, page * 10).length < 10 && <tr><td colSpan={12}><i
              className="fa fa-spinner fa-spin"
              aria-hidden="true"
              style={{ fontSize: "250%", textAlign: 'center', width: "100%" }}
            ></i><br />The scan usually takes up to 15 minutes, depending on the file size.</td></tr>}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="14" style={{ border: "none" }}>
                <button className="button" disabled={page === 1} onClick={() => setPage(p => p - 1)} style={{ float: "left", backgroundColor: "#8B3C7E", color: 'white' }}>Previous Page</button>
                <span>Page {page}</span>
                <button className="button" disabled={((page) * 10) > props.data.length} onClick={() => setPage(p => p + 1)} style={{ float: "right", backgroundColor: "#8B3C7E", color: 'white' }}>Next Page</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {hideModalProduct !== "" && (
        <ModalForHide
          isMobile={props.isMobile}
          setHideModalProduct={setHideModalProduct}
          hideModalProduct={hideModalProduct}
          hideCredits={props.hideCredits}
        />
      )}
      {linkUrl !== "" && (
        <NotDirectLinkPopup
          isMobile={props.isMobile}
          setLinkUrl={setLinkUrl}
          linkUrl={linkUrl}
        />
      )}
      {showMessageObj !== null && (
        <MessageBeforeLinkPopup
          showMessageObj={showMessageObj}
          setShowMessageObj={setShowMessageObj}
        />
      )}
      {modalForVariations &&
        <ModalForVariationsTable
          variations={-999} // Custom flag to fetch variations
          currAsin={modalForVariationsAsin}
          modalForVariations={modalForVariations}
          setModalForVariations={setModalForVariations}
          isMobile={props.isMobile} />
      }
      {loginWAZ && localStorage.getItem("no_azlog_popup") !== "1" && (
        <div
          id="modal-js-example"
          className={
            "modal" + (loginWAZ !== null ? " is-active" : "")
          }
        >
          <div className="modal-background"></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center", fontWeight: "600" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Log in to your Amazon account to automate the eligibility check!
              </p>
              <br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "green"
                }} />&emsp;GREEN - You CAN sell this item!
              <br /><br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "red"
                }} />&emsp;RED - You CANNOT sell this item!
              <br /><br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "orange"
                }} />&emsp;ORANGE - You need to APPLY to sell this item!
              <br /><br /><br />
              <i class="fa fa-arrow-right" aria-hidden="true" style={{ fontSize: "25px", marginRight: "10px" }}></i>
              {user && user.email && <img src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" onClick={() => window.open('https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.5e50b4d6-1a6f-46fa-a523-7dc2bd0266a8&version=beta&state=' + user.email)} style={{ resizeMode: 'center', cursor: "pointer", boxShadow: "0px 0px 5px 0px gray", borderRadius: "5px", marginBottom: "-8px" }} />}
              <i class="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: "25px", marginLeft: "10px" }}></i>
              <br /><br /><br />
              <div className="columns is-mobile is-centered" style={{ marginBottom: "1%", marginTop: '1%' }}>
                <button
                  className="button is-rounded is-danger is-outlined"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                    localStorage.setItem("no_azlog_popup", "1")
                  }}
                >
                  Do not show this message again
                </button>&emsp;
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                  }}
                >
                  Close for Now
                </button>
              </div>
            </div>
          </div>
        </div>)}
      {searchModal !== null && (
        <div
          id="modal-js-example"
          className={
            "modal" + (searchModal !== null ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setSearchModal(null);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Check in other suppliers
              </p>
              <p>
                A specific ASIN can be found from multiple suppliers in Nepeto. By clicking the Nepeto button, you can compare all prices from different suppliers.
              </p>
              <br />
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open(searchModal.upc !== null ?
                    `https://www.ebay.com/sch/i.html?&_nkw=${searchModal.upc}&_odkw=${searchModal.upc}` :
                    `https://www.ebay.com/sch/i.html?&_nkw=${searchModal.title.split(" | At")[0].slice(0, 40)}&_odkw=${searchModal.title.split(" | At")[0].slice(0, 40)}`,
                    "_blank"
                  )
                }
                src={"/assets/ebay.png"}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.7vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />&emsp;&emsp;&emsp;
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://nepeto.com/retail?asin=" + searchModal.asin.split("/")[searchModal.asin.split("/").length - 1
                  ])}
                src={logo}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "3px",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />&emsp;&emsp;&emsp;
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://www.google.com/search?q=" + searchModal.title.split(" | At")[0])}
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/250px-Google_2015_logo.svg.png"}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.5vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />
              <br /><br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setSearchModal(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>)}
    </>
  );
}
