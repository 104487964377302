import React from "react";

export default function DynamicKeepaGraphPopup(props) {
  const decRange = () => {
    localStorage.setItem("DKGrange", Math.max(props.range_state - 1, 1));
    props.setRange((prevValue) => Math.max(parseInt(prevValue) - 1, 1));
  };

  const incRange = () => {
    localStorage.setItem("DKGrange", props.range_state + 1);
    props.setRange((prevValue) => parseInt(prevValue) + 1);
  };

  const changeRange = (e) => {
    props.setRange(e.target.value);
    localStorage.setItem("DKGrange", e.target.value);
  };

  const fixRange = (e) => {
    if (e.target.value < 1) {
      props.setRange(30);
      localStorage.setItem("DKGrange", 30);
    }
  };

  const getImageSource = () => {
    return `https://graph.keepa.com/?asin=${
      props.asin
    }&domain=com&salesrank=${+props.bsr_state}&amazon=${+props.az_state}&new=${+props.new_state}&bb=${+props.bb_state}&fba=${+props.fba_state}&range=${props.range_state}`;
  };

  return (
    <div
      id="modal-js-example"
      className={
        "modal" + (props.asin !== null ? " is-active" : "")
      }
      style={{zIndex: "9999999999"}}
    >
      <div className="modal-background" onClick={() => {props.setDKGAsin(null)}}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "55vw", fontSize: ".95vw" }}
      >
        <div className="box" style={{ textAlign: "center" }}>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            <strong>Dynamic Keepa Graph</strong>
          </p>
          <br/>
          <div className="container">
            <div className="columns">
              {" "}
              {/* First raw */}
              <div className="column is-flex is-2 is-align-items-center" style={{marginLeft: "37.5%"}}>
                <button
                  onClick={decRange}
                  className="button is-rounded selectlight"
                  style={{width: "3vw", height: "2vw", fontSize: "1.5vw"}}
                >
                  -
                </button>
                {"\u00A0\u00A0"}
                <input
                  type="text"
                  id="rangeValue"
                  className="input has-text-centered"
                  value={props.range_state}
                  onChange={changeRange}
                  onBlur={fixRange}
                  style={{width: "4vw", fontSize: "1.1vw"}}
                ></input>
                {"\u00A0\u00A0"}
                <p id="counter" className="counter-value">
                  Days
                </p>
                {"\u00A0\u00A0"}
                <button
                  onClick={incRange}
                  className="button is-rounded selectlight"
                  style={{width: "3vw", height: "2vw", fontSize: "1.5vw"}}
                >
                  +
                </button>
              </div>
            </div>
            <div className="columns">
              {" "}
              {/* Second raw */}
              <div className="column is-3 is-flex is-flex-direction-column">
                {" "}
                {/* 3 buttons */}
                <label className="checkbox is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-end">
                  Sales Rank{"\u00A0"}
                  <input
                    type="checkbox"
                    className="round-checkbox"
                    checked={props.bsr_state}
                    onClick={(e) => {
                      props.setBsr(!props.bsr_state);
                      localStorage.setItem("DKGbsr", e.target.checked);
                    }}
                  ></input>
                </label>
                <label className="checkbox is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-end">
                  Amazon{"\u00A0"}
                  <input
                    type="checkbox"
                    className="round-checkbox"
                    checked={props.az_state}
                    onClick={(e) => {
                      props.setAz(!props.az_state);
                      localStorage.setItem("DKGaz", e.target.checked);
                    }}
                  ></input>
                </label>
                <label className="checkbox is-flex is-flex-direction-row is-align-items-center is-justify-content-flex-end">
                  New{"\u00A0"}
                  <input
                    type="checkbox"
                    className="round-checkbox"
                    checked={props.new_state}
                    onClick={(e) => {
                      props.setNew(!props.new_state);
                      localStorage.setItem("DKGnew", e.target.checked);
                    }}
                  ></input>
                </label>
              </div>
              <div className="column is-6">
                {" "}
                {/* img */}
                <img
                  loading="lazy"
                  onClick={() => window.open(getImageSource(), "_blank")}
                  src={getImageSource()}
                  alt="keepahist"
                  style={{ position: "relative", border: "1px solid gray", height: "100%" }}
                  className="disableHoverCssDynamicKeepa"
                />
              </div>
              <div className="column is-3 is-flex is-flex-direction-column">
                {" "}
                {/* 2 buttons */}
                <label className="checkbox is-flex is-flex-direction-row is-align-items-center">
                  <input
                    type="checkbox"
                    className="round-checkbox"
                    checked={props.bb_state}
                    onClick={(e) => {
                      props.setBb(!props.bb_state);
                      localStorage.setItem("DKGbb", e.target.checked);
                    }}
                  ></input>
                  {"\u00A0"}Buy Box
                </label>
                <label className="checkbox is-flex is-flex-direction-row is-align-items-center">
                  <input
                    type="checkbox"
                    className="round-checkbox"
                    checked={props.fba_state}
                    onClick={(e) => {
                      props.setFba(!props.fba_state);
                      localStorage.setItem("DKGfba", e.target.checked);
                    }}
                  ></input>
                  {"\u00A0"}New FBA
                </label>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="columns is-mobile is-centered">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setDKGAsin(null);
              }}
            >
              Close
            </button>
          </div>
        </div>
        )
      </div>
    </div>
  );
}
