import { useAuth0 } from "@auth0/auth0-react";
import HistoryGraph from "../../Products/Components/HistoryGraph";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function A2AAdvancedDataModal(props) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAdvancedData = async () => {
      const accessToken = await getAccessTokenSilently();
      fetch(
        "https://server.nepeto.com/get_a2a_advanced_data/" +
          props.advancedDataProduct.asin.split("/")[
            props.advancedDataProduct.asin.split("/").length - 1
          ] +
          "/" +
          props.advancedDataProduct.average +
          "/" +
          props.advancedDataProduct.now +
          "/",
        {
          mode: "cors",
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        });
    };

    if (!isLoading && isAuthenticated) {
      getAdvancedData();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, props.advancedDataProduct.now, props.advancedDataProduct.asin, props.advancedDataProduct.average]);

  return (
    <div
      id="modal-js-example"
      className={
        "modal" + (props.advancedDataProduct !== null ? " is-active" : "")
      }
    >
      <div className="modal-background" onClick={() => {
                  props.setAdvancedDataProduct(null);
                }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "40vw" }}
      >
        {data === "No permissions" ? (
          <div className="box" style={{ textAlign: "center" }}>
            <strong style={{ fontSize: "150%" }}>Advanced Product Data</strong>
            <br />
            <br />
            <span>
              The advanced product data is available for the Advanced Plan and above.
              <br />
              Please upgrade your plan to access this data.
            </span>
            <br />
            <br />
            <button
              className="button is-rounded"
              style={{
                backgroundColor: "#32CD32",
                color: "#ffffff",
                fontSize: props.fontSize
              }}
              onClick={() => navigate("/pricing")}
            >
              Upgrade
            </button>
          </div>
        ) : (
          <div className="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
              <strong>Advanced Product Data</strong>
              <br />
              <br />
              <div class="columns">
                <div class="column">
                  <table
                    class="table is-bordered"
                    style={{
                      width: "100%",
                      textAlign: "left",
                      height: "100%",
                      fontSize: "65%",
                    }}
                  >
                    <tbody>
                    <tr>
                        <td>Category</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.category
                          )}
                        </td>
                      </tr>
                    <tr>
                        <td>Offers</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.offers
                          )}
                        </td>
                      </tr>
                    <tr>
                        <td>Expected Profit</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.profit === -1 ? "N/A" : ("$" + data.profit.toFixed(2))
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Expected ROI (After Amazon & FBA Fees)</td>
                        <td><strong>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.expected_roi === -1 ? "N/A" : (data.expected_roi * 100).toFixed(2) + "%"
                          )}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Last Time AZ Changed Price</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            (new Date(data.last_time_az_was_seller * 1000)).toLocaleDateString()
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Lowest New Price</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "$" + data.min_price_30days
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Highest New Price</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "$" + data.max_price_30days
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Lowest Amazon Price</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "$" + data.min_az_price_30days
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Highest Amazon Price</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "$" + data.max_az_price_30days
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column">
                  <table
                    class="table is-bordered"
                    style={{
                      width: "100%",
                      textAlign: "left",
                      height: "100%",
                      fontSize: "65%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>Rating</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.rating === -1 ? "N/A" : data.rating + "★"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Count Reviews</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.count_reviews
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>AZ Price Avg (30 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.az_price_avg30 === -0.01 ? "N/A" : "$" + data.az_price_avg30
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>AZ Price Avg (90 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.az_price_avg90 === -0.01 ? "N/A" : "$" + data.az_price_avg90
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>BSR Avg (30 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "#" + data.bsr_avg30
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>BSR Avg (90 Days)</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            "#" + data.bsr_avg90
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Variations Amount</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.variations
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Estimated Monthly Sales</td>
                        <td>
                          {data === null ? (
                            <i
                              className="fa fa-spinner fa-spin"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            data.estimate_sales_30_days
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{width: props.isMobile ? "100%" : "55%", margin: "auto", marginLeft: props.isMobile ? "5%" : "25%"}}>
                  {props.isMobile && <br/>}
                  {data === null ? (
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  ) : (
                    <HistoryGraph
                      valueName="Offers Count"
                      history={data.new_offers_graph}
                      color="darkblue"
                    />
                  )}
                </div>
            </p>
            <div className="columns is-mobile is-centered">
              <button
                className="button is-rounded is-danger"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => {
                  props.setAdvancedDataProduct(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default A2AAdvancedDataModal;
