import React, { useState } from "react";
import "./FAQ.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const questions = [
  {
    title: "Q: What is Nepeto?",
    description:
      "A: Nepeto is an automated sourcing tool for Amazon sellers. We scan hundreds of retailers, wholesalers, and distributors across the internet to provide you with a summary of the most profitable and top-selling products that you can resell on Amazon. It's easy, convenient, and straightforward. We offer links to Amazon and suppliers, compare prices, and calculate ROI after fees.",
  },
  {
    title: "Q: What Does the 'Hide Button' Do?",
    description:
      "A: If you have seen a winning product that you want to keep for yourself, you have the option of hiding it for a certain period of time by clicking the hide button.",
  },
  {
    title: "Q: What is OA for FBA Software?",
    description:
      "A: It's a software tool designed to assist sellers in finding profitable products online (Online Arbitrage) to sell on Amazon via the Fulfillment by Amazon program.",
  },
  {
    title: "Q: How does the software find profitable products for me?",
    description:
      "A: The software scans various online retailers, comparing prices to Amazon listings and considering Amazon fees to calculate potential profit margins.",
  },
  {
    title: "Q: How often is the product database updated?",
    description:
      "A: Our system continuously scans and updates the database to ensure you get the most recent and accurate data.",
  },
  {
    title: "Q: Can I set specific criteria for products?",
    description:
      "A: Yes, you can filter results based on your desired roi, sales rank, category, and more.",
  },
  {
    title:
      "Q: Is there a limit to how many products I can search for each day?",
    description:
      "A: Depending on your subscription level, there might be a product/supplier type limits.",
  },
  {
    title: "Q: What if a product is restricted or gated on Amazon?",
    description:
      "A: We provide a gating check button, it's always recommended to check on Amazon Seller Central before purchasing any product.",
  },
  {
    title: "Q: Is training / tutorials available?",
    description:
      "A: Yes, we offer a range of tutorial videos and resources to help you get started and maximize the software's potential. https://www.nepeto.com/how-to-use-nepeto",
  },
  {
    title: "Q: What if I encounter issues or need support?",
    description:
      "A: Our dedicated support team is available to assist you. Please contact us through the contact us page.",
  },
  {
    title: "Q: Is there a trial period for Nepeto?",
    description:
      "A: Yes, we offer a 3-day free trial for new users. After the trial, you can select a subscription plan that suits your needs.",
  },
  {
    title: "Q: Can I cancel my subscription at any time?",
    description:
      "A: Yes, you can cancel your subscription at any time. If you do so, you'll retain access until the end of your current billing cycle.",
  },
  {
    title: "Q: What exactly we do?",
    description:
      "A: Think of Nepeto as your dedicated assistant for Online Arbitrage on Amazon. It scouts online retailers/wholesalers to discover profitable products you can sell via Amazon's Fulfillment by Amazon program.",
  },
  {
    title: "Q: How does Nepeto aid in discovering these products?",
    description:
      "A: Nepeto combs through multiple online retailers, comparing their product prices with Amazon's. After accounting for Amazon fees, it shows you potential profit margins.",
  },
  {
    title: "Q: I'm new to this. Is Nepeto suitable for me?",
    description:
      "A: Definitely! Nepeto is designed for everyone – from those just dipping their toes in, to seasoned Amazon sellers. Just have an Amazon Professional Seller account.",
  },
  {
    title: "Q: Does Nepeto work with all Amazon marketplaces?",
    description:
      "A: The information Nepeto currently presents is relevant to the United States market, but you can make your own adjustments to any market you choose.",
  },
  {
    title: "Q: How does Nepeto calculate Amazon FBA fees?",
    description:
      "A: We've integrated an FBA calculator right into Nepeto. This tool accounts for Amazon's various fees to give you a clear snapshot of potential profits.",
  },
  {
    title: "Q: What are the accepted payment methods for Nepeto?",
    description:
      "A: Our billing partner, PayPal, accepts Visa, Mastercard, American Express, and PayPal.",
  },
  {
    title: "Q: How are the subscriptions billed?",
    description:
      "A: Monthly plans see an auto-charge post-trial. For annual plans, billing is once a year on your subscription anniversary.",
  },
  {
    title:
      "Q: I've noticed some sellers buy out-of-stock (on Amazon) products. What are the benefits of doing this?",
    description:
      "Buying out-of-stock products make you the only seller and can adjust the price as you like using historical data.",
  },
];

function FAQ(props) {
  const [openList, setOpenList] = useState(
    new Array(questions.length).fill(false)
  );
  const [freeText, setFreeText] = useState("");

  return (
    <section className="faq-section" style={{ marginTop: "-5vh" }}>
      <Helmet>
        <title>Nepeto - FAQ</title>
        <meta
          name="description"
          content="Find answers to your common queries with our FAQ section. Get solutions to your questions quickly and efficiently. Explore our FAQ page for valuable insights."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, faq, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div style={{ padding: "0px", textAlign: "center" }}>
            <h1
              style={{
                fontSize: props.isMobile ? "4.1vh" : "2.35vw",
                padding: "0 !important",
                margin: "auto",
                color: "#8c3e7c",
              }}
            >
              FAQ
            </h1>
            <h1 align="center">
              <strong>
                <Link to="/contact">Contact us</Link> if you don't find an
                answer to your question.
              </strong>
            </h1>
            <input
              placeholder="Search..."
              onChange={(e) => setFreeText(e.target.value)}
              style={{
                backgroundColor: "white",
                height: "5vh",
                marginBottom: "5vh",
                marginTop: "3vh",
                paddingLeft: "1vw",
                paddingRight: "1vw",
                border: "2px solid #8c3e7c",
                borderRadius: "4px",
                width: "50%",
              }}
            />
          </div>
          <div className="col-md-6 offset-md-3">
            <div className="faq" id="accordion">
              {questions.map((question, index) => {
                return (
                  (question.title.includes(freeText) ||
                    question.description.includes(freeText)) && (
                    <div className="card" key={index}>
                      <div
                        className="card-header"
                        onClick={() => {
                          let l = [...openList];
                          l[index] = !l[index];
                          setOpenList(l);
                        }}
                      >
                        <div className="mb-0">
                          <h5 className="faq-title">
                            <span className="badge">
                              <img
                                alt="nepeto"
                                src={"/assets/logo192.png"}
                              />
                            </span>
                            {question.title}
                          </h5>
                        </div>
                      </div>
                      <div
                        className="collapse"
                        style={{ display: openList[index] ? "block" : "none" }}
                      >
                        <div className="card-body">
                          <p>{question.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
