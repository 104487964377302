import React from "react";
import { Link } from "react-router-dom";

const SellerAssistantApp = (props) => {
  return (
    <div>
      <header>
        <h1 className="blogTitle">
          Partner - Seller Assistant App
        </h1>
      </header>
      <br />
      <main>
        <article className="blogContent">
          <h2>What is the Seller Assistant App?</h2>
          <p>
          This Browser Extension is a Powerful Amazon Product Research Tool that combines: FBM&FBA profit calculator, Advanced IP-Alert, Variation viewer, Quick View, Stock Checker, ASIN Grabber, UPC/ASIN Converter and Restriction Checker – all rolled into one powerful tool.
          <br/><br/>- Say goodbye to tab overload! Everything you need is on the Amazon Product Page.
          <br/><br/>- Find: ASIN, BSR, Category, Product Dimensions, Size Tiers, UPC, EAN, Model, Current, Minimum, and Average Price, along with Price History.
          <br/><br/>- Research High-Profit Products with Advanced Analytics from Seller Assistant App.
          <br/><br/>- Enhanced notifications: IP-Alerts, HazMat, Oversize, Meltable, Fragile, Adult, Generic Brand – we've got you covered.
          <br/><br/>- Our innovative IP Alert system keeps you informed about complaints, ensuring secure trading.
          <br/><br/>- Enjoy Keepa charts which are seamlessly.
          <br/><br/>- Save your findings to Google Sheets in one click.
          <br/><br/>- Finding stellar products for online arbitrage, wholesale and dropshipping has never been smoother.
          <br/><br/>- Customize your own billing plan with Add-ons.
          <br/><br/>- Delegate routine tasks to virtual assistants and build your dream team together.
          <br/><br/>- Ready to explore Amazon markets worldwide.
          <br/><br/>
          <a href="https://sellerassistant.app/">Go to Seller Assistant App!</a>
          </p>
        </article>
        <br/>
        <br/>
        <br/>
        <br/>
      </main>
      <footer className="blogFooter">
        <p>Published on: 03.03.2024 by Seller Assistant App</p>
        <Link to="/blog">Back to Blog</Link>
      </footer>
    </div>
  );
};

export default SellerAssistantApp;
