import React, { useEffect, useState } from "react";
import ModalForHide from "../../Products/Components/ModalForHide";
import DynamicKeepaGraphPopup from "../../../components/DynamicKeepaGraphPopup";
import A2WAdvancedDataModal from "./A2WAdvancedDataModal";
import { useAuth0 } from "@auth0/auth0-react";

export default function OOSProductsTable(props) {
  const [bsr_state, setBsr] = useState(
    localStorage.getItem("DKGbsr") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbsr"))
  );
  const [az_state, setAz] = useState(
    localStorage.getItem("DKGaz") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGaz"))
  );
  const [new_state, setNew] = useState(
    localStorage.getItem("DKGnew") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGnew"))
  );
  const [bb_state, setBb] = useState(
    localStorage.getItem("DKGbb") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbb"))
  );
  const [fba_state, setFba] = useState(
    localStorage.getItem("DKGfba") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGfba"))
  );
  const [range_state, setRange] = useState(
    localStorage.getItem("DKGrange") === null
      ? 90
      : localStorage.getItem("DKGrange")
  );
  const [hideModalProduct, setHideModalProduct] = useState("");
  const [advancedDataProduct, setAdvancedDataProduct] = useState(null);
  const [DKGasin, setDKGAsin] = useState(null);
  const [render, setRender] = useState(0);
  const [sfChecked, setSFChecked] = useState(false);
  const [headerStyle, setHeaderStyle] = useState({});
  const { getAccessTokenSilently } = useAuth0();

  const handleScroll = () => {
    try {
      const table = document.getElementById('tableTable');
      const tableRect = table.getBoundingClientRect();

      if (tableRect.top <= 0) {
        // The header is out of view (or at the top of the viewport)
        setHeaderStyle({
          position: 'relative',
          top: -tableRect.top + "px",
          zIndex: 9,
        });
      } else {
        setHeaderStyle({});
      }
    } catch {}
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getWFSCost = async (product) => {
    const accessToken = await getAccessTokenSilently();
    fetch(
      "https://server.nepeto.com/calculate_wfs/" +
      product.asin +
      "/",
      {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        product.wfsCost = data
        setRender(render => render + 1);
      });
  };

  return (
    <>
      <div className="table-container products-table-container">
        <table id="tableTable" className="table is-hoverable products-table">
          <thead style={headerStyle}>
            <tr>
              <th>Product</th>
              <th>AZ Category</th>
              <th>AZ BSR</th>
              <th>Identifiers</th>
              <th>AZ Price</th>
              <th>Walmart Price</th>
              <th>Referral Fees</th>
              <th>{sfChecked ? "Shipping Cost" : "WFS Cost"}<br /><label class="checkbox tooltip" style={{
                fontSize: props.isMobile ? "100%" : "0.85vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.2)" }}
                  onChange={(e) => { setSFChecked(e.target.checked); props.data.forEach((product) => delete product["wfsCost"]) }}
                  checked={sfChecked}
                />
                &nbsp;&nbsp;SF<br/>
                {!sfChecked && <button className="button lightbutton" style={{fontSize: "80%", marginTop: "5px"}} onClick={() => {
                  var buttons = document.getElementsByClassName('calcWfsBtn');

                  for(var i = 0; i < buttons.length; i++)  
                      buttons[i].click();
                }}>Calculate All WFS</button>}
              </label></th>
              <th><abbr
                title="Profit"
                style={{
                  display:
                    props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"] === "profit" &&
                    "flex",
                }}
                onClick={() => {
                  if (props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"] === "profit") {
                    return
                  }
                  props.setFilters((filters) => ({
                    ...filters,
                    sortBy: "profit",
                  }));
                }}
              >
                Gross Profit
                {props.filters.hasOwnProperty("sortBy") &&
                  props.filters["sortBy"] === "profit" && (
                    <i
                      className="fa fa-arrow-down"
                      aria-hidden="true"
                      style={{ marginTop: "5px", marginBottom: "0px" }}
                    ></i>
                  )}
              </abbr></th>
              <th><abbr
                  title="ROI"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"] === "roi" &&
                      "flex",
                  }}
                  onClick={() => {
                    if (props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"] === "roi") {
                      return
                    }
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: "roi",
                    }));
                  }}
                >
                  ROI
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"] === "roi" && (
                      <i
                        className="fa fa-arrow-down"
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr></th>
              <th>Dynamic Keepa Graph</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((product, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: "22vw" }}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <p
                          onClick={() => {
                            window.open("https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1");
                          }}
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          Amazon
                        </p>
                        <img
                          alt="product_image"
                          className="hoverImageTable"
                          loading="lazy"
                          src={(product.az_img !== null && product.az_img !== "") ?
                            product.az_img :
                            `https://images.amazon.com/images/P/${product.asin.split("/")[product.asin.split("/").length - 1]}.jpg`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/noimage.png";
                          }}
                          style={{
                            borderRadius: "4.5vw",
                            border: "5px solid #E3D0DF",
                            height: "4.5vw",
                            width: "4.5vw",
                            objectFit: "scale-down",
                            position: "relative",
                            zIndex: "5"
                          }}
                          onClick={() => {
                            window.open("https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1");
                          }}
                        />
                      </div>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "10vw",
                          height: "10vh",
                          padding: "9px",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        {product.title.slice(0, 40)}
                      </span>
                      <div className="sourceImage">
                        <p
                          onClick={() => {
                            window.open(product.walmart_url);
                          }}
                          style={{
                            textAlign: "center",
                            maxWidth: "4.5vw",
                            cursor: "pointer",
                          }}
                        >
                          Walmart
                        </p>
                        <img
                          alt="product_image"
                          className="hoverImageTable"
                          loading="lazy"
                          src={product.walmart_img}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/noimage.png";
                          }}
                          style={{
                            borderRadius: "4.5vw",
                            border: "5px solid #E3D0DF",
                            height: "4.5vw",
                            width: "4.5vw",
                            objectFit: "scale-down",
                            position: "relative",
                            zIndex: "5"
                          }}
                          onClick={() => { window.open(product.walmart_url); }}
                        />
                      </div>
                      {product.title.includes("Last Update") && (
                        <span
                          style={{
                            fontSize: "0.7vw",
                            position: "absolute",
                            marginTop: "5.75%",
                            marginLeft: "-1%",
                            opacity: "0.7",
                            height: "0px",
                          }}
                        >
                          Found on{" "}
                          {new Date(product.title.split("Last Update: ")[1])
                            .toLocaleString()
                            .includes("Invalid")
                            ? product.title.split("Last Update: ")[1]
                            : new Date(
                              product.title.split("Last Update: ")[1]
                            ).toLocaleString()}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    {product.az_category === null || product.az_category === "" ? "N/A" : product.az_category}
                  </td>
                  <td>
                    #{product.sales_rank === null ? "N/A" : product.sales_rank}
                  </td>
                  <td>
                    <div style={{ marginTop: "3%" }} />
                    <a
                      href={"https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1"}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline", color: "#8B3C7E" }}
                    >
                      {
                        product.asin
                      }
                    </a>
                    <br />
                    {product.upc}
                    <br />
                    <img
                      className="graphicon"
                      alt="ebay"
                      onClick={() =>
                        window.open(`https://www.ebay.com/sch/i.html?&_nkw=%28${product.upc}%29&_odkw=${product.upc}`, "_blank")
                      }
                      src={"/assets/ebay.png"}
                      style={{
                        width: "2.75vw",
                        marginTop: "5px",
                        height: "calc(1.2vh + .75vw)",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        padding: "3px",
                        objectFit: "contain"
                      }}
                    />
                  </td>
                  <td style={{ fontSize: "105%" }}>${product.az_price.toFixed(2)}</td>
                  <td style={{ fontSize: "105%" }}>
                    ${product.walmart_price.toFixed(2)}
                  </td>
                  <td style={{ fontSize: "105%" }}>
                    ~${(product.walmart_price * 0.15).toFixed(2)}
                  </td>
                  <td style={{ fontSize: "105%" }} renderShtik={render}>
                    {sfChecked ? <div style={{ display: "flex" }}><label for="shippingCost">$</label><input style={{ width: "7vw" }} value={product.wfsCost} type="number" onChange={(e) => { props.data.forEach((product) => product.wfsCost = parseFloat(e.target.value)); setRender(render => render + 1); }} placeholder="Shipping Cost" /></div> : (product.hasOwnProperty("wfsCost") && product.wfsCost === -1 ? (
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      !product.hasOwnProperty("wfsCost") ?
                        <button className="button is-rounded lightbutton calcWfsBtn" style={{ cursor: "pointer", fontSize: "70%", marginBottom: "-5px" }} onClick={() => { product.wfsCost = -1; setRender(render => render + 1); getWFSCost(product) }}>Calculate WFS</button> :
                        "-$" + product.wfsCost.toFixed(2)
                    ))}
                  </td>
                  <td style={{ fontSize: "120%" }} class="bg">
                    ${(product.walmart_price - product.az_price - (product.walmart_price * 0.15) - (product.hasOwnProperty("wfsCost") && product.wfsCost > 0 && product.wfsCost)).toFixed(2)}
                  </td>
                  <td style={{ fontSize: "120%" }} class="bg">
                    {(((product.walmart_price - product.az_price - (product.walmart_price * 0.15) - (product.hasOwnProperty("wfsCost") && product.wfsCost > 0 && product.wfsCost)) / product.az_price) * 100).toFixed(2)}%
                  </td>
                  <td
                    className="no-bg"
                    style={{
                      width: "17vw",
                      maxWidth: "17vw",
                      zIndex: "9999 !important",
                    }}
                  >
                    <img
                      loading="lazy"
                      onClick={() =>
                        setDKGAsin(
                          product.asin.split("/")[
                          product.asin.split("/").length - 1
                          ]
                        )
                      }
                      src={DKGasin === null ? `https://graph.keepa.com/?asin=${product.asin.split("/")[
                        product.asin.split("/").length - 1
                        ]
                        }&domain=com&salesrank=${+bsr_state}&amazon=${+az_state}&new=${+new_state}&bb=${+bb_state}&fba=${+fba_state}&range=${range_state}` : '/assets/dkg_loading.png'}
                      alt="keepahist"
                      style={{ position: "relative", border: "1px solid gray" }}
                      className="keepagrapha2w"
                    />
                  </td>
                  <td className="no-bg" style={{ width: "15vw" }}>
                    <div
                      className="columns is-centered"
                      style={{ display: "flex" }}
                    >
                      <button
                        className="button is-rounded"
                        style={{
                          backgroundColor: "#8B3C7E",
                          color: "white",
                          fontWeight: "700",
                          marginLeft: "3px",
                          fontSize: ".75vw",
                        }}
                        onClick={() => {
                          setHideModalProduct(product);
                        }}
                      >
                        HIDE FROM OTHERS
                      </button>
                    </div>
                    <div
                      className="columns is-centered"
                      style={{ display: "flex", marginTop: "1px" }}
                    >
                       <img
                        className="graphicon"
                        alt="graph"
                        onClick={() => window.open("https://www.getmarter.com/")}
                        src={"/assets/marter.png"}
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "3px",
                          boxShadow: "0px 0px 2px 0px gray"
                        }}
                      />&emsp;
                      <img
                        className="graphicon"
                        alt="keepa"
                        onClick={() =>
                          window.open(
                            `https://keepa.com/#!product/1-${product.asin.split("/")[
                            product.asin.split("/").length - 1
                            ]
                            }}`,
                            "_blank"
                          )
                        }
                        src={"/assets/keepa.png"}
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "3px",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div
          style={{
            width: "70vw",
            margin: "auto",
            height: "10vh",
            textAlign: "center",
          }}
        >
          <button
            className="button"
            style={{
              float: "left",
              width: "10vw",
              fontSize: "1.1vw",
              backgroundColor: "#8B3C7E",
              color: "white",
            }}
            onClick={() => {
              props.setPage((page) => (page > 1 ? page - 1 : 1));
              window.scrollTo(0, 0);
            }}
          >
            Previous Page
          </button>
          <span>Page {props.page}</span>
          <button
            className="button"
            style={{
              float: "right",
              width: "10vw",
              fontSize: "1.1vw",
              backgroundColor: "#8B3C7E",
              color: "white",
            }}
            onClick={() => {
              props.setPage((page) => page + 1);
              window.scrollTo(0, 0);
            }}
          >
            Next page
          </button>
        </div>
      </div>
      {hideModalProduct !== "" && (
        <ModalForHide
          isMobile={props.isMobile}
          setHideModalProduct={setHideModalProduct}
          hideModalProduct={hideModalProduct}
          hideCredits={props.hideCredits}
        />
      )}
      {advancedDataProduct !== null && (
        <A2WAdvancedDataModal
          advancedDataProduct={advancedDataProduct}
          setAdvancedDataProduct={setAdvancedDataProduct}
          isMobile={props.isMobile}
        />
      )}
      {DKGasin !== null && (
        <DynamicKeepaGraphPopup
          asin={DKGasin}
          setDKGAsin={setDKGAsin}
          bsr_state={bsr_state}
          setBsr={setBsr}
          bb_state={bb_state}
          setBb={setBb}
          az_state={az_state}
          setAz={setAz}
          fba_state={fba_state}
          setFba={setFba}
          new_state={new_state}
          setNew={setNew}
          range_state={range_state}
          setRange={setRange} />
      )}
    </>
  );
}
