import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const WhatIsNepeto = (props) => {
  return (
    <div>
      <Helmet>
        <title>Nepeto - What is Nepeto</title>
        <meta
          name="description"
          content="What is Nepeto exactly? What can it be used for? What are the advantages of using Nepeto? How to use it? All of this is answered here."
        />
        <meta
          name="keywords"
          content="amazon fba, amazon fba business, nepeto, amazon arbitrage, fba business, amazon fba beginners"
        />
      </Helmet>
      <header>
        <h1 className="blogTitle">What is Nepeto</h1>
        <p style={{ textAlign: "center" }}>
          <a href="https://www.nepeto.com/" target="_blank" rel="noreferrer">
            Visit Nepeto
          </a>
        </p>
      </header>
      <br />
      <main>
        <article className="blogContent">
          <section>
          <iframe
          src="https://www.youtube-nocookie.com/embed/VUxhMIx5ggg?si=_PCWLPth-EJs6lzL"
          title="YouTube video player"
          style={{
            margin: "auto",
            border: "2px solid #8c3e7c",
            padding: "1%",
            width: props.isMobile ? "100vw" : "40vw",
            height: props.isMobile ? "30vh" : "45vh",
          }}
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <br/>
            <p>
              Nepeto has developed an innovative and revolutionary algorithm
              that scans hundreds of millions of products across the internet at
              any given moment. We search the largest retail sites,
              distributors, and wholesalers in the world, such as Walmart, Kohl's,
              Target, Home Depot, Zoro, Macy's, and Gamestop, identifying
              profitable products for sale on Amazon.
            </p>
            <img src="/blogs/blog15/2.png" alt="Nepeto Filter and Search Bar" />
            <img src="/blogs/blog15/3.png" alt="Nepeto Retail Product Table" />
            <p>
              With Nepeto, you can filter products by Category, Price, ROI, BSR,
              and AZ Offers. In the future updates we will add filters for variations and
              IP complaints.
            </p>
            <img src="/blogs/blog15/4.png" alt="Nepeto Example Price" />
            <img src="/blogs/blog15/5.png" alt="Nepeto Example ROI" />

            <p>
              Nepeto allows you to search for products by supplier name, so
              choosing Walmart as your preferred supplier will display only
              Walmart products!</p>
              <img src="/blogs/blog15/6.png" alt="Nepeto Example Filter" />  
            <p>Additionally, Nepeto provides comprehensive
              details about each product, giving you the necessary insights to
              make informed purchasing decisions.
            </p>

            <p>
              With one click of a button, you will see information such as:
              30-day and 90-day average prices on Amazon, indication of Amazon
              as a seller, the number of reviews, customer ratings, sales rank
              fluctuations over the last 90 days, variation count, and uses a
              special formula to estimate monthly sales, primarily utilizing
              data from Keepa.
            </p>
            <img src="/blogs/blog15/7.png" alt="Nepeto Example Filter" />
          </section>

          <section>
            <p>
              That's just a fraction of what Nepeto offers. Beyond its main
              features, it lists out-of-stock items, a2a deals – Amazon flips, products from various Telegram groups, and
              liquidation sale goods. It even provides bundles and extensive
              lead lists, up to 2000 products, in a CSV file for deeper sourcing
              options.
            </p>
          </section>

          <p>Thanks for reading!</p><br/><br/>
        </article>
      </main>
      <footer className="blogFooter">
        <p>Published on: 11.21.2023 by Jon Hlyo</p>
        <Link to="/blog">Back to Blog</Link>
      </footer>
    </div>
  );
};

export default WhatIsNepeto;
