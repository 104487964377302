import './PackmanLoader.css'

function PackmanLoader(props) {
  return <div style={{height: "75vh", alignItems: "center", justifyContent: "center", display: "flex", marginLeft: props.isMobile && "-17%"}}>
    <img src="logo512.png" alt="Nepeto Logo" className="logopacman" style={{position: "absolute", zIndex: "2", height: "55px", marginTop: "-24vh", left: "42vw"}}/>
    <pac-man></pac-man>
    </div>;
}

export default PackmanLoader;
