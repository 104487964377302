import SignUpFreeTrialButton from "./SignUpFreeTrialButton";

export function IconDiscord(props) {
  return (
    <>
      <div className="small-circle mr-3">
        <svg
          width="24px"
          height="24px"
          viewBox="0 -28.5 246 246"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path
                d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                fill="white"
                fill-rule="nonzero"
              >
                {" "}
              </path>{" "}
            </g>{" "}
          </g>
        </svg>
      </div>
    </>
  );
}

export function IconFacebook(props) {
  return (
    <>
      <div className="small-circle mr-3">
        <svg
          viewBox="0 0 24 24"
          fill="white"
          height="2em"
          width="2em"
          {...props}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
        </svg>
      </div>
    </>
  );
}

function IconInstagram(props) {
  return (
    <>
      <div className="small-circle mr-3">
        <svg
          viewBox="0 0 24 24"
          fill="white"
          height="2em"
          width="2em"
          {...props}
        >
          <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 01-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 017.8 2m-.2 2A3.6 3.6 0 004 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 003.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 011.25 1.25A1.25 1.25 0 0117.25 8 1.25 1.25 0 0116 6.75a1.25 1.25 0 011.25-1.25M12 7a5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5m0 2a3 3 0 00-3 3 3 3 0 003 3 3 3 0 003-3 3 3 0 00-3-3z" />
        </svg>
      </div>
    </>
  );
}

function ContactUsBlock(props) {
  return (
    <div className="container is-fluid center">
      <div className="container my-6">
        <div className="columns is-desktop">
          <div className="column is-two-fifths-desktop has-text-weight-bold">
            <div className="columns my-5">
              <div className="column is-5-mobile is-6-tablet">
                <p
                  className="is-size-2-touch is-size-2-desktop aharoni blue"
                  style={{ color: "#8c3e7c" }}
                >
                  REACH US AT
                </p>
              </div>
            </div>
            <div className="columns is-mobile my-5 is-vcentered sans is-size-4-tablet is-size-5-desktop">
              <div className="column is-4-mobile is-3-tablet is-4-desktop">
                <span
                  className="tag is-rounded is-medium has-text-centered"
                  style={{ backgroundColor: "#e6cede", width: props.homePage ? "100%" : "120%" }}
                >
                  <a
                    className="white"
                    href="mailto:support@nepeto.com"
                    style={{ color: "#8c3e7c" }}
                  >
                    EMAIL
                  </a>
                </span>
              </div>
              <div className="column">
                <div className="violet" style={{ color: "#8c3e7c" }}>
                  support@nepeto.com
                </div>
              </div>
            </div>
            <div className="columns is-mobile my-5 is-vcentered sans">
              <div className="column is-4-mobile is-3-tablet is-4-desktop">
                <span
                  className="tag is-rounded is-medium has-text-centered is-black"
                  style={{
                    backgroundColor: "#e6cede",
                    width: props.homePage ? "100%" : "120%",
                    color: "#8c3e7c",
                  }}
                >
                  SOCIAL
                </span>
              </div>
              &emsp;
              <div className="column">
                <div className="columns is-flex is-horizontal">
                  <a href="https://discord.gg/jg2vmgMrz9">
                    <IconDiscord />
                  </a>
                  <a href="https://www.facebook.com/share/ofb5faZtiNzMDmPs/?mibextid=K35XfP">
                    <IconFacebook />
                  </a>
                  <a href="https://www.instagram.com/_nepeto/">
                    <IconInstagram />
                  </a>
                </div>
              </div>
            </div>
            <div className="container">
              <SignUpFreeTrialButton fontSize="130%"/>
            </div>
          </div>
          <div className="column is-three-fifths-desktop my-6">
            <div
              className="container px-3 py-2"
              style={{ background: "#8c3e7c", borderRadius: "20px", marginLeft: "20%" }}
            >
              <img src={"/assets/last-pic.PNG"} alt="" style={{ borderRadius: "20px" }} loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsBlock;
