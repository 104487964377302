import Carousel from "./components/CarouselV2";
import "./Home.css";
import ContactUsBlock, { IconDiscord, IconFacebook } from "./components/ContactUsBlock";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpFreeTrialButton from "./components/SignUpFreeTrialButton";
import LogoScroller from "./components/SuppliersScroll";

function Home(props) {
  const navigate = useNavigate();
  const [imgSrcs, setImgSrcs] = useState(["1", "2", "3", "4"]);

  useEffect(() => {
    const numOfLogosAvailable = 65;
    const interval = setInterval(() => {
      const randomIntegers = [];

      while (randomIntegers.length < 4) {
        const random = Math.floor(Math.random() * numOfLogosAvailable) + 1;
        if (!randomIntegers.includes(random)) {
          randomIntegers.push(random);
        }
      }

      setImgSrcs(randomIntegers);
    }, 2000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <>
      <Helmet>
        <title>Nepeto - Automated Product Sourcing for Amazon</title>
        <meta
          name="description"
          content="Our algorithm scans hundreds of retailers, distributors, and wholesale suppliers, providing you with a selection of thousands of profitable products ready for resell on Amazon!"
        />
        <meta
          name="keywords"
          content="amazon fba, amazon, online arbitrage, amazon sourcing, fba products, profitable products, nepeto"
        />
      </Helmet>
      <div
        className="mx-5 aharoni"
        style={{
          marginTop: props.isMobile ? "10%" : "3%",
          marginBottom: props.isMobile ? "30%" : "3.5%",
          textAlign: !props.isMobile && "center",
        }}
      >
        <p
          className="is-size-2-mobile is-size-1-tablet"
          style={{ color: "#8c3e7c" }}
        >
          <strong style={{ color: "#8c3e7c" }}>Automated</strong> Product
          Sourcing for Amazon
        </p>
        <p
          className="is-size-6-mobile is-size-5-tablet"
          style={{ color: "#8c3e7c" }}
        >
          Our algorithm scans hundreds of{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
              cursor: "pointer",
            }}
            onClick={() => navigate("/retail")}
          >
            retailers
          </span>
          , unique{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
              cursor: "pointer",
            }}
            onClick={() => navigate("/distributors")}
          >
            distributors
          </span>
          , and{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
              cursor: "pointer",
            }}
            onClick={() => navigate("/distributors")}
          >
            wholesale
          </span>{" "}
          suppliers,
          <br />
          providing you with a selection of hundreds of thousands of{" "}
          <span style={{ backgroundColor: "#e6cede", color: "#8c3e7c" }}>
            profitable products
          </span>{" "}
          ready for resell on Amazon!{" "}
        </p>
        <br />
        <div className="container custom-carousel" style={{ marginTop: "2vh" }}>
          <Carousel isMobile={props.isMobile} />
        </div>
        <br />

        {/* Sign Up button */}
        {props.isMobile && <br />}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SignUpFreeTrialButton
            fontSize={props.isMobile ? "2vh" : "calc(1vw + 1vh)"}
          />
        </div>
        <span style={{ marginTop: "10px", display: "block" }}>You have a FREE trial. Start now, join our free webinars & community, and cancel anytime.</span>
      </div>

      <LogoScroller />

      {/* The Future is Nepeto -- Mobile */}
      <>
        {/* The Future is Nepeto -- Desktop */}
        <div
          className="columns"
          style={{
            height: props.isMobile ? "100vh" : "90vh",
            width: props.isMobile ? "90%" : "80%",
            margin: "auto",
            marginTop: props.isMobile ? "5vh" : "25vh",
          }}
        >
          <div className="column">
            <iframe
              src="https://www.youtube-nocookie.com/embed/iMVy5Sl5YMs?si=zfidhUc7GOoEBZ4p"
              title="YouTube video player"
              style={{
                margin: "auto",
                border: "2px solid #8c3e7c",
                padding: "1%",
                width: props.isMobile ? "100%" : "35vw",
                height: props.isMobile ? "50vw" : "23vw",
              }}
              frameborder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="column">
            <p
              className="aharoni"
              style={{
                color: "#8c3e7c",
                fontSize: props.isMobile ? "5vh" : "2.75vw",
              }}
            >
              $500 in 60 Seconds
            </p>
            <hr style={{ borderTop: "5px dashed", color: "#8c3e7c" }} />
            <div className="montserrat">
              <strong
                style={{
                  color: "#8c3e7c",
                  fontSize: props.isMobile ? "2.2vh" : "1.2vw",
                }}
              >
                <span style={{ fontSize: "200%" }}>Watch the video to see how to find $500 in profitable products in 60 seconds!</span>
              </strong>
              <br /> <br />
              <div>
                <strong style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>Ask our 2,000+ community members how they profit with Nepeto!</strong>
                <br />
                <div style={{ display: "flex", flexDirection: !props.isMobile ? "row" : "column", alignItems: "center", margin: "auto", justifyContent: "center" }}>
                  <a
                    class="button"
                    href="https://www.facebook.com/share/ofb5faZtiNzMDmPs/?mibextid=K35XfP"
                    style={{
                      padding: props.isMobile ? "7% 4%" : "4%",
                      backgroundColor: "#e6cede",
                      borderRadius: "50px",
                      color: "#8c3e7c",
                      fontSize: "115%",
                      zIndex: "100",
                      marginBottom: props.isMobile && "5%",
                    }}
                  >
                    Join our &nbsp; <IconFacebook /> Community !
                  </a>
                  &emsp;
                  <a
                    class="button"
                    href="https://discord.gg/jg2vmgMrz9"
                    style={{
                      padding: props.isMobile ? "7% 4%" : "4%",
                      backgroundColor: "#e6cede",
                      borderRadius: "50px",
                      color: "#8c3e7c",
                      fontSize: "115%",
                      zIndex: "100",
                    }}
                  >
                    Join our &nbsp; <IconDiscord /> Community !
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br /><br />
        {/* Why use Nepeto? -- Desktop */}
        <div
          className="container is-fluid center"
          style={{
            backgroundColor: "#8c3e7c",
            height: props.isMobile ? "120vh" : "100vh",
            marginTop: props.isMobile && "10vh",
          }}
        >
          <div
            className="container"
            style={{
              marginTop: props.isMobile && "22vh",
              marginBottom: props.isMobile && "5vh",
              fontSize: props.isMobile ? "5vh" : "3.2vw",
            }}
          >
            <div className="columns">
              <div className="column has-text-centered">
                <p className="aharoni white">Why use Nepeto?</p>
              </div>
            </div>

            <div className="columns has-text-centered spartan is-desktop">
              <div className="column">
                <div className="columns">
                  <div className="column is-4-mobile is-offset-4-mobile is-4-tablet is-offset-4-tablet is-three-fifths-desktop is-offset-one-fifth-desktop">
                    <div className="circle" style={{ margin: "auto" }}>
                      <img src={"/assets/thumbs-up.PNG"} alt="Thumbs up" />
                    </div>
                  </div>
                </div>
                <p
                  className="white"
                  style={{ fontSize: props.isMobile ? "3.5vh" : "2.2vw" }}
                >
                  All Sourcing Methods in One Place
                  <p style={{fontSize: "50%"}}>One-Stop Shop</p>
                </p>
              </div>

              <div className="column">
                <div className="columns">
                  <div className="column is-4-mobile is-offset-4-mobile is-4-tablet is-offset-4-tablet is-three-fifths-desktop is-offset-one-fifth-desktop">
                    <div className="circle" style={{ margin: "auto" }}>
                      <img src={"/assets/wallet.PNG"} alt="Wallet" />
                    </div>
                  </div>
                </div>
                <p
                  className="white"
                  style={{ fontSize: props.isMobile ? "3.5vh" : "2.2vw" }}
                >
                  Nepeto Pays for Itself
                </p>
              </div>

              <div className="column">
                <div className="columns">
                  <div className="column is-4-mobile is-offset-4-mobile is-4-tablet is-offset-4-tablet is-three-fifths-desktop is-offset-one-fifth-desktop">
                    <div className="circle" style={{ margin: "auto" }}>
                      <img
                        src={"/assets/puzzle.PNG"}
                        alt="Puzzle"
                        style={{ width: "65%", height: "auto" }}
                      />
                    </div>
                  </div>
                </div>
                <p
                  className="white"
                  style={{ fontSize: props.isMobile ? "3.5vh" : "2.2vw" }}
                >
                  We find the products for you
                </p>
              </div>
            </div>
            <br/ >
            {/* Sign Up button -- Desktop */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: props.isMobile && "10vh",
              }}
            >
              <SignUpFreeTrialButton
                fontSize={props.isMobile ? "2vh" : "calc(1vw + 1vh)"}
              />
            </div>
            <br />
          </div>
        </div>

        {/* Some Common Questions -- Desktop */}
        <div
          className="container is-fluid center"
          style={{
            height: props.isMobile ? "112vh" : "100vh",
            marginTop: props.isMobile && "12vh",
          }}
        >
          <div className="container spartan">
            <div className="columns is-mobile">
              <div className="column is-4-mobile is-offset-1-mobile is-5-tablet is-offset-2-tablet is-offset-0-desktop">
                <p
                  className="custom-title"
                  style={{
                    color: "#8b3c7e",
                    fontSize: props.isMobile ? "4vh" : "3.5vw",
                  }}
                >
                  Commonly Asked Questions
                </p>
              </div>
              <div className="column is-5-mobile is-offset-2-mobile is-4-tablet is-2-desktop is-offset-2-desktop">
                <img
                  src={"/assets/qmark.PNG"}
                  alt="qmark"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>

            <div className="columns is-desktop">
              <div className="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-0-desktop">
                <div className="box">
                  <div className="row">
                    <p className="custom-text" style={{ color: "#8b3c7e" }}>
                      What is the difference between Nepeto and a lead list/VAs?
                    </p>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "3px dashed #8b3c7e",
                      paddingTop: "25px",
                    }}
                  >
                    <p className="small-text">
                    We do not manually source products. Our algorithm scans hundreds of suppliers to find hundreds of thousands of profitable, sellable products for you to source using our software.
                    </p>
                  </div>
                </div>
              </div>

              <div className="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-0-desktop">
                <div className="box">
                  <div className="row">
                    <p className="custom-text" style={{ color: "#8b3c7e" }}>
                      What do you do about competition for your products?
                    </p>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "3px dashed #8b3c7e",
                      paddingTop: "25px",
                    }}
                  >
                    <p className="small-text">
                      We understand how frustrating it is to face competition that cuts the profits. Therefore, you can exclusively have the products for yourself and hide them from other Nepeto users!
                    </p>
                  </div>
                </div>
              </div>

              <div className="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-0-desktop">
                <div className="box">
                  <div className="row">
                    <p className="custom-text" style={{ color: "#8b3c7e" }}>
                      How to use Nepeto?
                    </p>
                  </div>
                  <div
                    className="row"
                    style={{
                      borderTop: "3px dashed #8b3c7e",
                      paddingTop: "25px",
                    }}
                  >
                    <p className="small-text">
                      {" "}
                      Select a product, visit the supplier's website, and purchase the product. It's as simple as that.<br />
                      <a href="https://www.facebook.com/share/ofb5faZtiNzMDmPs/?mibextid=K35XfP" alt="csv-export" target="_blank" rel="noreferrer">Click here to join our Facebook community to access webinar recordings and more!</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/* LET'S WORK TOGETHER */}
      <ContactUsBlock homePage={true} />
    </>
  );
}

export default Home;
