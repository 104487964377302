import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../logoN.svg";
import React from "react";

function ListItem({
  value,
  href,
  sub,
  currentHref,
  setCurrentHref,
  setisActive,
}) {
  const itemStyle = {
    fontSize: "110%",
    color: "#8c3e7c",
  };

  return (
    <div className="navbar-item">
      <Link
        to={href}
        style={itemStyle}
        className={
          "navbar-text" + (currentHref === href ? " navbar-active" : "")
        }
        onClick={() => {
          setCurrentHref(href);
          setisActive(false);
        }}
        reloadDocument={href === "/retail" || href === "/distributors" || href === "/wholesale" || href === "/pricing"}
      >
        <strong style={{ fontWeight: "600" }}>
          {value}
          <sub className="has-text-weight-normal">&nbsp;{sub}</sub>
        </strong>
      </Link>
    </div>
  );
}

function Navbar(props) {
  const [isActive, setisActive] = React.useState(false);
  const [currentHref, setCurrentHref] = React.useState("");
  const { loginWithRedirect, user, isAuthenticated, isLoading, logout } =
    useAuth0();

  const brandStyle = {
    fontSize: "200%",
    color: "#8c3e7c",
  };

  return (
    <>
      <nav
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        style={{ height: "80px", fontSize: "1vw" }}
      >
        <div className="container">
          <div className="navbar-brand">
            <div className="navbar-item">
              <Link
                className="mx-6"
                to="/"
                style={brandStyle}
                onClick={() => setCurrentHref("")}
              >
                <img
                  src={logo}
                  alt="Nepeto"
                  style={{ scale: "2", marginTop: "1vh" }}
                />
              </Link>
            </div>

            <span
              role="button"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navMenu"
              onClick={() => {
                setisActive(!isActive);
              }}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>

          <div
            id="navMenu"
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
          >
            <div className="navbar-start">
              {localStorage.getItem('affiliate') === "ga" && <ListItem
                value="Abra"
                href="/pricing/ga"
                sub=""
                currentHref={currentHref}
                setCurrentHref={setCurrentHref}
                setisActive={setisActive}
              />}
              {props.isAff && <ListItem
                value="Affiliate-Dashboard"
                href="/affiliate-dashboard/"
                sub=""
                currentHref={currentHref}
                setCurrentHref={setCurrentHref}
                setisActive={setisActive}
              />}
              <ListItem
                value="Tutorials"
                href="/tutorials"
                sub=""
                currentHref={currentHref}
                setCurrentHref={setCurrentHref}
                setisActive={setisActive}
              />
              <ListItem
                value="Pricing"
                href="/pricing"
                sub=""
                currentHref={currentHref}
                setCurrentHref={setCurrentHref}
                setisActive={setisActive}
              />
              <ListItem
                value="Retail(OA)"
                href="/retail"
                sub=""
                currentHref={currentHref}
                setCurrentHref={setCurrentHref}
                setisActive={setisActive}
              />
              <ListItem
                value="Live Search"
                href="/live-search"
                sub=""
                currentHref={currentHref}
                setCurrentHref={setCurrentHref}
                setisActive={setisActive}
              />
              <ListItem
                value="OOS"
                href="/oos"
                sub=""
                currentHref={currentHref}
                setCurrentHref={setCurrentHref}
                setisActive={setisActive}
              />
              <div className="navbar-item has-dropdown is-hoverable">
                <strong
                  className="navbar-link navbar-item navbar-more"
                  style={{
                    fontSize: "110%",
                    color: "#8c3e7c",
                    fontWeight: "600",
                  }}
                >
                  More
                </strong>

                <div className="navbar-dropdown">
                  <ListItem
                    value="Amazon Flips (A2A)"
                    href="/amazon-flips"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="Amazon to Walmart"
                    href="/amazon-to-walmart"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="Distributors/Wholesale"
                    href="/distributors"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="Telegram"
                    href="/telegram"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  {!isLoading && isAuthenticated && (user.email === "friedman.ido@gmail.com" || user.email === "suzy.mclaughlin.shopper@gmail.com" || user.email === "judycockrum@gmail.com" || user.email === "jimcockrum@gmail.com") && <ListItem
                    value="SilentJim"
                    href="/silentjim"
                    sub="vip"
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />}
                  <hr className="navbar-divider" />
                  <ListItem
                    value="Storefront Scanner"
                    href="/storefront-scanner"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="CSV Scanner"
                    href="/csv-scanner"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="Nepeto Master Search"
                    href="/master-search"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="Extension/Mobile App"
                    href="/app-ext"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <hr className="navbar-divider" />
                  <ListItem
                    value="User Activity"
                    href="/user-activity"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="FB Community"
                    href="https://www.facebook.com/share/g/E88fQYt7sStzNmPX/?mibextid=K35XfP"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="FAQ"
                    href="/faq"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                  <ListItem
                    value="Contact Us"
                    href="/contact"
                    sub=""
                    currentHref={currentHref}
                    setCurrentHref={setCurrentHref}
                    setisActive={setisActive}
                  />
                </div>
              </div>
            </div>
            <div className="navbar-end">
              <span
                className="navbar-item navbar-login"
                onClick={() => {
                  if (!isLoading) {
                    if (isAuthenticated) {
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      });
                    } else {
                      loginWithRedirect();
                    }
                  }
                }}
                style={{
                  color: "#8c3e7c",
                  fontSize: "110%",
                  margin: "auto",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : isAuthenticated ? (
                  "Logout"
                ) : (
                  "Login"
                )}
              </span>
              <div className="navbar-item">
                {!isLoading && isAuthenticated ? (
                  <Link
                    to="/pricing"
                    onClick={() => setCurrentHref("/pricing")}
                    reloadDocument={true}
                  >
                    <strong className="navbar-free-trial">
                      &ensp;{props.activePlanLevel.includes("Plan") ? props.activePlanLevel : "Free Trial"}&ensp;
                    </strong>
                  </Link>
                ) : (
                  <strong
                    className="navbar-free-trial"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: "signup",
                        },
                        appState: { returnTo: "/pricing?s=1" },
                      })
                    }
                  >
                    &ensp;Free Trial&ensp;
                  </strong>
                )}
              </div>
              {!isLoading && isAuthenticated && (
                <span
                  className="navbar-username"
                  onClick={() => {
                    window.location.href = "/pricing";
                  }}
                >
                  {user.name}
                </span>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
