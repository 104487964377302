import HistoryGraph from "./HistoryGraph";

function ModalForReviewsGraph(props) {
  return (
    <div
      id="modal-js-example"
      className={
        "modal" + (props.modalForReviewsGraph !== "" ? " is-active" : "")
      }
    >
      <div className="modal-background" onClick={() => {
                props.setModalForReviewsGraph("");
              }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "60vw", height: "70vh" }}
      >
        <div className="box" style={{ textAlign: "center", height: "70vh" }}>
          <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            {props.title} History Graph
          </p>
          <br />
          <HistoryGraph
            valueName={props.title}
            history={props.count_reviews_graph}
            color="darkgreen"
            fontSize="100%"
            addBG={true}
            height="57vh"
            domain={[(Math.min(...props.count_reviews_graph.map(item => item.value))), Math.max(...props.count_reviews_graph.map(item => item.value))]}
          />
          <div className="columns is-mobile is-centered">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setModalForReviewsGraph("");
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForReviewsGraph;
