import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import ProductsTable from "../../LiveSearch/ProductsTable";
import FilterBar from "../../LiveSearch/FilterBar";

function ModalForBundlesTable(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortASC, setSortASC] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const CATEGORY_BSR_COUNT = {
    'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515,
    'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843,
    'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162,
    'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647,
    'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328,
    'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340,
    'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048,
    'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515,
    'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789,
    'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893,
    'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107,
    'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894,
    "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127,
    "Collectible Coins": 210648, "Camera & Photo": 4021127
  }

  function removeDuplicates(list) {
    var uniqueObjects = {};
    var result = [];

    list.forEach(function (obj) {
      var key = obj.asin + obj.source;

      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        result.push(obj);
      }
    });

    return result;
  }

  useEffect(() => {
    const getBundlesData = async (item) => {
      setIsLoading(true);

      const body = [{
        "Barcode": item["upc"],
        "Price": item["price"] ? item["price"] : 0,
        "Name": item["title"],
        "Image": item["img"],
        "Source": item["source_name"],
        "URL": item["url"].replace('X'.repeat(item['source_name'].length), item['source_name'])
      }];

      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch('https://server.nepeto.com/live_search_get_data/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
          mode: "cors",
          body: JSON.stringify(body),
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
    
        setProducts(data);
      } catch (error) {
        console.error('Error:', error);
      }
      setIsLoading(false);
    }

    getBundlesData(props.currItem);
  }, [props, getAccessTokenSilently]);

  useEffect(() => {
    function calculateROI(item, settings) {
      let logisticsCost = parseFloat(localStorage.getItem("costPrep") || 0);
      let price = item.price * (1 - ((settings.priceCoupon || 0) / 100));
      let azPrice = item.az_price;
      let fbaCost = settings.isFBM ? 0 : item.fba_fee;
      let sellingOnAzFees = item.ref_fee_perc * item.az_price;

      return (azPrice - price - fbaCost - logisticsCost - sellingOnAzFees) / (price + logisticsCost);
    }

    function filterData(data, filters) {
      return data.filter(item => {
        // noVariations
        if (parseInt(filters.noVariations || 0) !== 0 && item.variations !== "null") {
          return false;
        }

        // amazonNotSeller
        if (parseInt(filters.amazonNotSeller || 0) !== 0) {
          if (item.az_sells_it) {
            return false;
          }
        }

        // top_bsr
        if (filters.top_bsr && (filters.top_bsr[0] !== -1 || filters.top_bsr[1] !== -1)) {
          let categoryCount = CATEGORY_BSR_COUNT[item.category] || 10000000;
          let salesRankPercentage = (item.sales_rank / categoryCount) * 100;

          if (filters.top_bsr[0] !== -1 && salesRankPercentage < filters.top_bsr[0]) {
            return false;
          }

          if (filters.top_bsr[1] !== -1 && salesRankPercentage > filters.top_bsr[1]) {
            return false;
          }
        }

        // profit
        if (filters.profit && (filters.profit[0] !== -1 || filters.profit[1] !== -1)) {
          let profitValue = item.az_price - (item.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : item.fba_fee) - (item.ref_fee_perc * item.az_price) - parseFloat(localStorage.getItem("costPrep") || 0);

          if (filters.profit[0] !== -1 && profitValue < filters.profit[0]) {
            return false;
          }

          if (filters.profit[1] !== -1 && profitValue > filters.profit[1]) {
            return false;
          }
        }

        // search
        if (filters.search && filters.search !== "") {
          let searchLower = filters.search.toLowerCase();
          if (!item.title.toLowerCase().includes(searchLower) && !item.asin.includes(filters.search)) {
            return false;
          }
        }

        // category
        if (filters.category && filters.category !== "Category" && !filters.category.includes(item.category)) {
          return false;
        }

        // qty, moq, bsr
        for (let filterAttr of ["qty", "moq", "bsr"]) {
          if (filters[filterAttr] && (filters[filterAttr][0] !== -1 || filters[filterAttr][1] !== -1)) {
            let value = item[filterAttr === "bsr" ? "sales_rank" : filterAttr];

            if (filters[filterAttr][0] !== -1 && value < filters[filterAttr][0]) {
              return false;
            }

            if (filters[filterAttr][1] !== -1 && value > filters[filterAttr][1]) {
              return false;
            }
          }
        }

        // price
        if (filters.price && (filters.price[0] !== -1 || filters.price[1] !== -1)) {
          let price = item.price * (1 - ((filters.priceCoupon || 0) / 100));

          if (filters.price[0] !== -1 && price < filters.price[0]) {
            return false;
          }

          if (filters.price[1] !== -1 && price > filters.price[1]) {
            return false;
          }
        }

        // roi
        if (filters.roi && (filters.roi[0] !== -1 || filters.roi[1] !== -1)) {
          let roi = calculateROI(item, filters);

          if (filters.roi[0] !== -1 && roi < (filters.roi[0] / 100)) {
            return false;
          }

          if (filters.roi[1] !== -1 && roi > (filters.roi[1] / 100)) {
            return false;
          }
        }

        // offers
        if (filters.offers && (filters.offers[0] !== -1 || filters.offers[1] !== -1)) {
          let offersCount = item.total_offers_count;

          if (filters.offers[0] !== -1 && offersCount < filters.offers[0]) {
            return false;
          }

          if (filters.offers[1] !== -1 && offersCount > filters.offers[1]) {
            return false;
          }
        }

        // offers
        if (filters.sales && (filters.sales[0] !== -1 || filters.sales[1] !== -1)) {
          let sales = item.keepa_monthly_sold > 0 ? item.keepa_monthly_sold : item.estimate_sales_30_days;

          if (filters.sales[0] !== -1 && sales < filters.sales[0]) {
            return false;
          }

          if (filters.sales[1] !== -1 && sales > filters.sales[1]) {
            return false;
          }
        }

        // offers
        if (filters.bsr_drops_30_days && (filters.bsr_drops_30_days[0] !== -1 || filters.bsr_drops_30_days[1] !== -1)) {
          let drops = item.sales_rank_drops_30_days;

          if (filters.bsr_drops_30_days[0] !== -1 && drops < filters.bsr_drops_30_days[0]) {
            return false;
          }

          if (filters.bsr_drops_30_days[1] !== -1 && drops > filters.bsr_drops_30_days[1]) {
            return false;
          }
        }

        return true;
      });
    }

    const sortFunction = (a, b) => {
      if (filters.sortBy) {
        let getKey = (n, key) => {
          if (filters.sortBy[0] === "roi") {
            return parseFloat(calculateROI(n, filters))
          }
          if (filters.sortBy[0] === "profit") {
            return parseFloat(n.az_price - (n.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : n.fba_fee) - (n.ref_fee_perc * n.az_price) - parseFloat(localStorage.getItem("costPrep") || 0))
          }
          return parseFloat(n[key])
        }
        if (filters.sortBy[1]) {
          return getKey(a, filters.sortBy[0]) - getKey(b, filters.sortBy[0])
        } else {
          return getKey(b, filters.sortBy[0]) - getKey(a, filters.sortBy[0])
        }
      } else {
        return 1
      }
    }
    
    setFilteredData(filterData(products, filters).sort((a, b) => sortFunction(a, b)));
  }, [filters]);

  return (
    <div
      id="modal-js-example"
      className={
        "modal" + (props.modalForBundles !== "" ? " is-active" : "")
      }
      style={{ fontSize: "0.8vw" }}
    >
      <div className="modal-background" onClick={() => { props.setModalForBundles(""); }}></div>

      <div
        className="modal-content"
        style={{ width: props.isMobile ? "90vw" : "95vw" }}
      >
        <div className="box" style={{ textAlign: "center" }}>
          {(filteredData.length === 0 && Object.keys(filters).length > 0) && <p style={{ textAlign: "center" }}>No products match your filters.</p>}
          <FilterBar
            data={products}
            setFilters={setFilters}
            filters={filters}
            activePlanLevel={"Master Plan"}
          />

          {isLoading ? (
            <div style={{ borderColor: "#E3D0DF" }}>
              <progress class="progress is-small" max="100">15%</progress>
            </div>
          ) : (
            <ProductsTable
              data={(filteredData && filteredData.length > 0) ? filteredData : removeDuplicates(products)}
              identifier={"Barcode"}
              isMobile={props.isMobile}
              hideCredits={props.hideCredits}
              filters={filters}
              setSortASC={setSortASC}
              sortASC={sortASC}
              setFilters={setFilters}
              sellerId={props.sellerId}
              refreshToken={props.refreshToken}
              isLoading={isLoading}
            />
          )}

          <div className="columns is-mobile is-centered">
            <button
              className="button is-rounded is-danger"
              style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
              onClick={() => {
                props.setModalForBundles("");
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalForBundlesTable;
