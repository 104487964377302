import React from "react";
import "../Pricing.css";

function ListItem(props) {
  return (
    <div>
      <div className="columns is-mobile is-gapless" style={{marginBottom: "1vh"}}>
        <div className="column is-2">
          <img src={"/assets/checkSign.png"} alt="Card" style={{height: '3vh', width: "3vh"}} />
        </div>

        <div className="column is-10">
          <text
            style={{
              fontSize: (props.sent.includes("previous plans") || props.sent.includes("Master Search") || props.sent.includes("LIVE Search") || props.sent.includes("Explorer")) ? "2vh" : "1.8vh",
              color: "rgb(140, 62, 124)",
              fontWeight: (props.sent.includes("previous plans") || props.sent.includes("Master Search") || props.sent.includes("LIVE Search") || props.sent.includes("Explorer")) ? "800" : "bold",
            }}
          >
            {props.sent}{props.sent.includes("Data") ? <span class="tooltip" style={{width: "16px"}}>
              {" "}<i class="fa fa-eye" aria-hidden="true" fontSize={14}></i>
              <span class="tooltiptext nobordertooltip" style={{top: "70%", padding:"5px", left: "-66%", width: "40vw"}}>
              <img alt="hint" src="/assets/advanced_data.png"/>
              </span>
              </span> : ""}
          </text>
        </div>
      </div>
      
    </div>
  );
}
export default ListItem;
