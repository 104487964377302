import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import ModalForHide from "./ModalForHide";
import { retailLogos } from "./RetailSuppliersLogos";

function calculateRemainingDays(purchases) {
  if (!purchases.length) return {};

  // Get the current date
  const now = new Date();

  // Group purchases by asin
  const groupedPurchases = purchases.reduce((acc, purchase) => {
      if (!acc[purchase.asin]) {
          acc[purchase.asin] = [];
      }
      acc[purchase.asin].push(purchase);
      return acc;
  }, {});

  const result = {};

  for (const asin in groupedPurchases) {
      const asinPurchases = groupedPurchases[asin];

      // Sort purchases by purchase_time for each asin
      asinPurchases.sort((a, b) => new Date(a.purchase_time * 1000) - new Date(b.purchase_time * 1000));

      let endTime = new Date(asinPurchases[0].purchase_time * 1000);
      endTime.setDate(endTime.getDate() + asinPurchases[0].weeks * 7);
      
      for (let i = 1; i < asinPurchases.length; i++) {
          let currentStartTime = new Date(asinPurchases[i].purchase_time * 1000);
          if (currentStartTime <= endTime) {
              // If the current purchase starts before the previous ends, extend the end time
              let additionalDays = asinPurchases[i].weeks * 7;
              endTime.setDate(endTime.getDate() + additionalDays);
          } else {
              endTime = new Date(asinPurchases[i].purchase_time * 1000);
              endTime.setDate(endTime.getDate() + asinPurchases[i].weeks * 7);
          }
      }

      // Calculate the remaining days from now
      let remainingDays = (endTime - now) / (1000 * 60 * 60 * 24);

      // If the subscription has already ended, set remaining days to 0
      remainingDays = Math.ceil(remainingDays);

      // Store the result for the current asin
      result[asin] = remainingDays;
  }

  return result;
}

function ModalForHiddenProducts(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [hideModalProduct, setHideModalProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [tagFilter, setTagFilter] = useState("");

  useEffect(() => {
    const loadProducts = async () => {
      const accessToken = await getAccessTokenSilently();

      fetch("https://server.nepeto.com/get_hidden_products", {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
          setProductsLoading(false);
        })
        .catch((error) => {
          alert("Error! Please contact us.");
        });
    };
    if (props.hidden) {
      setProducts(props.hidden);
      setProductsLoading(false);
    } else {
      loadProducts();
    }
  }, [props.showHiddenProducts, getAccessTokenSilently, props.hidden]);
  return (
    <>
      <div
        id="modal-js-example"
        className={"modal" + (props.showHiddenProducts ? " is-active" : "")}
      >
        <div className="modal-background" onClick={() => {props.setShowHiddenProducts(false)}}></div>

        <div
          className="modal-content"
          style={{ width: props.isMobile ? "90vw" : "auto", minWidth: "25vw" }}
        >
          <div className="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
              Your Hidden Products
            </p>
            {props.hidden && <p>If your recently hidden product didn't load,<br/>it may take a few moments to update.</p>}
            <br />
            {productsLoading ? (
              <i className="fa fa-spinner fa-spin" style={{fontSize: "150%"}} aria-hidden="true"></i>
            ) : (products.length === 0 ? 
              <span style={{ color: "#8B3C7E" }}>
                Here you will find all the products<br/>
                you've hidden from other Nepeto users.<br/>
                To secure a lead for yourself,<br/>
                click the 'Hide From Others' button.<br/></span> :
              <table class="table is-bordered" style={{ margin: "auto" }}>
                <thead>
                  <tr>
                    <th style={{ color: "#8B3C7E" }}>Product</th>
                    <th style={{ color: "#8B3C7E" }}>ASIN</th>
                    <th style={{ color: "#8B3C7E" }}>Tag - Just Type<br/><input
                            style={{
                              width: "80%",
                              color: "#8B3C7E",
                              fontWeight: "600"
                            }}
                            placeholder="Filter By Tag"
                            onChange={(e) => setTagFilter(e.target.value)}
                          /></th>
                    <th style={{ color: "#8B3C7E" }}>Days Left</th>
                  </tr>
                </thead>
                <tbody>
                  {products.filter((obj1, i, arr) => arr.findIndex(obj2 => (obj2.asin === obj1.asin)) === i).filter((product) => (localStorage.getItem(product["product_url"].slice(-9) + product['asin'].split("/").pop() + "TAGHIDE") ?? "").includes(tagFilter)).map((product, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            alt="product_image"
                            loading="lazy"
                            src={product["img"] !== "" ? product['img'] : `/assets/logos/${retailLogos[product["product_url"].split(".com")[0].split(".").pop()] || ""}.png`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/assets/noimage.png";
                            }}
                            style={{
                              height: props.isMobile ? "15vw" : "4.5vw",
                              width: props.isMobile ? "15vw" : "4.5vw",
                              objectFit: "scale-down",
                              cursor: "pointer",
                              borderRadius: "8vw",
                              border: "5px solid #E3D0DF",
                            }}
                            onClick={() => {
                              window.open(product["product_url"]);
                            }}
                            className="bigHover"
                          />
                        </td>
                        <td
                          style={{
                            color: "#8B3C7E",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <a
                            href={"https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1"}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: "underline",
                              color: "#8B3C7E",
                            }}
                          >
                            {product["asin"]}
                          </a>
                        </td>
                        <td
                          style={{
                            color: "#8B3C7E",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <input
                            style={{
                              width: "80%",
                              color: "#8B3C7E",
                              fontWeight: "600"
                            }}
                            key={tagFilter}
                            placeholder={localStorage.getItem(product["product_url"].slice(-9) + product['asin'].split("/").pop() + "TAGHIDE") ?? "NO TAG"}
                            onChange={(e) => localStorage.setItem(product["product_url"].slice(-9) + product['asin'].split("/").pop() + "TAGHIDE", e.target.value)}
                          />
                        </td>
                        <td
                          style={{
                            color: "#8B3C7E",
                            textAlign: "center",
                            verticalAlign: "middle",
                            fontWeight: "500",
                          }}
                        >
                          {calculateRemainingDays(products)[product.asin] > 0 ? <>{(
                            calculateRemainingDays(products)[product.asin]
                          )}<br/><button
                              className="button is-rounded darkbutton is-small"
                              onClick={() =>
                                setHideModalProduct(product)
                              }
                            >
                              Extend Hide
                            </button></> : (
                            <button
                              className="button is-rounded darkbutton is-small"
                              style={{
                                backgroundColor: "red"
                              }}
                              onClick={() =>
                                setHideModalProduct(product)
                              }
                            >
                              Hide Again
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <br />
            <br />
            <div className="columns is-mobile is-centered">
              <button
                className="button is-rounded is-danger"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => {
                  props.setShowHiddenProducts(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {hideModalProduct !== "" && (
        <ModalForHide
          isMobile={props.isMobile}
          setHideModalProduct={setHideModalProduct}
          hideModalProduct={hideModalProduct}
          hideCredits={props.hideCredits}
        />
      )}
    </>
  );
}

export default ModalForHiddenProducts;
